{
  "netRequirement": "Besoin net",
  "product_constraint": "Contrainte produit",
  "component_shortage": "Rupture de composants",
  "supplier_constraint": "Contrainte fournisseur",
  "allocation_needed": "Allocation nécessaire",
  "below_net_requirement": "Inférieur au besoin net",
  "above_net_requirement": "Supérieur au besoin net",
  "over_fulfilled": "Sur-livraison",
  "under_fulfilled": "Sous-livraison",
  "alertsDropdown-product_constraint": "Problème de contraintes du produit",
  "alertsDropdown-component_shortage": "Rupture de composants",
  "alertsDropdown-supplier_constraint": "Problème de contraintes du fournisseur",
  "alertsDropdown-allocation_needed": "Allocation nécessaire",
  "alertsDropdown-below_net_requirement": "Inférieur au besoin net",
  "alertsDropdown-above_net_requirement": "Supérieur au besoin net",
  "alertsDropdown-out_of_stock": "Rupture de stock",
  "alertsDropdown-over_fulfilled": "Sur-livraison",
  "alertsDropdown-under_fulfilled": "Sous-livraison",
  "similarProductTooltip": "Afficher et ajouter des produits similaires",
  "similarProductExplanation1": "L'historique de demande du produit source sera copié dans l'historique de demande du produit cible. Cela est particulièrement efficace pour gérer les remplacements de produits.",
  "similarProductExplanation2": "Vous pouvez appliquer un coefficient lors du transfert de l'historique de demande, par exemple si deux produits sont remplacés par un seul produit.",
  "similarProductExplanation3": "Notez que l'impact sur l'historique de demande est immédiat, mais la prévision ne sera mise à jour que lors du prochain calcul de prévision.",
  "datepickerEndDateCantBeBeforeStartDate": "La date de fin ne peut être avant la date de début",
  "datepickerStartDateCantBeAfterEndDate": "La date de début ne peut être après la date de fin",
  "possible": "Possible",
  "outlierAlreadyExistForthisPeriod": "Une anomalie de demande existe déjà pour cette période",
  "eventXMarkedAsRead": "L'événement \"{name}\" a été marqué comme lu",
  "outlierCreationExplanation": "Cette période sera exclue par Flowlity lors de la création de la prédiction. Les modifications prendront effet lundi, après la prochaine mise à jour de la prédiction durant le weekend.",
  "outlierAutoAcceptedTooltip": "Cette anomalie de demande passée, détectée par Flowlity, sera automatiquement exclue lors de la création de la prédiction de demande",
  "weekAbbreviation": "S",
  "outlierDetectedByFlowlityExplanation": "Cette anomalie de demande passée, détectée par Flowlity, a été automatiquement exclue lors de la création de la prédiction de demande",
  "editOutlier": "Modifier l'anomalie de demande",
  "outlierEventCreationSuccess": "Anomalie de demande créée avec succès",
  "outlierEventCreationError": "Une erreur est survenue lors de la création de l'anomalie de demande",
  "outlierEventEditingSuccess": "Anomalie de demande modifiée avec succès",
  "outlierEventEditingError": "Une erreur est survenue lors de la modification de l'anomalie de demande",
  "date": "Date",
  "addOutlierWrongTimebucket": "Passez à la granularité \"%{timebucket}\" (utilisée pour le calcul de la prédiction) pour ajouter une anomalie de demande",
  "addOutlierFuturePeriod": "Une anomalie de demande ne peut être ajoutée que dans le passé",
  "addOutlierAlreadyExists": "Une anomalie de demande existe déjà pour cette période, vous pouvez la modifier au lieu d'en créer une nouvelle",
  "addOutlier": "Ajouter une anomalie de demande",
  "default": "Défaut",
  "forecastGranularity": "Granularité de la prédiction",
  "tooltipDefaultComputationTimebucketDropdownDemand": "Cette granularité est utilisée pour le calcul de la prédiction",
  "tooltipDefaultViewTimebucketDropdown": "Cette granularité est la vue par défaut",
  "tooltipDefaultViewTimebucketDropdownDemand": "Ma prédiction ne peut être modifiée que dans cette granularité",
  "format": "Format",
  "destination": "Destination",
  "file": "Fichier",
  "download": "Télécharger",
  "analytics": "Analytics",
  "tacticalNoun": "Tactical",
  "capacity": "Capacité",
  "salesOrders": "Commandes de vente",
  "supplyOrders": "Commandes d'approvisionnement",
  "settings": "Configurations",
  "dashboardNoun": "Dashboard",
  "demandNoun": "Demande",
  "planningNoun": "Planning",
  "whatsNew": "Nouveautés",
  "language": "Langue",
  "profileSettings": "Paramètres du profil",
  "logout": "Déconnexion",
  "dimension": "Dimension",
  "tableNoun": "Table",
  "creditsPlural": "Crédit | Crédit | Crédits",
  "pinTheSidebar": "Épingler",
  "unpinTheSidebar": "Détacher",
  "selectionIsDisabledWhenSimulationIsValidated": "La sélection est désactivée lorsque la simulation est validée",
  "selectionIsOnlyAvailableForMtsFlowlityAiProducts": "La sélection est uniquement disponible pour les produits MTS Flowlity AI",
  "selected": "{count} sélectionné | {count} sélectionné | {count} sélectionnés",
  "itemsSelected": "Éléments sélectionnés",
  "errorWhileFetchingTagCategories": "Une erreur est survenue lors de la récuperation des catégories de tags",
  "tagCategoryUpdateSuccess": "La catégorie de tags a été mise à jour avec succès",
  "tagCategoryUpdateError": "Une erreur est survenue lors de la mise à jour de la catégorie de tags",
  "tagCategoryDeleteSuccess": "La catégorie de tags \"{name}\" a été supprimée avec succès",
  "tagCategoryDeleteError": "Une erreur est survenue lors de la suppression de la catégorie de tags",
  "deleteTagCategory": "Supprimer la catégorie de tags ?",
  "deleteTagCategoryButton": "Supprimer la catégorie",
  "tagCategoryCreateSuccess": "La catégorie de tags a été créée avec succès",
  "tagCategoryCreateError": "Une erreur est survenue lors de la création de la catégorie de tags",
  "categoryName": "Nom de la catégorie",
  "optional": "Optionnel",
  "NProductsSelected": "0 produit sélectionné | 1 produit sélectionné | {countDisplay} produits sélectionnés",
  "downloadProductsCSVError": "Une erreur s'est produite lors du téléchargement du CSV des produits.",
  "cannotOpenDetailsWhenSelectingAll": "Vous ne pouvez pas ouvrir les détails lorsque plus de {max} éléments sont sélectionnés",
  "downloadCSV": "Télécharger la liste en CSV",
  "dueDate": "Date de fin de période figée",
  "fixPeriod": "Figer la période de recommandations",
  "fixPeriodTitle": "Figer la période pour les produits sélectionnés ?",
  "discardPeriod": "Défiger la période de recommandations",
  "discardPeriodTitle": "Défiger la période de recommandations pour les produits sélectionnés ?",
  "discardInventoryStrategyChanges": "Annuler les modifications de la stratégie d'inventaire",
  "discardInventoryChangesWarningProductDetail": "Vous perdrez toutes les modifications de la stratégie d'inventaire appliquées à ce produit.",
  "inventoryStrategyChangedWarning": "Stratégie d'inventaire modifiée.",
  "inventoryStrategyChangedBannerPart1": "Nouveau plan d'approvisionnement avec",
  "incomingChanges": "Modifications en cours",
  "inventoryStrategyChangedBannerPart2Planning": "sera prêt après le prochain calcul. Vous pouvez cliquer sur \"Optimiser\" pour obtenir immédiatement un nouveau plan d'approvisionnement.",
  "inventoryStrategyChangedBannerPart2Demand": "sera prêt après le prochain calcul. Vous pouvez cliquer sur \"Voir détails\" pour accéder au Planning page et voir plus de détails.",
  "optimize": "Optimiser",
  "seeDetails": "Voir détails",
  "discardInventoryStrategyChangesSuccess": "Vos modifications de stratégie d'inventaire ont été annulées.",
  "discardInventoryStrategyChangesError": "Vos modifications de stratégie d'inventaire n'ont pas pu être annulées, veuillez réessayer.",
  "productListDiscardInventoryStrategyChangesTitle": "Annuler les modifications de stratégie d'inventaire ?",
  "productListDiscardInventoryStrategyChangesTooltip": "Annuler les modifications de la stratégie d'inventaire qui n'ont pas encore été prises en compte dans le calcul du plan d'approvisionnement.",
  "productsListDiscardInventoryStrategyChangesDescription": "Lorsque vous annulez, toutes les modifications apportées aux produits sélectionnés qui n'ont pas été appliquées au calcul du plan d'approvisionnement seront perdues.",
  "inventoryStrategyChangedBannerPart2": "sera prêt après le prochain calcul. Vous pouvez cliquer sur \"Optimiser\" pour obtenir immédiatement un nouveau plan d'approvisionnement.",
  "discardPeriodDescription": "Si vous défiguez, Flowlity recommandera un plan d'approvisionnement pour toute la période de planification.",
  "discardPeriodConfirmButton": "Défiger",
  "discardPeriodSuccess": "La fixation de période a été annulée",
  "discardPeriodError": "Une erreur est survenue lors de l'annulation de la fixation de période",
  "fixPeriodSuccess": "La période a été figée",
  "fixPeriodError": "Une erreur est survenue lors de la fixation de la période",
  "downloadXlsx": "Télécharger la liste en XLSX",
  "xlsxExplanation": "XLSX est un format de fichier tableur utilisé par Microsoft Excel pour stocker des données dans des tableaux avec des formules et du formatage",
  "downloadCsv": "Télécharger la liste en CSV",
  "csvExplanation": "Valeurs séparées par des virgules",
  "downloadTsv": "Télécharger la liste en TSV",
  "tsvExplanation": "Valeurs séparées par des tabulations",
  "noBom": "Ce produit ne fait partie d'aucun BOM",
  "deleteOrder": "Supprimer la commande",
  "open": "Ouvrir",
  "close": "Fermer",
  "orderDetailsAction": "{action} les détails de la commande",
  "editInventoryStrategy": "Modifier la stratégie de stock",
  "selectCurrentPage": "Sélectionner la page",
  "selectAllPages": "Tout sélectionner",
  "clearSelection": "Désélectionner",
  "units": "unité | unité | unités",
  "mixed": "mixte",
  "myStockMaxValue": "Ma valeur de stock maximale",
  "searchASupplier": "Rechercher un fournisseur",
  "supplier": "Fournisseur",
  "delayCoverage": "Couverture du délai",
  "categoriesOfTags": "Catégories de tags",
  "here": "ici",
  "useDefaultBufferPolicy": "Utiliser la politique par défaut",
  "supplierDelay": "Couverture du délai",
  "supplierMode": "Valeur",
  "ropLevel": "Niveau de point de commande",
  "quantity": "Quantité",
  "qty": "Qté",
  "coverage": "Couverture",
  "type": "Type",
  "time": "Temps",
  "stock": "Stock",
  "maxValue": "Ma valeur maximale",
  "minValue": "Ma valeur minimale",
  "bufferLevel": "Niv. de stock sécurité",
  "bufferLevelTip": "Le niveau de stock sécurité Flowlity aura un impact sur votre niveau de stock sécurité. Plus le niveau de stock sécurité est faible, plus votre niveau minimum sera bas. Plus le niveau de stock sécurité est élevé, plus votre niveau minimum sera élevé.",
  "back": "Retour",
  "apply": "Appliquer",
  "select": "Sélectionner",
  "strategyType": "Type de stratégie",
  "strategyTypeDesc": "Appliquez la stratégie MTO ou MTS pour tous les produits et spécifiez les configurations détaillées.",
  "supplyBufferPolicyDesc": "Spécifiez si la politique de buffer sera Temps, Stock, Aucun, ou utilisez simplement les configurations par défaut.",
  "myStockMax": "Mon stock max",
  "myStockMaxDesc": "Définir la valeur maximale de stock pour tous les produits.",
  "whatDoYouWantToEdit": "Que voulez-vous éditer ?",
  "viewCreationSuccess": "La vue a bien été créée",
  "viewUpdateSuccess": "La vue a bien été mise à jour",
  "viewRemovalSuccess": "La vue a bien été supprimée",
  "excludeNewProducts": "Exclure les nouveaux produits",
  "excludeNewProductsTooltip": "Exclure les produits qui n'étaient pas disponibles pendant la période comparée précédente",
  "sum": "Somme",
  "avg": "Moyenne",
  "samePeriodLastYear": "La même période l'année dernière",
  "previousPeriod": "La période précédente",
  "startUsingFlowlity": "Le début de l'utilisation de Flowlity",
  "compareWith": "À comparer avec",
  "period": "Période",
  "lastMonth": "Mois dernier",
  "lastQuarter": "Trimestre dernier",
  "last6Months": "6 derniers mois",
  "lastYear": "Année dernière",
  "zeroStockDays": "Jours de stock à zéro",
  "stockCoverage": "Couverture de stock",
  "pastDemand": "Demande passée",
  "inventoryLevel": "Niveau de stock",
  "average": "Moyenne",
  "homeKpiPercentageTooltip": "Différence en pourcentage entre les périodes:",
  "addView": {
    "title": "Ajouter une nouvelle vue",
    "desc": "Une vue vous permet de personnaliser la liste de produits en sauvegardant filtres, colonnes et tri."
  },
  "clearAllFilters": "Désélectionner les filtres",
  "reset": "Réinitialiser",
  "saveView": "Enregistrer la vue",
  "enterName": "Entrez un nom",
  "deleteViewTitle": "Voulez-vous supprimer la vue \"{name}\" ?",
  "deleteViewDescription": "Si vous supprimez cette vue, vous perdrez toutes les configurations telles que les filtres et les paramètres de colonne.",
  "reorderViews": "Réorganiser les vues",
  "renameView": "Renommer la vue",
  "name": "Nom",
  "duplicate": "Dupliquer",
  "rename": "Renommer",
  "reorder": "Réorganiser",
  "addANewView": "Ajouter une nouvelle vue",
  "defaultView": "Vue par défaut",
  "discardModalTitle": "Annuler les modifications ?",
  "openDemand": "Ouvrir la demande",
  "openPlanning": "Ouvrir le planning",
  "product": "Produit",
  "by": "Par",
  "day": "Jour | Jour | Jours",
  "week": "Semaine | Semaine | Semaines",
  "month": "Mois",
  "quarter": "Trimestre",
  "year": "An",
  "discardChangesDescription": "Vous perdrez toutes les modifications qui ont été faits pendant l'édition",
  "discardChanges": "Ignorer les modifications",
  "save": "Enregistrer",
  "customers": "Clients",
  "customer": "Client",
  "selectCustomer": "Sélectionner un client",
  "andXMore": "et {count} de plus...",
  "noSuppliersFound": "Aucun fournisseur trouvé",
  "noAlertsAvailable": "Aucune alerte disponible",
  "learnMore": "En savoir plus",
  "new": "Nouveau",
  "days": "jour | jour | jours",
  "ascending": "Ascendant",
  "descending": "Descendant",
  "baseTable": {
    "loadOlderData": "Données passées",
    "simulation": "simulation uniquement",
    "simulationTooltipTitle": "Changement sur l'approvisionnement ferme",
    "simulationTooltipSubtitle": "Les modifications ne seront pas sauvegardés.",
    "simulationTooltipText": "L'approvisionnement ferme ne peut être modifié que dans votre ERP."
  },
  "cancel": "Annuler",
  "confirm": "Confirmer",
  "undo": "Annuler",
  "dateRange": "Période",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Une erreur est survenue lors de la récuperation du status de la mise à jour des données"
    }
  },
  "backToEditing": "Retour à l'édition",
  "delete": "Supprimer",
  "deleteOrdersWarningText": "Êtes-vous sûr de vouloir supprimer les commandes sélectionnées ?",
  "deleteWarningCancelButton": "Annuler",
  "deleteWarningConfirmButton": "Je confirme",
  "deleteWarningTitle": "Confirmation de suppression",
  "deliveryDate": "Date de livraison",
  "demand": {
    "chart": {
      "allOrders": "Expéditions futures",
      "demandFinalForecast": "Prédiction finale",
      "demandForecastFlowlity": "Prédiction Flowlity",
      "demandForecastExternal": "Prédiction externe",
      "firmOrders": "Expéditions confirmées",
      "plannedOrders": "Expéditions planifiées",
      "historicalDemand": "Demande passée",
      "confidenceInterval": "Intervalle de confiance",
      "maxFlowPred": "Intervalle de confiance - max",
      "minFlowPred": "Intervalle de confiance - min",
      "myforecast": "Ma prédiction",
      "supplierDemand": "Consommation réelle du client",
      "title": "Prédictions"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Vue agrégée",
      "clientDemand": "Client",
      "finalForecast": "Prédiction finale",
      "demandForecastExternal": "Prédiction externe",
      "finalForecastExpl": "La prédiction totale prenant en compte la demande future, Ma prévision et la prédiction de base (Flowlity ou externe)",
      "externalForecast": "Prédiction externe",
      "externalForecastExpl": "Prédiction ou demande dépendante fournie par une source externe",
      "futureDemand": "Demande future",
      "futureDemandExpl": "Demande future enregistrée comme ferme ou planifiée",
      "firmDemand": "Demande ferme",
      "firmDemandExpl": "Demande ferme, prévue pour le futur",
      "plannedDemand": "Demande planifiée",
      "plannedDemandExpl": "Demande planifiée pour le futur",
      "updateError": "Une erreur s'est produite pendant la sauvegarde de votre donnée",
      "updateSuccess": "La donnée a bien été enregistrée"
    },
    "discardChanges": "Annuler",
    "editingMyForecast": "Édition",
    "regularView": {
      "pastDemand": "Demande passée",
      "pastDemandExpl": "Demande enregistrée dans le passé",
      "myForecast": "Ma prédiction",
      "myForecastExpl": "Prédiction manuelle influençant la prédiction finale par rapport à la prédiction de base (Flowlity ou Externe)",
      "flowlityForecast": "Prédiction Flowlity",
      "flowlityForecastExpl": "Prédiction générée par l'IA de Flowlity"
    },
    "saveUpdateButton": "Sauvegarder",
    "saving": "Sauvegarde en cours",
    "supplierView": {
      "allOrders": "Expéditions futures",
      "firmOrders": "Expéditions confirmées",
      "plannedOrders": "Expéditions planifiées",
      "historicalDemand": "Consommation réelle du client"
    }
  },
  "deselectAll": "Tout désélectionner",
  "discardCancelButton": "Continuer d'éditer",
  "discardConfirmButton": "Annuler les modifications",
  "discardText": "Vous perdrez tous les modifications qui ont été faits pendant l'édition.",
  "discardTitle": "Annuler les modifications ?",
  "productsCount": "0 produits | 1 produit | {count} produits",
  "tagsCount": "0 tags | 1 tag | {count} tags",
  "dropdown": {
    "alerts": {
      "title": "Alertes"
    },
    "customers": "Clients",
    "dateRange": "Période",
    "deselect": "Désélectionner",
    "deselectAll": "Tout désélectionner",
    "products": "Produits",
    "search": "Recherche {suffix}",
    "selectAll": "Tout sélectionner",
    "subContractedComponents": "Composants",
    "suppliers": {
      "title": "Fournisseurs | {name} | {count} Fournisseurs"
    },
    "tags": {
      "title": "Tags | {name} | {count} Tags",
      "counter": "Aucun tag disponible | 1 tag disponible | {count} tag disponibles",
      "cell": {
        "displayTitle": "- | {name} | {count} tags"
      }
    }
  },
  "emptyData": "Pas de donnée disponible",
  "credits": {
    "available": "Crédits disponibles",
    "getMore": "Obtenir plus de crédits",
    "currentLimit": "Actuellement, vous avez {limit} crédits optim disponibles chaque mois",
    "needTitle": "Combien de crédits par mois avez-vous besoin ?",
    "credits/month": "crédits/mois",
    "requestWillBeSentExplanation": "Votre demande sera envoyée à support{'@'}flowlity.com",
    "cancel": "Annuler",
    "topupSubject": "{companyName} : augmenter les crédits pour {siteName}",
    "topupBody": "Veuillez augmenter la limite mensuelle disponible à {amount} crédits/mois pour {siteName} / {companyName} (ID du site : {siteId})",
    "notEnough": "Pas assez de crédits pour exécuter la planification",
    "contactFlowlity": "Obtenez plus de crédits en contactant Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "Ce jour est",
    "empty": "vide",
    "flowlitySupply": "Cette valeur est recommandée par Flowlity",
    "supplyIsConfirmed": "Cet approvisionnement a été validé",
    "areValidated": "est validé | est validé | sont validés",
    "fullyFrozen": "gelé | gelée",
    "lotSize": "Taille de lot",
    "monthMarkedAs": "Ce mois est",
    "moq": "Mini de commande",
    "originalValue": "Valeur initiale",
    "partlyFrozen": "partiellement gelé | partiellement gelée",
    "validated": "Attention, la commande pour cette supply a été validée",
    "weekMarkedAs": "Cette semaine est"
  },
  "lastOrderDate": "Date d'échéance",
  "lastConfirmedDeliveryDate": "Date de livraison confirmée",
  "lastConfirmedDeliveryDateTitle": "Date de livraison",
  "lastConfirmedDeliveryDateSubtitle": "dernière confirmation",
  "modal": {
    "backToList": "Retour à liste",
    "backToOrder": "Retour à la commande",
    "backToCapacityUnit": "Retour à la capacité"
  },
  "no": "Non",
  "notification": {
    "error": "Erreur",
    "success": "Succès"
  },
  "dashboard": {
    "actual": "Actuel",
    "daysOfCoverage": "Couverture de stock",
    "days": "jours",
    "tagDropdownPlaceholder": "Filtrer les résultats par tag"
  },
  "planning": {
    "chart": {
      "allOrders": "Commandes",
      "currentDay": "J-1",
      "week": "[S]",
      "weekTooltip": "[Semaine]",
      "Expired": "Expirant",
      "firmOrders": "Commandes fermes",
      "intervalMax": "Flowlity Max",
      "intervalMin": "Flowlity Min",
      "Maximum": "Maximum",
      "Minimum": "Minimum",
      "myMinimum": "Mon Stock Min",
      "reorderPoint": "Point de Commande (ROP)",
      "plannedOrders": "Commandes planifiées",
      "Stock": "Stock",
      "simulatedMin": "Nouveau Stock Min",
      "myStockMax": "Mon Stock Max"
    },
    "error": {
      "expiredStock": "Une erreur est survenue lors de la récuperation du stock expiré",
      "storageSites": "Une erreur est survenue lors de la récuperation du stock expiré"
    }
  },
  "planningTable": {
    "aggregatedView": "Vue agrégée",
    "currentDay": "J-1",
    "restOf": "Fin",
    "discardCancelButton": "Continuer d'éditer",
    "discardChanges": "Annuler les modifications",
    "discardConfirmButton": "Annuler les modifications",
    "discardText": "Vous ne pourrez plus récupérer les modifications effectuées.",
    "discardTitle": "Annuler les modifications ?",
    "editingMySupply": "Édition",
    "finalForecast": "Prédiction finale",
    "justInTime": "Ce produit est paramétré pour ne pas avoir de plan d'approvisionnement proposé",
    "leadTime": "jours d'appro",
    "maximum": "Flowlity Max",
    "minimum": "Flowlity Min",
    "myMinimum": "Mon Stock Min",
    "reorderPoint": "Point de Commande (ROP)",
    "severalWarningsFound": "{count} alertes ont été constatés avec le produit",
    "noStock": "Nous n'avons pas reçu de donnée de stock pour ce produit. Nous avons donc défini le stock à 0",
    "noSupplierWarning": "Ce produit n'a aucun fournisseur lié, veuillez ajouter des fournisseurs",
    "orders": "Mon Plan",
    "productAdminPage": "administration du produit",
    "recomputeSuppliesInfo": "Nous manquons de données historiques sur ce produit pour recalculer des recommandations de supply",
    "recomputeSuppliesSuccess": "Les recommandations de supply ont été recalculées, rafraichissez la page pour les voir",
    "saveConfirmButton": "Oui",
    "saveTitle": "Appliquer les modifications et mettre à jour les commandes ?",
    "saveUpdateButton": "Sauvegarder",
    "saving": "Sauvegarde en cours",
    "status3Supplies": "Commandes fermes",
    "plannedOrders": "Commandes planifiées",
    "stock": "Stock",
    "averageFinalForecast": "Couverture de stock en jours",
    "stockExpired": "Stock expirant"
  },
  "products": "Produits",
  "productSearch": {
    "aboveFlowlityMax": "Au-dessus du stock optimal",
    "alerts": "Alertes",
    "events": "Événements",
    "belowFlowlityMin": "En dessous du stock optimal",
    "expiringStocks": "Stock expiré",
    "outOfStock": "Rupture de stock",
    "selectedAlerts": "0 Alerte | 1 Alerte | {count} Alertes",
    "selectedEvents": "0 Événement | 1 Événement | {count} Événements",
    "selectedProductsFiltersTags": "0 Tag | 1 Tag | {count} Tags",
    "selectedTagCategories": "0 Catégorie de tag | 1 Catégorie de tag | {count} Catégories de tags",
    "lastNDays": "les {count} derniers jours",
    "lastNDaysCapitalized": "@.capitalize:productSearch.lastNDays",
    "nextNDays": "{count} prochains jours",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Tout l'horizon",
    "daysToAct": "Jours pour agir",
    "daysToActExplanation": "Nombre de jours restants pour résoudre le problème. Cette option prend en compte le paramètre \"délai de livraison\", représentant ainsi l'urgence de la résolution de l'alerte. Si plusieurs fournisseurs existent, le délai moyen est pris en compte",
    "toActWithin": "Dans les @:productSearch.nextNDays",
    "alertDate": "Date de l'alerte",
    "alertDateExplanation": "Nombre de jours avant que le problème ne se soit produit",
    "alertDuration": "Durée de l'alerte",
    "alertValue": "Valeur de l'alerte",
    "stockAlerts": "Alertes de stock",
    "demandAlerts": "Alertes de demande",
    "externalForecastAlerts": "Alertes de prédiction externe"
  },
  "search": "Recherche {suffix}",
  "searchBySite": "Rechercher par site",
  "selectedSubContractedComponents": "0 Composant | 1 Composant | {count} Composants",
  "selectedSuppliers": "0 Fournisseur | 1 Fournisseur | {count} Fournisseurs",
  "selectedCustomers": "0 Client | 1 Client | {count} Clients",
  "selectedAttributes": "O Attribut | 1 Attribut | {count} Attributs",
  "site": "Site",
  "siteDropdown": {
    "discardCancelButton": "Continuer d'éditer",
    "discardConfirmButton": "Annuler les modifications",
    "discardText": "Vous ne pourrez plus récupérer les modifications effectuées.",
    "discardTitle": "Annuler les modifications ?"
  },
  "suppliers": "Fournisseurs",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} tags"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "et {count} plus..."
    }
  },
  "updateError": "Un problème a été rencontré pendant la mise à jour des commandes",
  "updateSuccess": "Commande(s) correctement mise(s) à jour",
  "yes": "Oui",
  "yesterday": "Hier",
  "today": "Aujourd'hui",
  "loadMore": "Charger plus",
  "editing": "Édition",
  "loading": "Chargement...",
  "restoreInitial": "Rétablir l'initial",
  "applyToAllPeriods": "Appliquer à toutes les périodes",
  "applyToSpecificPeriods": "Appliquer à des périodes spécifiques",
  "clearAll": "Tout supprimer",
  "none": "Aucune",
  "toOpenInANewTab": "pour ouvrir un nouvel onglet",
  "noEntitiesFound": "Aucun {entities} trouvé",
  "byName": "par nom",
  "byNameAndRef": "par nom et reference",
  "notFound": "{entity} non trouvé(e)",
  "notFoundExplanation": "ID {id} de {entity} n'existe(e) pas ou a été supprimé(e)",
  "selectionNotFoundExplanation": "Vous essayez d'utiliser une sélection de produits qui n'existe pas encore dans votre session. Veuillez retourner à la page de la liste des produits et sélectionner à nouveau les produits.",
  "nMore": "{count} autre | {count} autres",
  "overdue": "En retard",
  "more": "Plus",
  "greaterThan": "Supérieur à",
  "between": "Entre",
  "lessThan": "Inférieur à",
  "enter": "Entrer",
  "advancedConfiguration": "Configuration avancée",
  "internalCustomerForecastTooltip": "Les prévisions ne s'appliquent pas aux clients internes. Seule la demande ferme est utilisée pour le calcul.",
  "familyConstraints": "Contraintes de la famille",
  "familyTagName": "Tag de la famille",
  "familyMOQ": "Qte min de la famille",
  "familyUnit": "Unité de la famille",
  "familyMOQExplanation": "Ce MOQ est appliqué au groupe de produits appartenant à cette famille",
  "familyLotSize": "Taille de lot de la famille",
  "familyLotSizeExplanation": "Cette taille de lot est appliquée au \"niveau familial\" sur tous les produits partageant le même composant de produit",
  "tags": "Tags",
  "tagCategories": "Catégories de tags",
  "usedToGroupOrders": "Regroupe les commandes",
  "createTagCategory": "Créer une catégorie de tags",
  "addToNewTagCategory": "Ajouter à une nouvelle catégorie de tags",
  "editTagCategory": "Modifier la catégorie de tags",
  "tagsSelected": "{count} tag sélectionné | {count} tag sélectionné | {count} tags sélectionnés",
  "tagCategoryName": "Nom de la catégorie",
  "tagCategoryExplanation1": "Les catégories de tags sont un système pour regrouper des tags ayant la même signification afin d'organiser et de classer les produits",
  "tagCategoryExplanation2": "par leurs attributs ou caractéristiques. Par exemple, vous pouvez avoir des catégories de tags comme \"Couleur\", \"Taille\" et \"Matériau\", chacune pouvant contenir plusieurs tags spécifiques. Ensuite, vous pouvez utiliser ces catégories dans les listes de produits pour obtenir des sélections plus précises.",
  "seeLess": "voir moins",
  "seeMore": "voir plus",
  "createCategory": "Créer une catégorie",
  "saveCategory": "Enregistrer la catégorie",
  "add": "Ajouter",
  "addToTagCategory": "Ajouter à la catégorie de tags",
  "unassignCategories": "Retirer des catégories",
  "addToANewCategory": "Ajouter à une nouvelle catégorie",
  "useToGroupOrders": "Utiliser pour regrouper les commandes",
  "confirmToAssignNTagsToCategory": "Êtes-vous sûr de vouloir ajouter ce tag à la catégorie \"{name}\" ? | Êtes-vous sûr de vouloir ajouter ces {count} tags à la catégorie \"{name}\" ?",
  "confirmToUnassignNTagsToCategory": "Êtes-vous sûr de vouloir retirer le tag de sa catégorie ? | Êtes-vous sûr de vouloir retirer ces {count} tags de leur(s) catégorie(s) ?",
  "unassign": "Retirer",
  "errorAssignmentTags": "Une erreur est survenue lors de l'assignation ou de la désassignation des tags à la catégorie",
  "successAssignmentTags": "{count} tags ont été ajoutés à la catégorie {name}",
  "successUnassignmentTags": "{count} tags ont été désassignés de leurs catégories",
  "successUseTagsToGroupOrders": "Les tags ont été mis à jour",
  "errorUseTagsToGroupOrders": "Une erreur est survenue lors de l'utilisation des tags sélectionnés pour regrouper les commandes",
  "confirmUseToGroupOrders": "Êtes-vous sûr de vouloir utiliser {count} tags sélectionnés pour regrouper la commande ?",
  "use": "Utiliser",
  "errorDeletingTags": "Une erreur est survenue lors de la suppression des tags sélectionnés",
  "successDeletingTags": "Les tags ont été supprimés",
  "deleteNTags": "Supprimer {count} tags ?",
  "theseTagsUsedInNProducts": "Ces tags sont utilisés dans {count} produits. Vous ne pourrez pas annuler cette action.",
  "tooltipProductDefaultSupplier": "Lors de la sélection d'un fournisseur par défaut, les commandes seront attribuées par défaut à ce fournisseur.",
  "defaultSupplier": "Fournisseur par défaut",
  "bomFetchError": "Une erreur s'est produite lors de la récupération des données de BOM",
  "configureColums": "Configurer les colonnes",
  "yourDefaultCompany": "Votre entreprise par défaut",
  "yourDefaultSite": "Votre site par défaut",
  "tagCategory": "Catégorie de tags",
  "quotas": {
    "createQuotaDisabledWarning": "Vous n'êtes pas autorisé à définir un fournisseur préféré et à créer de nouveaux quotas lorsqu'il existe des quotas permanents. Pour être autorisé à faire ces configurations, veuillez supprimer les quotas permanents",
    "createQuotas": "Créer des quotas",
    "defaultSupplierChanged": "Le fournisseur par défaut a été mis à jour",
    "defaultSupplierIntegratedWarning": "Cette valeur est intégrée à partir d'une source externe",
    "defaultSupplierNoAvailable": "Le fournisseur par défaut ne peut pas être modifié lorsque vous avez des quotas actifs",
    "defaultQuotaExistsWarning": "Vous ne pouvez pas créer de quotas lorsque un fournisseur par défaut est défini",
    "deleteQuotaWarningContent": "Si vous supprimez ces quotas, vous perdrez les configurations créées. Vous ne pourrez pas annuler cette action.",
    "deleteQuotaWarningTitle": "Voulez-vous supprimer ces quotas",
    "expired": "Expiré",
    "expiredQuotas": "Quotas expirés",
    "integratedQuotaWarning": "Les quotas sont intégrés à partir d'une source externe.",
    "noDefaultSupplier": "Aucun fournisseur par défaut",
    "permanent": "Quotas permanents",
    "setDefaultSupplier": "Définir le fournisseur par défaut",
    "setDefaultSupplierTooltip": "En sélectionnant un fournisseur préféré, les commandes seront attribuées par défaut à ce fournisseur.",
    "setQuotaForSuppliers": "Définir les quotas pour les fournisseurs",
    "updateQuotas": "Mettre à jour les quotas",
    "form": {
      "create": "Créer des quotas",
      "duration": "Durée",
      "durationRequired": "Veuillez sélectionner une durée",
      "permanentDisabledTooltip": "Vous ne pouvez pas définir des quotas comme permanents lorsqu'il existe des quotas avec durée.",
      "rangeAlreadyExists": "Vous ne pouvez pas sélectionner une plage de dates où des quotas existent déjà",
      "save": "Sauvegarder",
      "setAsPermanent": "Définir comme permanent",
      "totalQuotaWarningCurrent": "La somme des quotas actuellement est de",
      "totalQuotaWarningExpected": "La somme des valeurs doit être égale à"
    }
  },
  "productEditTabs": {
    "events": "Événements",
    "info": "Infos",
    "inventoryStrategies": "Stratégie d'inventaire",
    "quotas": "Quotas",
    "similarProducts": "Produits similaires",
    "suppliers": "Fournisseurs",
    "tags": "Tags",
    "bom": "Bom"
  },
  "from": "De",
  "to": "À",
  "selectDate": "Sélectionner la date",
  "click": "Clic",
  "thereAreOnlySitesWhereProductPresented": "Il n'y a que des sites où le produit actuel est présenté.",
  "switchBackTo": "Revenir à",
  "toNavigateThroughTheList": "Pour naviguer dans la liste",
  "searchByNameOrPasteRefs": "Recherchez par nom ou collez des références",
  "value": "Valeur",
  "value€": "Valeur, {currency}",
  "count": "Nombre",
  "atNextDelivery": "À la prochaine livraison",
  "atDeliveryDate": "À la date de livraison",
  "current": "Actuel",
  "last7days": "7 derniers jours",
  "last30days": "30 derniers jours",
  "last3months": "3 derniers mois",
  "last6months": "6 derniers mois",
  "last12months": "12 derniers mois",
  "last7d": "7 derniers jours",
  "last30d": "30 derniers j",
  "last3m": "3 derniers m",
  "last6m": "6 derniers m",
  "last12m": "12 derniers m",
  "next30days": "30 prochains jours",
  "next3months": "3 prochains mois",
  "next6months": "6 prochains mois",
  "next12months": "12 prochains mois",
  "next30d": "30 prochains j",
  "next3m": "3 prochains m",
  "next6m": "6 prochains m",
  "next12m": "12 prochains m",
  "dataSource": "Source de données",
  "formula": "Formule",
  "kpiType": "Type de KPI",
  "firmDemand": "Demande ferme",
  "dataMeasure": "Mesure des données",
  "measure": "Mesure",
  "forecastType": "Type de prévision",
  "ordersType": "Type de commandes",
  "ordersNoun": "Commandes d'approvisionnement",
  "min": "Min",
  "max": "Max",
  "trend": "Tendance",
  "ratio": "Ratio",
  "mape": "MAPE",
  "mae": "MAE",
  "fva": "FVA",
  "all": "Tout",
  "firm": "Ferme",
  "released": "Fermes",
  "planned": "Planifié",
  "statistic": "Statistique",
  "pastPerformance": "Performance passée",
  "finalForecast": "Prévision finale",
  "stockMin": "Stock minimum",
  "stockMax": "Stock maximum",
  "flowlityForecastStatisticExplanation": "L'ensemble comprend des indicateurs de valeur/quantité pour une période spécifique",
  "flowlityForecastPastPerformanceExplanation": "L'ensemble comprend des indicateurs de qualité de la prédiction sous la forme de MAPE, MAE, valeur ajoutée des prévisions",
  "trendGeneralExplanation": "La tendance compare deux périodes : la période sélectionnée et la période précédente. La tendance décrit l'évolution de la valeur de la période sélectionnée par rapport à la période précédente, en pourcentage",
  "kpi": "KPI",
  "createKPI": "Créer un KPI",
  "thisKpiAlreadyAdded": "Ce KPI est déjà ajouté",
  "pastDemandQtySumDescription": "Demande passée en unité par défaut pour {for}",
  "pastDemandQtyTrendDescription": "Compare la quantité de demande passée (en unités par défaut) entre les périodes {period1} et {period2}",
  "daysShort": "j",
  "deleteKPI": "Supprimer le KPI",
  "deleteKPIMsg": "Lorsque vous supprimez, ce KPI sera retiré de toutes les vues et sites de votre entreprise, pour tous les utilisateurs. Cette action est irréversible.",
  "kpiDeleted": "KPI supprimé",
  "allOrders": "Toutes les commandes",
  "firmOrders": "Commandes fermes",
  "plannedOrders": "Commandes planifiées",
  "inventory": "Stock",
  "reference": "Référence",
  "nextDelivery": "Prochaine livraison",
  "simulated": "Simulé",
  "trendGapsLast7d": "[J-14; J-7] et [J-7; J-1]",
  "trendGapsLast30d": "[J-60; J-31] et [J-30; J-1]",
  "trendGapsLast3m": "[M-6; M-4] et [M-3; M-1]",
  "trendGapsLast6m": "[M-12; M-7] et [M-6; M-1]",
  "trendGapsLast12m": "[M-24; M-13] et [M-12; M-1]",
  "trendGapsNext30d": "[J-30; J-1] et [J+1; J+30]",
  "trendGapsNext3m": "[M-3; M-1] et [M+1; M+3]",
  "trendGapsNext6m": "[M-6; M-1] et [M+1; M+6]",
  "trendGapsNext12m": "[M-12; M-1] et [M+1; M+12]",
  "createKPIDescription": "Vous pouvez créer un KPI qui sera affiché sous forme de colonne dans la liste des produits. Ce KPI sera disponible dans toutes les vues et sur tous les sites de votre entreprise, pour tous les utilisateurs.",
  "pastDemandExplanation": "Demande passée (en {unit}) pour {for}{formula}",
  "pastDemandTrendExplanation": "Compare la demande passée (en {unit}) entre les périodes {gaps}",
  "firmDemandExplanation": "Commandes validées (en {unit}) reçues de vos clients externes ou internes (commandes de transfert, commandes de production, etc.) pour {for}",
  "finalForecastExplanation": "Prévision finale (en {unit}) pour {for}",
  "finalForecastTrendExplanation": "Compare la prévision finale (en {unit}) entre les périodes {gaps}",
  "externalForecastExplanation": "Prévision externe (en {unit}) pour {for}",
  "externalForecastTrendExplanation": "Compare la prévision externe (en {unit}) entre les périodes {gaps}",
  "myForecastExplanation": "Ma prévision (en {unit}) pour {for}",
  "myForecastTrendExplanation": "Compare ma prévision (en {unit}) entre les périodes {gaps}",
  "flowlityForecastExplanation": "Prévision Flowlity (en {unit}) pour {for}",
  "flowlityForecastTrendExplanation": "Compare la prévision Flowlity (en {unit}) entre les périodes {gaps}",
  "maeExplanation": "Erreur absolue entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision) en quantité",
  "mapeExplanation": "Erreur moyenne absolue en pourcentage entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision)/demande (en %)",
  "forecastValueAddedExplanation": "Valeur ajoutée par la prédiction flowlity par rapport à un modèle statistique (Moyenne Mobile des trois derniers mois). Lorsque la valeur est positive la prévision flowlity est meilleure de x%",
  "ordersCountExplanation": "Nombre de {dataset} pour {for}",
  "ordersValueExplanation": "Somme des valeurs de {dataset} pour {for}",
  "ordersQuantityExplanation": "Somme des quantités (en unité par défaut) de {dataset} pour {for}",
  "inventoryExplanation": "Niveau d'inventaire (en {unit}), {for}{formula}",
  "inventoryTrendExplanation": "Compare le niveau d'inventaire (en {unit}) entre les périodes {gaps}",
  "stockCoverageExplanation": "Couverture de stock (en jours), {for}{formula}",
  "stockMinExplanation": "Couverture de stock (en {unit}), {for}{formula}",
  "zeroStockDaysExplanation": "Pourcentage moyen de jours sans stock sur {for}",
  "zeroStockDaysTrendExplanation": "Compare le pourcentage moyen de jours sans stock entre les périodes {gaps}",
  "minimum": "Minimum",
  "maximum": "Maximum",
  "productDescription": "Description du produit",
  "leadTime": "Délai de livraison",
  "defaultUnitName": "Unité par défaut",
  "stockCoverageNext1YAvgTooltip": "Couverture de stock en jours - moyenne pour l'année prochaine",
  "stockReduction": "Réduction du stock %",
  "stockChangeTooltip": "Changement en % du niveau de stock par rapport à la base",
  "productName": "Nom du produit",
  "defaultUnit": "Unité par défaut",
  "endOfLife": "Fin de vie",
  "supplyBufferPolicyTooltip": "Stratégie appliquée pour couvrir l'incertitude fournisseur : buffer en temps ou en stock",
  "reviewDemand": "Revue de la Demande",
  "reviewPlanning": "Revue du Planning",
  "inventoryStrategyNoun": "Stratégie de stock",
  "similarProducts": "Produits similaires",
  "fixingRecommendationsDate": "Recommandations figées",
  "until": "Jusqu'à",
  "promotions": "Promotions",
  "demandPredictabilitySubtitle": "de la demande",
  "demandPredictabilityTooltip": "La prédictibilité de la demande est la qualité des données de demande passées utilisées pour générer la prévision",
  "demandPredictabilityNoTooltip": "Pas de demande passée, une prévision ne peut pas être générée",
  "demandPredictabilityLowTooltip": "La demande passée est limitée et ne permet pas de générer les meilleures prévisions",
  "demandPredictabilityMediumTooltip": "Flowlity génère les meilleures prévisions possibles, mais la demande peut avoir un comportement erratique",
  "demandPredictabilityHighTooltip": "Flowlity génère les meilleures prévisions possibles",
  "bufferLevelTooltip": "Facteur qui influence le niveau de stock minimum : plus le niveau de stock sécurité est élevé, moins vous acceptez de risque sur le produit, et plus le niveau de stock minimum est élevé",
  "firmOrdersQtyTooltip": "Quantité en commandes d'approvisionnement fermes",
  "nextDeliveryDate": "Date de la prochaine livraison",
  "shelfLife": "Durée de vie",
  "alerts": "Alertes",
  "next1YAvg": "moy. futur 1 an",
  "zeroStockDaysNext1YearTooltip": "Pourcentage moyen de jours sans stock sur l'année prochaine",
  "flowlityAi": "IA Flowlity",
  "bufferPolicy": "Politique de buffer",
  "initialValue": "Valeur initiale",
  "tagName": "Nom de tag",
  "noTags": "Aucun Tag Trouvé",
  "nProductsFound": "0 produit trouvé | 1 produit trouvé | {count} produits trouvé",
  "nTagsFound": "0 tags trouvé | 1 tag trouvé | {count} tags trouvé",
  "inventoryStrategyChanged": "Stratégie de stock modifiée",
  "noAttributesFound": "Aucun attribut trouvé",
  "noForecastModelDemandTypeFound": "Aucun type de demande de prévision trouvé",
  "continuous": "Continue",
  "demandBasedConstant": "Flowlity IA + Mon stock min",
  "demandBasedFlowlity": "Flowlity IA",
  "demandType": "Type de demande",
  "demandTypeDropdown": "Type de demande | {name} | {count} Types de demande",
  "demandPredictability": "Prédictibilité de la demande",
  "general": "General",
  "futureForecastEvents": "Événements de prévision futures",
  "decreasingMrp": "Diminution de la demande MRP",
  "fixedPeriod": "Période figée",
  "inventoryStrategyAttr": "Stratégie de stock",
  "timeBuffer": "Buffer temps",
  "mto": "MTO",
  "mts": "MTS",
  "noEol": "Pas de fin de vie",
  "noPolicy": "Pas de buffer",
  "punctual": "Ponctuelle",
  "reorderPoint": "Point de commande",
  "reorderPointAI": "Point de commande + IA",
  "reorderPointAIShort": "ROP + AI",
  "stockBuffer": "Buffer Stock",
  "supplyBufferPolicy": "Politique de buffer",
  "withComponents": "Avec composants",
  "punctualConstantFast": "Ponctuelle constante rapide",
  "punctualConstantSlow": "Ponctuelle constante lente",
  "punctualLumpySlow": "Ponctuelle irrégulière lente",
  "punctualLumpyFast": "Ponctuelle irrégulière rapide",
  "punctualSingleDemand": "Ponctuelle unique",
  "continuousLumpy": "Continue irrégulière",
  "continuousSmooth": "Continue régulière",
  "noData": "Pas de données",
  "poorData": "Peu de données",
  "less6mData": "Moins de 6 mois de données",
  "noDataTooltip": "Produit sans données de demande passées",
  "poorDataTooltip": "Produit sans demande passée depuis plus d'un an",
  "newTooltip": "Produits avec moins de six mois de données",
  "punctualConstantFastTooltip": "Produit ponctuel avec une demande faible, volatile et un intervalle court entre deux demandes en moyenne",
  "punctualConstantSlowTooltip": "Produit ponctuel avec une demande faible, volatile et un intervalle long entre deux demandes en moyenne",
  "punctualLumpySlowTooltip": "Produit ponctuel avec une demande volatile et un intervalle long entre deux demandes en moyenne",
  "punctualLumpyFastTooltip": "Produit ponctuel avec une demande volatile et un intervalle court entre deux demandes en moyenne",
  "punctualSingleDemandTooltip": "Produit ponctuel avec un unique point de demande dans le passé",
  "continuousLumpyTooltip": "Produit continu mais la demande est très volatile",
  "continuousSmoothTooltip": "Produit continu mais la demande n'est pas très volatile",
  "noDemandPredictability": "Non",
  "lowDemandPredictability": "Faible",
  "mediumDemandPredictability": "Moyenne",
  "highDemandPredictability": "Élevée",
  "forecastSource": "Source de prédiction",
  "externalForecast": "Prédiction externe",
  "flowlityForecast": "Prédiction flowlity",
  "myForecast": "Ma Prédiction",
  "createForecastEvent": "Créer un nouvel événement de prévision",
  "editForecastEvent": "Modifier l'événement de prévision",
  "eventsCount": "{count} événement | {count} événements",
  "assignForecastEvent": "Ajouter à un événement de prévision existant",
  "quantityShort": "Qté",
  "description": "Description",
  "startDate": "Date de début",
  "endDate": "Date de fin",
  "impact": "Impact",
  "impactRange": "Annuel",
  "create": "Créer",
  "event": "Événement | Événements",
  "addEventsTo": "Ajouter des événements au {suffix} | Ajouter des événements aux {suffix}",
  "editEvent": "Modifier l'événement",
  "deleteEvent": "Supprimer l'événement",
  "future": "Futur",
  "past": "Passé",
  "andMore": "et {count} de plus...",
  "forecastEvent": "Événement de prévision | Événements de prévision",
  "noDescription": "Aucune description fournie",
  "forecastEventSuccess": "Un nouvel événement de prévision a été créé",
  "forecastEventsFetchError": "Une erreur s'est produite lors de la récupération des événements de prévision",
  "forecastEventUpdateSuccess": "Cet événement de prévision a été mis à jour",
  "forecastEventAssignSuccess": "Les produits ont été assignés aux événements sélectionnés",
  "forecastEventsDeleteTitle": "Supprimer l'événement?",
  "hasInventoryIncomingChanges": "Stratégie d'inventaire modifiée",
  "deleteWarning": "Vous ne pourrez pas annuler cette action",
  "datePlaceholder": "Choisissez une date",
  "keepEditing": "Continuer à modifier",
  "cancelModifications": "Annuler les modifications",
  "creationDiscardText": "Vous perdrez toutes les modifications effectuées lors de la création",
  "editionDiscardText": "Vous perdrez toutes les modifications effectuées lors de l'édition",
  "edit": "Modifier",
  "companyMissMatch": "Seuls les membres de cette entreprise peuvent effectuer cette action",
  "applyForecastEventFor": "Appliquer l'événement pour",
  "allCustomers": "Tous les clients",
  "specificCustomers": "Clients spécifiques",
  "specifCustomersInfos": "Veuillez noter que l'événement ne sera ajouté qu'aux produits liés aux clients spécifiés.",
  "futureEvents": "Aucun événement futur | 1 événement futur | {count} événements futurs",
  "pastEvents": "Aucun événement passé | 1 événement passé | {count} événements passés",
  "cantExceed": "{fieldName} ne peut pas dépasser {count} caractères",
  "minLoadCannotBeGreaterMaxLimit": "La charge minimale ne peut être supérieure à la limite de capacité. Veuillez corriger les valeurs surlignées en rouge avant de sauvegarder.",
  "forecastEventExpl": "Evénements personnalisés définis par l'utilisateur",
  "createNewEventTooltip": "Créer un nouvel événement",
  "selectedCustomersCount": "0 client sélectionné | 1 client sélectionné | {count} clients sélectionnés",
  "appVersion": "Version de l'application",
  "daily": "Quotidien",
  "weekly": "Hebdomadaire",
  "monthly": "Mensuel",
  "quarterly": "Trimestriel",
  "yearly": "Annuel",
  "granularityOfRecommendations": "Granularité des recommandations",
  "granularityOfRecommendationsExplanation": "La granularité de planning est la fréquence à laquelle Flowlity peut générer une commande.",
  "deliveryDateExplanation": "La date de livraison d'une commande générée par Flowlity est le premier jour livrable dans une période définie par la granularité de planning.",
  "deliverableDaysExplanation": "Les jours de livraison sont définis par le jour de la semaine, le délai de livraison, l'horizon des recommandations fixes, la fermeture du fournisseur et la maintenance du site.",
  "flowlityMax": "Flowlity Max",
  "flowlityMin": "Flowlity Min",
  "supply": "Approvisionnement",
  "flowlitySupply": "Approvisionnement Flowlity",
  "tableSettings": "Paramètres de la table",
  "myStockMin": "Mon stock min",
  "shownInTable": "Affiché dans le tableau",
  "hiddenInTable": "Caché dans le tableau",
  "errorUpdatingDemandPlanningPreferences": "Une erreur est survenue lors de la mise à jour des préférences de demande/planification",
  "includeProductsThatMatchThisFilter": "Inclure les produits qui correspondent à ce filtre",
  "excludeProductsThatMatchThisFilter": "Exclure les produits qui correspondent à ce filtre",
  "nAttributes": "0 attribut | 1 attribut | {count} attributs",
  "justInTime": "Pas d'approvisionnement",
  "comments": "Commentaires | Commentaire | Commentaires",
  "comment": "Commenter",
  "inputPlaceholder": "Votre commentaire...",
  "noCommentsYet": "Personne n'a encore laissé de commentaires",
  "validate": "Valider",
  "ordersView": "Commandes | Commande | Commandes",
  "orderLinesView": "Lignes de commande | Ligne de commande | Lignes de commande",
  "collaborationState": "Statut de collaboration",
  "requestValidation": "Demande",
  "proposedChanges": "Modifications proposées",
  "notRequested": "Pas de demande",
  "rejectedBySupplier": "Rejeté par le fournisseur",
  "sendRequest": "Envoyer la demande",
  "discardRequest": "Annuler la demande",
  "updateRequest": "Mettre à jour la demande",
  "accept": "Accepter",
  "discardOrder": "Annuler la commande",
  "proposeChanges": "Proposer des modifications",
  "customerKpi": "KPI Client",
  "supplierKpi": "KPI Fournisseur",
  "currentSiteKpi": "KPI Site",
  "recommendedQuantity": "Quantité recommandée",
  "recommendedQuantityTooltip": "Quantité recommandée par Flowlity respectant vos contraintes",
  "accepted": "Accepté",
  "changedByCustomer": "Modifié par le client",
  "changedBySupplier": "Modifié par le fournisseur",
  "sendingRequestsTitle": "Envoi de demande pour {counter} sur {total} commandes",
  "sendingRequestsDescription": "Seules les commandes avec un statut de collaboration",
  "sendingRequestsDescriptionPart2": "peuvent être demandées. Les commandes avec d'autres statuts ne seront pas traitées.",
  "discardRequestsSuccess": "Les demandes ont été annulées avec succès",
  "discardRequestsErrors": "Une erreur est survenue lors de l'annulation des demandes",
  "undoRejectedRequestsSuccess": "Les demandes rejetées ont été restaurées avec succès",
  "undoRejectedRequestsErrors": "Une erreur est survenue lors de la restauration des demandes rejetées",
  "updateRequestsSuccess": "Les demandes ont été mises à jour avec succès",
  "updateRequestsErrors": "Une erreur est survenue lors de la mise à jour des demandes",
  "acceptRequestsSuccess": "Les demandes ont été acceptées avec succès",
  "acceptRequestsErrors": "Une erreur est survenue lors de l'acceptation des demandes",
  "sendRequestsSuccess": "Les demandes ont été envoyées avec succès",
  "sendRequestsErrors": "Une erreur est survenue lors de l'envoi des demandes",
  "undoRejection": "Annuler le rejet",
  "reject": "Rejeter",
  "yourComment": "Votre commentaire",
  "changes": "Modifications",
  "deleted": "Supprimé",
  "acceptRequestsConfirmDescription": "Êtes-vous sûr de vouloir accepter cette commande ? | Êtes-vous sûr de vouloir accepter ces commandes ?",
  "acceptRequestsConfirmTitle": "Accepter la commande ? | Accepter {count} commandes ?",
  "acceptRequestsPartiallyTitle": "Accepter {affected} sur {selected} commandes",
  "acceptRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec un statut de collaboration",
  "acceptRequestsPartiallySubDescriptionCollaborationStatus": "Demandé, Modifié par le Client",
  "acceptRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "discardSupplierRequestsConfirmTitle": "Annuler les modifications proposées ? | Annuler les modifications proposées pour {count} commandes",
  "discardSupplierRequestsPartiallyTitle": "Annuler la modification proposée pour {affected} sur {selected} commandes",
  "discardSupplierRequestsConfirmDescription": "Si vous annulez, vous perdrez toutes les modifications que vous avez proposées au Client | Si vous annulez, vous perdrez toutes les modifications que vous avez proposées au Client",
  "discardSupplierRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec un statut de collaboration",
  "discardSupplierRequestsPartiallySubDescriptionCollaborationStatus": "Modifications proposées",
  "discardSupplierRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "rejectRequestsConfirmTitle": "Rejeter la commande ? | Rejeter {count} commandes ?",
  "rejectRequestsPartiallyTitle": "Rejeter {affected} sur {selected} commandes",
  "rejectRequestsConfirmDescription": "Êtes-vous sûr de vouloir rejeter cette commande ? | Êtes-vous sûr de vouloir rejeter ces commandes ?",
  "rejectRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec tous les statuts de collaboration sauf",
  "rejectRequestsPartiallySubDescriptionCollaborationStatus": "Non demandé",
  "rejectRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "undoRejectionRequestsConfirmTitle": "Annuler le rejet de la commande | Annuler le rejet pour {count} commandes",
  "undoRejectionRequestsPartiallyTitle": "Annuler le rejet pour {affected} sur {selected} commandes",
  "undoRejectionRequestsConfirmDescription": "Êtes-vous sûr de vouloir annuler le rejet de la commande ? | Êtes-vous sûr de vouloir annuler le rejet des commandes ?",
  "undoRejectionRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec un statut de collaboration",
  "undoRejectionRequestsPartiallySubDescriptionCollaborationStatus": "Rejeté",
  "undoRejectionRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "sendRequestsConfirmConfirmBtnText": "Envoyer la demande",
  "sendRequestsConfirmDescription": "Lorsque vous envoyez, le fournisseur pourra accepter, modifier ou rejeter cette commande. | Lorsque vous envoyez, le fournisseur pourra accepter, modifier ou rejeter ces commandes.",
  "sendRequestsConfirmTitle": "Envoyer la demande ? | Envoyer les demandes pour {count} commandes ?",
  "sendRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec un statut de collaboration",
  "sendRequestsPartiallySubDescriptionCollaborationStatus": "Non demandé",
  "sendRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "sendRequestsPartiallyTitle": "Envoyer les demandes pour {affected} sur {selected} commandes ?",
  "discardRequestsConfirmDescription": "Êtes-vous sûr de vouloir annuler la demande sélectionnée ? | Êtes-vous sûr de vouloir annuler les {count} demandes sélectionnées ?",
  "discardRequestsConfirmTitle": "Annuler la demande ? | Annuler les demandes pour {count} commandes ?",
  "discardRequestsPartiallyTitle": "Annuler les demandes pour {affected} sur {selected} commandes ?",
  "discardRequestsPartiallySubDescriptionPrefix": "Cette action ne peut être appliquée qu'aux commandes avec un statut de collaboration",
  "discardRequestsPartiallySubDescriptionCollaborationStatus": "Demandé, Modifications proposées, Modifié par le fournisseur et Rejeté",
  "discardRequestsPartiallySubDescriptionSuffix": "Les commandes avec d'autres statuts ne seront pas traitées",
  "deleteOrdersConfirmDescription": "Êtes-vous sûr de vouloir supprimer cette commande ? Cela supprimera tous les approvisionnements planifiés dans cette commande. | Êtes-vous sûr de vouloir supprimer ces commandes ? Cela supprimera tous les approvisionnements planifiés dans ces commandes.",
  "deleteOrdersConfirmTitle": "Supprimer la commande ? | Supprimer {count} commandes ?",
  "deleteOrdersConfirmBtnText": "Supprimer la commande",
  "deleteOrdersSuccess": "Les commandes ont été supprimées avec succès",
  "deleteOrdersErrors": "Une erreur est survenue lors de la suppression des commandes",
  "errorWhileFetchingCustomersLinkedSites": "Une erreur est survenue lors de la récupération des sites clients liés",
  "addOrderLine": "Ajouter une ligne de commande",
  "rejected": "Rejeté",
  "exportSFTP": "Exporter vers SFTP"
}
