import { SortQuery } from "@/services/api/types/shared.api.types";

export enum ColumnAlignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ColumnSortingDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export declare type ColumnSortingOrderV2 = ColumnSortingDirection | {
  direction?: ColumnSortingDirection;
  nulls?: "first" | "last" | "FIRST" | "LAST";
};

export type IValueFormatter<TEntity = any, TKey extends string = string> = (obj: TEntity, column: IColumn<TEntity, TKey>) => any;

export interface IColumn<TEntity = any, TKey extends string = string> {
  key: TKey;
  minWidth?: number | string;
  maxWidth?: number | string;
  sticky?: boolean;
  alignment?: ColumnAlignment;
  title?: string;
  subtitle?: string;
  sortable?: boolean;
  tooltip?: string;
  sortingQuery?: (direction: ColumnSortingDirection) => SortQuery<TEntity>;
  // maximum lines limit for each cell (long text ellipsis...)
  // works only with default content but accessible in slot vars
  maxLinesLimit?: number;
  formatter?: IValueFormatter<TEntity, TKey>;
  // if true, will display the cell content only if the row is hovered
  displayOnlyWhenRowHovered?: boolean;
  // configurable additional left/right padding
  // used to compensate hoverable btn in headers cell
  headerCellPaddingLeft?: number;
  headerCellPaddingRight?: number;
  bodyCellPaddingLeft?: number; // 6px by default
  bodyCellPaddingRight?: number; // 26px by default
}

/* @deprecated can be used only for flat sorting (locally) */
export interface ISortingState<IType> {
  by: IType; // demand
  order: ColumnSortingDirection;
}

export interface IRowConfig {
  isDisabled?: boolean;
  tooltipText?: string;
}

// used in selectAll: if true, we inverse the checkbox logic
interface ITableConfigExclusionModeEnabled {
  exclusionModeEnabled: true;
  // we should define total number (on all pages), otherwise logic "has selection" cannot be defined
  totalItems: number;
}

// if exclusion mode is disabled, not necessary to provide totalItems
interface ITableConfigExclusionModeDisabled {
  exclusionModeEnabled?: false;
}

// either both provided, either nothing provided
type ITableExclusionModeConditionalConfig = ITableConfigExclusionModeEnabled | ITableConfigExclusionModeDisabled;

export type ITableConfig<TEntity = any> = {
  columns: IColumn<TEntity>[];
  headerHeight: number;
  showHeader?: boolean;
  showFooter?: boolean;
  stickyHeader?: boolean;
  gap?: number;
  paddingLeft?: number;
  paddingRight?: number;
  // items can be selected? → then set true
  interactive?: boolean;
  // in case of focusable = true
  // user can use keyboard arrows ↕︎ to navigate and to press "Enter" to emit the selection
  focusable?: boolean;
  // unique ID; used to check if objects are equal
  // "id" by default
  uniqueKey?: keyof TEntity;
  // if "isLoading" moves false → true
  // table is being scrolled to the top
  scrollToTopOnLoading?: boolean;
  // specific behaviour for each row
  rowsConfigs?: IRowConfig[];
} & ITableExclusionModeConditionalConfig;

// to call methods externally
export interface ITableHooks {
  scrollToTop(): void;
  resetFocus(): void;
}

export interface IColumnSortQuery<TEntity = any, TKey extends string = string> {
  column: IColumn<TEntity, TKey>;
  query: SortQuery<TEntity>;
}
