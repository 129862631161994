{
  "mustBeFilled": "Must be filled",
  "mustBeFilledNamed": "{fieldName} must be filled",
  "mustBeANumber": "Must be a number",
  "mustBeANumberNamed": "{fieldName} must be a number",
  "mustBePositive": "Must be positive",
  "mustBePositiveNamed": "{fieldName} must be positive",
  "cantBeNegative": "Cannot be negative",
  "cantBeNegativeNamed": "{fieldName} cannot be negative",
  "mustBeInteger": "Must be an integer",
  "mustBeIntegerNamed": "{fieldName} must be an integer",
  "mustBeBetween": "Must be between {min} and {max}",
  "mustBeBetweenNamed": "{fieldName} must be between {min} and {max}",
  "mustBeGreaterThan": "Must be greater than {min}",
  "mustBeGreateThanNamed": "{fieldName} must be greater than {min}",
  "mustBeLessThan": "Must be less than {max}",
  "mustBeLessThanNamed": "{fieldName} must be less than {max}",
  "mustBeLaterThan": "Must be later than {date}",
  "mustBeLaterThanNamed": "{fieldName} must be later than {date}",
  "mustBeEarlierThan": "Must be earlier than {date}",
  "mustBeEarlierThanNamed": "{fieldName} must be earlier than {date}",
  "maxLength": "Must be less than {max} characters",
  "maxLengthNamed": "{fieldName} must be less than {max} characters"
}