import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { ISite } from "@/typings/models/v2/site";
import { SiteAPI } from "@/services/api/site.v2.api";
import { NotificationService } from "@/services/notifications";
import { useSiteTagCategoriesStore } from "@/store/tag-categories.store";
import { usePreferencesStore } from "@/store/preferences.store";
import { UsersAPIV2 } from "@/services/api/users.v2.api";
import { IUserViews } from "@/views/forecast/typings/views";
import { useUserStore } from "@/store/user.store";
import { CURRENCY_MAP, DEFAULT_CURRENCY_CODE, DEFAULT_CURRENCY_SYMBOL } from "@/utils/currency";
import { useCustomersDataForSalesOrdersStore } from "@/store/customers-data-for-sales-orders.store";

export const useSiteStore = defineStore("site", () => {
  const preferencesStore = usePreferencesStore();
  const userStore = useUserStore();
  const siteStore = useSiteStore();
  const siteTagCategoriesStore = useSiteTagCategoriesStore();
  const customersDataForSalesOrderStore = useCustomersDataForSalesOrdersStore();
  const site = ref<ISite | null>(null);

  const userViewsForCurrentSite = ref<IUserViews>(
    {
      planning: { product: [] },
      demand: { product: [] },
    } as IUserViews);

  const isFetching = ref<boolean>(false);

  /*
  * used in supply orders to check if they can have access to collaboration features
  */
  const supplyOrdersHasCollaboration = computed<boolean>(() => !!site.value?.partners.some(partner => partner.isCustomer && partner.hasCollaboration));

  const currencyCode = computed<string>(
    () => site.value?.currency
      ? site.value.currency.toUpperCase()
      : DEFAULT_CURRENCY_CODE,
  );

  const currencySymbol = computed<string>(() => {
    if (!(currencyCode.value in CURRENCY_MAP)) {
      return DEFAULT_CURRENCY_SYMBOL;
    }

    return CURRENCY_MAP[currencyCode.value as keyof typeof CURRENCY_MAP];
  });

  const isDemoSite = computed(() => site.value && [501, 502].includes(site.value.id));

  const fetchViews = async () => {
    try {
      userViewsForCurrentSite.value = await UsersAPIV2.getViewsByUserIdAndSiteId(userStore.currentUser.id, siteStore.site!.id);
    } catch (e: unknown) {
      NotificationService.error("Error occurred while fetching views");
      throw e;
    }
  };

  const fetchSite = async (siteId: number) => {
    isFetching.value = true;

    try {
      site.value = await SiteAPI.getById(siteId, {
        relations: [
          "configSiteGlobal",
          "configSiteMl",
          "configSiteOr",
          "partners",
        ],
      });
    } catch (e: any) {
      NotificationService.error("Error occurred while fetching current site");
      throw e;
    } finally {
      isFetching.value = false;
    }

    // site-related tag categories can be accessible from Tactical and F&R (tags filter, columns)
    // it should be always ready-to-use when we initialize the app or change a site
    preferencesStore.preferences.lastUsedSiteId = siteId;
    preferencesStore.save();

    const fetchPromises: Promise<any>[] = [];

    if (!site.value!.isSupplierView) {
      fetchPromises.push(fetchViews(), siteTagCategoriesStore.fetchCategories());
    }

    if (site.value!.isSupplierView || site.value!.hasSalesOrders) {
      fetchPromises.push(customersDataForSalesOrderStore.fetchCustomersDataForSalesOrders());
    }

    await Promise.all(fetchPromises);

    window.gtag("config", import.meta.env.VITE_GA_ID, {
      custom_map: { dimension2: "is_supplier" },
    });

    window.gtag("event", "custom_dimension_is_supplier", {
      is_supplier: site.value!.isSupplierView,
    });
  };

  return {
    site,
    userViewsForCurrentSite,
    isFetching,
    currencyCode,
    currencySymbol,
    isDemoSite,
    supplyOrdersHasCollaboration,
    fetchSite,
    fetchViews,
  };
});
