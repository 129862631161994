{
  "mustBeFilled": "Должно быть заполнено",
  "mustBeFilledNamed": "{fieldName} должно быть заполнено",
  "mustBeANumber": "Должно быть числом",
  "mustBeANumberNamed": "{fieldName} должно быть числом",
  "mustBePositive": "Должно быть положительным",
  "mustBePositiveNamed": "{fieldName} должно быть положительным",
  "cantBeNegative": "Не может быть отрицательным",
  "cantBeNegativeNamed": "{fieldName} не может быть отрицательным",
  "mustBeInteger": "Должно быть целым числом",
  "mustBeIntegerNamed": "{fieldName} должно быть целым числом",
  "mustBeBetween": "Должно быть между {min} и {max}",
  "mustBeBetweenNamed": "{fieldName} должно быть между {min} и {max}",
  "mustBeGreaterThan": "Должно быть больше, чем {min}",
  "mustBeGreateThanNamed": "{fieldName} должно быть больше, чем {min}",
  "mustBeLessThan": "Должно быть меньше, чем {max}",
  "mustBeLessThanNamed": "{fieldName} должно быть меньше, чем {max}",
  "mustBeLaterThan": "Должно быть позже, чем {date}",
  "mustBeLaterThanNamed": "{fieldName} должно быть позже, чем {date}",
  "mustBeEarlierThan": "Должно быть раньше, чем {date}",
  "mustBeEarlierThanNamed": "{fieldName} должно быть раньше, чем {date}",
  "maxLength": "Должно быть меньше, чем {max} символов",
  "maxLengthNamed": "{fieldName} должно быть меньше, чем {max} символов"
}
