import { defineStore } from "pinia";
import { ref } from "vue";
import { NotificationService } from "@/services/notifications";
import { ILastUsedViewIds } from "@/views/forecast/typings/views";

export const usePreferencesStore = defineStore("ls", () => {
  const KEY = "flowlity_app_preferences";

  const preferences = ref<{
    lastUsedCompanyId?: number;
    lastUsedSiteId?: number;
    lastUsedViewIds?: ILastUsedViewIds;
    addViewDescriptionIsClosed?: boolean;
    orderModalAsideIsVisible?: boolean;
    navigationSidebarIsPinned?: boolean;
  }>({});

  const load = () => {
    const raw = window.localStorage.getItem(KEY);
    if (!raw) return;

    try {
      const parsed = JSON.parse(raw);

      if (typeof parsed === "object") {
        preferences.value = parsed;
      }
    } catch {
      NotificationService.error("Preferences: cannot parse saved preferences");
    }
  };

  const save = () => {
    const serialized = JSON.stringify(preferences.value);
    window.localStorage.setItem(KEY, serialized);
  };

  return {
    preferences,
    load,
    save,
  };
});
