{
  "label": {
    "useDefaultBufferPolicy": "usar política predeterminada",
    "supplierDelay": "Cobertura de retraso",
    "supplierMode": "Valor",
    "bufferLevel": "Nivel de buffer",
    "ropLevel": "Nivel de Rop",
    "quantity": "Cantidad",
    "coverage": "Cobertura",
    "type": "Tipo",
    "none": "Ninguno",
    "time": "Tiempo",
    "stock": "Stock",
    "maxValue": "Mi valor máximo",
    "minValue": "Mi valor mínimo"
  },
  "description": {
    "bufferLevel1": "El nivel de buffer es aplicable para productos con ",
    "bufferLevel2": "estrategia y ",
    "bufferLevel3": " fuente. Los productos que utilizan otras estrategias serán omitidos.",
    "stockMin1": "El valor mínimo es aplicable para productos con ",
    "stockMin2": "estrategia. Los productos que utilizan otras estrategias serán omitidos.",
    "bufferPolicyStockTip1": "Tenga en cuenta que ",
    "bufferPolicyStockTip2": "el tipo de política es aplicable para productos con",
    "bufferPolicyStockTip3": "estrategia solamente. Los productos que utilizan otras estrategias serán omitidos.",
    "ropLevelTip1": "El valor ROP es aplicable para productos con ",
    "ropLevelTip2": "estrategia.",
    "useDefaultTip": "Todos los productos tendrán la política de buffer predeterminada dependiendo de la estrategia que tengan. Puede gestionar las políticas de buffer predeterminadas"
  },
  "strategyType": {
    "mts": "MTS",
    "stock": "Stock",
    "demand": "Basado en demanda - Flowlity AI",
    "myMin": "Flowlity AI + mi mínimo",
    "ropAi": "ROP + AI"
  },
  "tooltip": {
    "integrated": "Los datos están integrados desde un sistema externo, por lo tanto, la modificación no está permitida",
    "bufferLevel": "El Nivel de Buffer de Flowlity impactará su nivel de stock de buffer. Cuanto más bajo sea el nivel de buffer, más bajo será su nivel mínimo. Cuanto más alto sea el nivel de buffer, más alto será su nivel mínimo.",
    "stockMin": "Lo usaremos como valor fijo para todo el horizonte"
  },
  "mainTitle": "Editar estrategia de inventario",
  "category": {
    "strategyType": "Tipo de Estrategia",
    "bufferPolicy": "Política de Buffer",
    "bufferLevel": "Nivel de Buffer",
    "rop": "Valor de Punto de Reorden",
    "ropAi": "Rop + AI",
    "myStockMin": "Mi Stock Mínimo",
    "myStockMax": "Mi Stock Máximo",
    "changedMessage": "Cambios en la estrategia de inventario aplicados"
  },
  "edit": {
    "strategyType": "Editar tipo de estrategia",
    "bufferPolicy": "Editar política de buffer de suministro",
    "bufferLevel": "Editar nivel de buffer",
    "rop": "Editar valor de punto de reorden",
    "stockMin": "Editar mi stock mínimo",
    "stockMax": "Editar mi stock máximo"
  },
  "mixed": "mezclado",
  "here": "aquí",
  "or": "o"
}
