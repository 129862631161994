import { FEATURE_FLAG } from "@/typings/models/v2/features";
import { NotificationService } from "@/services/notifications";
import { storeToRefs } from "pinia";
import { UserLocaleEnum } from "@/typings/models/v2/user";
import { useSiteStore } from "@/store/site.store";
import { NavigationGuardNext, NavigationGuardWithThis } from "vue-router";
import { useUserStore } from "@/store/user.store";
import { ROUTE } from "@/router/enum";

export const controlRbacAccess = (
  permissionList: string[] = [],
  then: NavigationGuardWithThis<any> = (to, from, next) => {
    return next();
  },
): NavigationGuardWithThis<any> => (to, from, next) => {
  const { currentUser } = storeToRefs(useUserStore());
  if (currentUser.value.canAccess(permissionList)) return then(to, from, next);

  const unauthorizedMsg
    = currentUser.value.locale === UserLocaleEnum.EN ? "Not authorized" : "Non autorisé";

  NotificationService.error(unauthorizedMsg);
  return next({ name: ROUTE.DASHBOARD });
};

export const adminNotAuthorized: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const { site } = storeToRefs(useSiteStore());
  const { currentUser } = storeToRefs(useUserStore());
  if (currentUser.value.isAdmin && !site.value!.isSupplierView) return next();

  const unauthorizedMsg
    = currentUser.value.locale === UserLocaleEnum.EN ? "Not authorized" : "Non autorisé";

  NotificationService.error(unauthorizedMsg);
  return next({ name: ROUTE.DASHBOARD });
};

export const supplierNotAuthorized: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const { site } = storeToRefs(useSiteStore());
  const { currentUser } = storeToRefs(useUserStore());
  if (!site.value!.isSupplierView) return next();

  const unauthorizedMsg
    = currentUser.value.locale === UserLocaleEnum.EN ? "Not authorized" : "Non autorisé";

  NotificationService.error(unauthorizedMsg);
  return next({ name: ROUTE.ORDERS_LIST });
};

export const customerNotAuthorized: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const { site } = storeToRefs(useSiteStore());
  const { currentUser } = storeToRefs(useUserStore());
  if (site.value!.isSupplierView) return next();

  const unauthorizedMsg
    = currentUser.value.locale === UserLocaleEnum.EN ? "Not authorized" : "Non autorisé";

  NotificationService.error(unauthorizedMsg);
  return next({ name: ROUTE.ORDERS_LIST });
};

export const pageGuard = (next: NavigationGuardNext, feature: FEATURE_FLAG) => {
  const { currentUser } = storeToRefs(useUserStore());

  if (!currentUser.value.features.includes(feature)) {
    const unauthorizedMsg
    = currentUser.value.locale === UserLocaleEnum.EN ? "Not authorized" : "Non autorisé";

    NotificationService.error(unauthorizedMsg);

    return next({ name: ROUTE.DASHBOARD });
  }

  return next();
};

export const analyticsPageGuard: NavigationGuardWithThis<undefined> = (to, from, next) => pageGuard(next, FEATURE_FLAG.ANALYTICS_PAGE);

export const capacityUnitsPageGuard: NavigationGuardWithThis<undefined> = (to, from, next) => pageGuard(next, FEATURE_FLAG.CAPACITY_UNITS_PAGE);
