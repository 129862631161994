{
    "alerts": {
        "capacity_below_min": "Problema de carga mínima",
        "capacity_above_limit": "Problema de capacidad máxima"
    },
    "capacityAboveLimit": "Problema de límite de capacidad",
    "capacityBelowMin": "Problema de carga mínima",
    "capacityLeft": "Capacidad restante",
    "totalLoad": "Carga total",
    "capacity": "Capacidad",
    "productData": "Datos del producto",
    "byTag": "Por etiqueta",
    "byProduct": "Por producto",
    "byName": "por nombre de capacidad",
    "aboveCapacityLimit": "Por encima del límite de capacidad",
    "belowMinLoad": "Por debajo de la carga mínima",
    "capacityLimit": "Límite de capacidad",
    "minLoad": "Carga mínima",
    "createNewCapacity": "Crear unidad de capacidad",
    "creationMessage": "La unidad de capacidad ha sido creada",
    "deleteDescription": "Si elimina esta unidad de capacidad, perderá toda la información conectada. No podrá deshacer esta acción.",
    "deleteMessage": "La unidad de capacidad ha sido eliminada",
    "deleteTitle": "¿Desea eliminar esta unidad de capacidad?",
    "deselectAll": "Deseleccionar todo",
    "errorMessage": "Ocurrió un error al guardar la unidad de capacidad",
    "updateMessage": "La unidad de capacidad ha sido actualizada",
    "of": "de",
    "timebucketDoesntFitGranularity": "La unidad de capacidad predeterminada tiene granularidad \"{granularity}\". Cambie el intervalo de tiempo a \"{granularity}\" para editar",
    "cannotOpenPlanningForMore100": "Temporal: solo se pueden abrir en planificación las unidades de capacidad que contengan hasta 100 productos",
    "list": {
        "table": {
            "header": {
                "actions": "Acciones",
                "alerts": "Alertas",
                "description": "Descripción",
                "isActive": "Estado",
                "name": "Nombre de la Unidad de Capacidad",
                "supplier": "Proveedor"
            },
            "active": "Activo",
            "inactive": "Inactivo",
            "editTooltip": "Editar unidad de capacidad",
            "deleteTooltip": "Eliminar unidad de capacidad"
        }
    },
    "formModal": {
        "andMore": "y {count} más ...",
        "create": "Crear unidad de capacidad",
        "defaultCapacityLimitTooltip": "Este es el valor por período de granularidad que se establecerá para todo el horizonte. Puede personalizar este valor para cierto período en una vista de tabla más adelante.",
        "defaultMinLoadTooltip": "Este es el valor predeterminado por período de granularidad que se establecerá para todo el horizonte. Puede personalizar este valor para un período determinado en una tabla más adelante.",
        "duplicateNameError": "Este nombre ya existe para el proveedor seleccionado, por favor ingrese otro nombre",
        "editTitle": "Editar unidad de capacidad",
        "granularity": "Granularidad de capacidad",
        "granularityDisabledTooltip": "La granularidad del sitio es 'Meses', no puede seleccionar un valor menor.",
        "granularityTooltip": "Define el período al que se aplicarán la \"Carga mínima\" y el \"Límite de capacidad\"",
        "isActiveDescription": "Si está activo, el sistema muestra alertas en la página de unidad de capacidad cuando los pedidos exceden el límite de capacidad o no alcanzan la carga mínima.",
        "maxLoad": "Límite de capacidad",
        "maxLoadLessThanMinLoadError": "Este valor no puede ser menor que la carga mínima predeterminada",
        "minLoad": "Carga mínima",
        "minMaxDescription": "Los valores predeterminados no afectan los valores del horizonte actual, pero actualizan los valores de nuevos períodos que aparecen progresivamente.",
        "minMaxLoadPlaceholder": "Introducir valor",
        "name": "Nombre de la unidad de capacidad",
        "namePlaceholder": "Introducir nombre",
        "products": "Productos",
        "productsDifferentUnits": "Este producto no respeta la unidad de medida predeterminada seleccionada",
        "requirePartnerTooltip": "Por favor, seleccione un proveedor primero",
        "requireUnitOfMeasureTooltip": "Por favor, seleccione una unidad de medida predeterminada primero",
        "supplier": "Proveedor",
        "supplierPlaceholder": "Seleccionar proveedor",
        "tagsDifferentUnits": "Al menos un producto dentro de esta etiqueta no respeta la unidad de medida predeterminada seleccionada",
        "title": "Crear unidad de capacidad",
        "unit": "Unidad de medida predeterminada",
        "unitPlaceholder": "Seleccionar unidad de medida",
        "update": "Guardar"
    },
    "modal": {
        "backToList": "Volver a la lista"
    }
}
