import { API_V2 } from ".";
import { ISite } from "@/typings/models/v2/site";
import { IGetSiteParams } from "@/services/api/types/site.api.v2.types";
import { ICustomerDataForSalesOrder } from "@/typings/models/v2/customers-data-for-sales-orders";

export const SiteAPI = {
  async getById(
    id: number,
    params?: IGetSiteParams,
  ) {
    const { data } = await API_V2.get<ISite>(
      `/api/v2/sites/${id}`,
      params,
    );

    return data;
  },
  async patchSite(siteId: number, partialSite: Partial<ISite>) {
    const { data } = await API_V2.patch<ISite>(
      `/api/v2/sites/${siteId}`,
      partialSite,
    );

    return data;
  },

  async getCustomersDataForSalesOrders(siteId: number, companyId: number) {
    const { data } = await API_V2.get<ICustomerDataForSalesOrder[]>(
      `/api/v2/sites/${siteId}/companies/${companyId}/customers-data-for-sales-orders`,
    );

    return data;
  },
};
