import en from "./en.json";
import fr from "./fr.json";
import ru from "./ru.json";
import es from "./es.json";

const globalTranslations = {
  en,
  fr,
  ru,
  es,
};

export default globalTranslations;
