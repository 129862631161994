{
    "alerts": {
        "capacity_below_min": "Проблема с минимальной мощностью",
        "capacity_above_limit": "Проблема с максимальной мощностью"
    },
    "capacityAboveLimit": "Проблема с лимитом мощности",
    "capacityBelowMin": "Проблема с минимальной мощностью",
    "capacityLeft": "Оставшаяся мощность",
    "totalLoad": "Общая мощность",
    "capacity": "Мощность",
    "productData": "Данные о продукте",
    "byTag": "По тегу",
    "byProduct": "По продукту",
    "byName": "по названию мощности",
    "aboveCapacityLimit": "Превышение лимита мощности",
    "belowMinLoad": "Ниже минимальной мощности",
    "capacityLimit": "Лимит мощности",
    "minLoad": "Минимальная мощность",
    "createNewCapacity": "Создать единицу мощности",
    "creationMessage": "Единица мощности была создана",
    "deleteDescription": "Если вы удалите эту единицу мощности, вы потеряете всю связанную информацию. Вы не сможете отменить это действие.",
    "deleteMessage": "Единица мощности была удалена",
    "deleteTitle": "Вы хотите удалить эту единицу мощности?",
    "deselectAll": "Снять все выделения",
    "errorMessage": "Произошла ошибка при сохранении единицы мощности",
    "updateMessage": "Единица мощности была обновлена",
    "of": "из",
    "timebucketDoesntFitGranularity": "Единица мощности по умолчанию имеет гранулярность \"{granularity}\". Измените временной интервал на \"{granularity}\" для редактирования",
    "cannotOpenPlanningForMore100": "Временно: можно открывать в планировании только мощности, содержащие до 100 продуктов",
    "list": {
        "table": {
            "header": {
                "actions": "Действия",
                "alerts": "Предупреждения",
                "description": "Описание",
                "isActive": "Статус",
                "name": "Название единицы мощности",
                "supplier": "Поставщик"
            },
            "active": "Активен",
            "inactive": "Неактивен",
            "editTooltip": "Редактировать единицу мощности",
            "deleteTooltip": "Удалить единицу мощности"
        }
    },
    "formModal": {
        "andMore": "и еще {count} ...",
        "create": "Создать единицу мощности",
        "defaultCapacityLimitTooltip": "Это значение за период гранулярности, которое будет установлено для всего горизонта. Вы можете настроить это значение для определенного периода позже в табличном представлении.",
        "defaultMinLoadTooltip": "Это значение по умолчанию за период гранулярности, которое будет установлено для всего горизонта. Вы можете настроить это значение для определенного периода позже в таблице.",
        "duplicateNameError": "Это имя уже существует для выбранного поставщика, пожалуйста, введите другое имя",
        "editTitle": "Редактировать единицу мощности",
        "granularity": "Гранулярность мощности",
        "granularityDisabledTooltip": "Гранулярность склада - 'Месяцы', вы не можете выбрать более низкое значение.",
        "granularityTooltip": "Определяет период, к которому будут применяться \"Минимальная мощность\" и \"Лимит мощности\"",
        "isActiveDescription": "Если активно, система показывает вам предупреждения на странице единицы мощности, когда заказы превышают лимит мощности или не достигают минимальной мощности.",
        "maxLoad": "Лимит мощности",
        "maxLoadLessThanMinLoadError": "Это значение не может быть меньше минимальной мощности по умолчанию",
        "minLoad": "Минимальная мощность",
        "minMaxDescription": "Значения по умолчанию не влияют на значения текущего горизонта, но обновляют значения новых периодов, которые появляются постепенно.",
        "minMaxLoadPlaceholder": "Введите значение",
        "name": "Название единицы мощности",
        "namePlaceholder": "Введите название",
        "products": "Продукты",
        "productsDifferentUnits": "Этот продукт не соответствует выбранной единице измерения по умолчанию",
        "requirePartnerTooltip": "Пожалуйста, сначала выберите поставщика",
        "requireUnitOfMeasureTooltip": "Пожалуйста, сначала выберите единицу измерения по умолчанию",
        "supplier": "Поставщик",
        "supplierPlaceholder": "Выберите поставщика",
        "tagsDifferentUnits": "По крайней мере один продукт в этом теге не соответствует выбранной единице измерения по умолчанию",
        "title": "Создать единицу мощности",
        "unit": "Единица измерения по умолчанию",
        "unitPlaceholder": "Выберите единицу измерения",
        "update": "Сохранить"
    },
    "modal": {
        "backToList": "Вернуться к списку"
    }
}
