import { API_V2 } from "@/services/api";
import { AxiosRequestConfig } from "axios";
import { ICompany } from "@/typings/models/v2/company";
import {
  ICompanyDataExportToSpreadsheetPayload,
  IGetCompaniesParams,
  IGetCompanyParams,
  IGetCompanySitesPayload,
} from "@/services/api/types/companies.api.v2.types";
import { IGetCompanyTagsParams } from "@/services/api/types/tags.api.v2.types";
import { IPaginatedResponse, IWithOptionalPattern, IWithOptionalSelect, IWithPagination } from "@/typings/models/v2/api";
import { ISite } from "@/typings/models/v2/site";
import { IProductSupplierLink } from "@/typings/models/v2/supplier";
import { ITag } from "@/typings/models/v2/tag";
import { useCompanyStore } from "@/store/company.store";
import { IApiUpdateResult } from "@/services/api/types/shared.api.types";
import { downloadFile } from "@/utils/file-downloader";

export const CompaniesAPIV2 = {
  async getCompanies(
    params?: IGetCompaniesParams,
  ) {
    const { data } = await API_V2.get<ICompany[]>("/api/v2/companies", params);
    return data;
  },
  async getCompany(
    id: number,
    params?: IGetCompanyParams,
  ) {
    const { data } = await API_V2.get<ICompany>(
      `/api/v2/companies/${id}`,
      params,
    );

    return data;
  },
  async patchCompany(id: number, payload: Partial<ICompany>): Promise<IApiUpdateResult> {
    const { data } = await API_V2.patch<IApiUpdateResult>(
      `/api/v2/companies/${id}`,
      payload,
    );

    return data;
  },

  async getCompanySuppliers(
    companyId: number,
    params: IWithPagination & IWithOptionalPattern & IWithOptionalSelect<IProductSupplierLink> & { siteId?: number },
  ): Promise<IPaginatedResponse<IProductSupplierLink>> {
    const { data } = await API_V2.get<IPaginatedResponse<IProductSupplierLink>>(
      `/api/v2/companies/${companyId}/suppliers`,
      params,
    );

    return data;
  },

  async getCompanySites(payload?: IGetCompanySitesPayload) {
    const companyStore = useCompanyStore();

    const { data } = await API_V2.post<ISite[]>(
      `/api/v2/companies/${companyStore.company!.id}/sites/get`,
      payload,
    );

    return data;
  },

  async getCompanyTags(
    companyId: number,
    params: IGetCompanyTagsParams,
    config?: AxiosRequestConfig,
  ) {
    const { data } = await API_V2.post<IPaginatedResponse<ITag>>(
      `/api/v2/companies/${companyId}/tags`,
      params,
      undefined,
      config,
    );

    return data;
  },

  async downloadCompanyDataSpreadsheetExportFile(payload: ICompanyDataExportToSpreadsheetPayload, fileName: string): Promise<void> {
    const companyStore = useCompanyStore();

    const { data } = await API_V2.post(
      `/api/v2/companies/${companyStore.company!.id}/export`,
      payload,
      undefined,
      {
        responseType: "blob",
      },
    );

    downloadFile(data, fileName);
  },
};
