{
  "mustBeFilled": "Debe completarse",
  "mustBeFilledNamed": "{fieldName} debe completarse",
  "mustBeANumber": "Debe ser un número",
  "mustBeANumberNamed": "{fieldName} debe ser un número",
  "mustBePositive": "Debe ser positivo",
  "mustBePositiveNamed": "{fieldName} debe ser positivo",
  "cantBeNegative": "No puede ser negativo",
  "cantBeNegativeNamed": "{fieldName} no puede ser negativo",
  "mustBeInteger": "Debe ser un número entero",
  "mustBeIntegerNamed": "{fieldName} debe ser un número entero",
  "mustBeBetween": "Debe estar entre {min} y {max}",
  "mustBeBetweenNamed": "{fieldName} debe estar entre {min} y {max}",
  "mustBeGreaterThan": "Debe ser mayor que {min}",
  "mustBeGreateThanNamed": "{fieldName} debe ser mayor que {min}",
  "mustBeLessThan": "Debe ser menor que {max}",
  "mustBeLessThanNamed": "{fieldName} debe ser menor que {max}",
  "mustBeLaterThan": "Debe ser posterior a {date}",
  "mustBeLaterThanNamed": "{fieldName} debe ser posterior a {date}",
  "mustBeEarlierThan": "Debe ser anterior a {date}",
  "mustBeEarlierThanNamed": "{fieldName} debe ser anterior a {date}",
  "maxLength": "Debe tener menos de {max} caracteres",
  "maxLengthNamed": "{fieldName} debe tener menos de {max} caracteres"
}
