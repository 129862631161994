import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAuthStore } from "@/store/auth.store";
import { apiV1ParamsSerializer, apiV2ParamsSerializer } from "@/utils/axios";
import { storeToRefs } from "pinia";

interface IAPIServiceOpts {
  serializer(params: any): string;
}

class APIService {
  private readonly _axios: AxiosInstance;

  private readonly baseURL: string;

  constructor(baseURL: string, opts: IAPIServiceOpts) {
    this.baseURL = baseURL;

    this._axios = axios.create({
      baseURL: baseURL,
      headers: {
        // @ts-ignore
        common: {},
      },
      paramsSerializer: opts.serializer,
    });
  }

  get axios(): AxiosInstance {
    return this._axios as AxiosInstance;
  }

  setHeader() {
    if (!this.axios) return;
    const store = useAuthStore();
    const { accessToken } = storeToRefs(store);
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken.value}`;

    if (import.meta.env.MODE === "development") {
      this.axios.defaults.headers.common.email = import.meta.env.VITE_DEV_EMAIL;

      this.axios.defaults.headers.common.permissions = import.meta.env.VITE_DEV_PERMISSIONS;
    }
  }

  get<T = any>(resource: string, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(resource, { params, ...config });
  }

  post<T = any>(resource: string, data?: any, params?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(resource, data, { params, ...config });
  }

  put<T = any>(resource: string, data?: any, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.put<T>(resource, data, { params });
  }

  patch<T = any>(resource: string, data?: any, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.patch<T>(resource, data, { params });
  }

  delete<T = any>(resource: string, params?: any): Promise<AxiosResponse<T>> {
    return this.axios.delete<T>(resource, { params });
  }
}

export const baseURL
  = import.meta.env.MODE === "development"
    ? "http://127.0.0.1:5000"
    : `https://${import.meta.env.VITE_GATEWAY}/admin-manager`;

export const baseURLV2
  = import.meta.env.MODE === "development"
    ? "http://localhost:3000"
    : `https://${import.meta.env.VITE_GATEWAY_V2}`;

// singletons
export const API = new APIService(baseURL, {
  serializer: apiV1ParamsSerializer,
});

export const API_V2 = new APIService(baseURLV2, {
  serializer: apiV2ParamsSerializer,
});
