import { defineStore } from "pinia";
import { i18n } from "@/i18n/i18n@next";
import { IUser, UserLocaleEnum } from "@/typings/models/v2/user";
import { JimoService } from "@/services/jimo";
import { NotificationService } from "@/services/notifications";
import { capitalize, ref } from "vue";
import { useAuthStore } from "@/store/auth.store";
import { UsersAPIV2 } from "@/services/api/users.v2.api";
import dayjs from "dayjs";
import { FlowlityDateUnit, PeriodBoundary, computeDaysFromToday } from "@/utils/date";
import {
  PLANNING_DEMAND_VIEW,
  DateRelativeSettingKey,
  TimebucketSettingKey,
} from "@/views/forecast/typings";

export const useUserStore = defineStore("user", () => {
  const authStore = useAuthStore();

  // we are initializing with `{} as IUser`
  // to avoid having to check everywhere if currentUser exists
  // anyway if the user is not well loaded, the app will logout and redirect to flowlity.com
  const currentUser = ref<IUser>({} as IUser);
  const { locale } = i18n.global;

  const updateUserLocaleInBack = async (newLocale: UserLocaleEnum) => {
    try {
      await UsersAPIV2.updateUser(currentUser.value.id, {
        locale: newLocale,
      });

      currentUser.value.locale = newLocale;

      if (newLocale === UserLocaleEnum.EN)
        NotificationService.success(
          "Your language choice was successfuly saved",
        );
      if (newLocale === UserLocaleEnum.FR)
        NotificationService.success(
          "Votre choix de langue a bien été enregistré",
        );
      if (newLocale === UserLocaleEnum.ES)
        NotificationService.success(
          "Su elección de idioma se ha guardado correctamente",
        );
      if (newLocale === UserLocaleEnum.RU)
        NotificationService.success(
          "Ваш выбор языка был успешно сохранен",
        );
    } catch (error) {
      NotificationService.error(
        "Something went wrong, we could not save your language choice",
      );

      throw error;
    }
  };

  const updateUserLocaleInApp = (newLocale: UserLocaleEnum) => {
    // update VueI18n locale
    locale.value = newLocale;
    // update dayjs locale globally
    dayjs.locale(newLocale);
  };

  const updateUserLocale = async (newLocale: UserLocaleEnum) => {
    updateUserLocaleInApp(newLocale);
    await updateUserLocaleInBack(newLocale);
  };

  const initMe = async () => {
    try {
      const user = await UsersAPIV2.getMe();

      user.canAccess = permissionList => permissionList.every(permission =>
        user.permissions.includes(permission),
      );

      user.hasTacticalWithEditionMode = user.canAccess(["tactical:edition"]);
      user.hasTacticalWithSimulationMode = user.canAccess(["tactical:simulation"]);

      currentUser.value = user;

      window.gtag("set", {
        user_id: user.id.toString(),
      });

      window.gtag("config", import.meta.env.VITE_GA_ID, {
        custom_map: { dimension1: "user_id" },
      });

      window.gtag("event", "custom_dimension_user_id", {
        user_id: user.id.toString(),
      });

      updateUserLocaleInApp(user.locale);

      // Initialize Jimo with user's email
      JimoService.initialize(user.email);
    } catch (error: any) {
      NotificationService.error("Error occurred while fetching user info", {
        description: error.message,
      });

      setTimeout(() => authStore.logout(), 5000);
    }
  };

  const updateUserStartEndDaysFromToday = (
    view: PLANNING_DEMAND_VIEW | "capacity",
    periodBoundary: PeriodBoundary,
    dateToDiffFromToday: dayjs.Dayjs,
  ) => {
    const settingKey = `${view}${capitalize(periodBoundary)}DateRelativeSetting` as DateRelativeSettingKey;

    const daysFromToday = computeDaysFromToday(dateToDiffFromToday, periodBoundary);

    currentUser.value[settingKey] = daysFromToday;

    UsersAPIV2.updateUser(currentUser.value.id, {
      [settingKey]: daysFromToday,
    });
  };

  const updateUserTimebucket = (
    view: PLANNING_DEMAND_VIEW | "capacity",
    timebucket: FlowlityDateUnit,
  ) => {
    const settingKey = `${view}TimeBucketSetting` as TimebucketSettingKey;

    currentUser.value[settingKey] = timebucket;

    UsersAPIV2.updateUser(currentUser.value.id, {
      [settingKey]: timebucket,
    });
  };

  return {
    currentUser,
    initMe,
    updateUserLocale,
    updateUserStartEndDaysFromToday,
    updateUserTimebucket,
  };
});
