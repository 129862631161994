{
  "netRequirement": "Requerimiento neto",
  "component_shortage": "Restricción de producto",
  "product_constraint": "Escasez de componentes",
  "supplier_constraint": "Restricción de proveedor",
  "allocation_needed": "Asignación necesaria",
  "below_net_requirement": "Por debajo del requerimiento neto",
  "above_net_requirement": "Por encima del requerimiento neto",
  "over_fulfilled": "Sobre cumplimiento",
  "under_fulfilled": "Subcumplimiento",
  "alertsDropdown-component_shortage": "Escasez de componentes",
  "alertsDropdown-product_constraint": "Problema con restricciones de producto",
  "alertsDropdown-supplier_constraint": "Problema con restricciones de proveedor",
  "alertsDropdown-allocation_needed": "Asignación necesaria",
  "alertsDropdown-below_net_requirement": "Por debajo del requerimiento neto",
  "alertsDropdown-above_net_requirement": "Por encima del requerimiento neto",
  "alertsDropdown-out_of_stock": "Sin stock",
  "alertsDropdown-over_fulfilled": "Sobre cumplimiento",
  "alertsDropdown-under_fulfilled": "Subcumplimiento",
  "similarProductTooltip": "Mostrar y agregar productos similares",
  "similarProductExplanation1": "El historial de demanda del producto de origen se copiará en el historial de demanda del producto de destino. Esto es particularmente eficiente para gestionar reemplazos de productos.",
  "similarProductExplanation2": "Puede aplicar un coeficiente al transferir el historial de demanda, por ejemplo, si dos productos son reemplazados por un solo producto.",
  "similarProductExplanation3": "Tenga en cuenta que el impacto en el historial de demanda es inmediato; pero la previsión solo se actualizará en el próximo cálculo de previsión.",
  "datepickerStartDateCantBeAfterEndDate": "La fecha de inicio no puede ser después de la fecha de fin",
  "datepickerEndDateCantBeBeforeStartDate": "La fecha de fin no puede ser antes de la fecha de inicio",
  "outlierAlreadyExistForthisPeriod": "Ya existe un evento atípico para este período",
  "eventXMarkedAsRead": "El evento “{name}” fue marcado como leído",
  "outlierCreationExplanation": "Este período será excluido por Flowlity al crear el pronóstico de demanda. Los cambios tendrán efecto después de la actualización del pronóstico del fin de semana, el próximo lunes.",
  "outlierAutoAcceptedTooltip": "Este evento atípico histórico detectado por Flowlity será automáticamente excluido al crear el pronóstico de demanda",
  "weekAbbreviation": "S",
  "outlierDetectedByFlowlityExplanation": "Este evento atípico histórico fue detectado por Flowlity y fue automáticamente excluido al crear el pronóstico de demanda",
  "editOutlier": "Editar evento atípico",
  "outlierEventCreationSuccess": "Evento atípico creado con éxito",
  "outlierEventCreationError": "Ocurrió un error al crear el evento atípico",
  "outlierEventEditingSuccess": "Evento atípico editado con éxito",
  "outlierEventEditingError": "Ocurrió un error al editar el evento atípico",
  "date": "Fecha",
  "addOutlierWrongTimebucket": "Cambie al intervalo de tiempo de cálculo \"%{timebucket}\" para agregar un evento atípico",
  "addOutlierFuturePeriod": "Solo puede agregar un evento atípico en el pasado",
  "addOutlierAlreadyExists": "Ya existe un evento atípico para este período, puede editarlo en lugar de crear uno nuevo",
  "addOutlier": "Agregar un evento atípico",
  "default": "Predeterminado",
  "forecastGranularity": "Granularidad del pronóstico",
  "tooltipDefaultComputationTimebucketDropdownDemand": "Esta granularidad se utiliza para el cálculo del pronóstico",
  "tooltipDefaultViewTimebucketDropdown": "Esta es la vista predeterminada",
  "tooltipDefaultViewTimebucketDropdownDemand": "Mi pronóstico solo se puede editar en esta granularidad",
  "format": "Formato",
  "destination": "Destino",
  "file": "Archivo",
  "download": "Descargar",
  "analytics": "Analítica",
  "tacticalNoun": "Táctico",
  "capacity": "Capacidad",
  "salesOrders": "Órdenes de ventas",
  "supplyOrders": "Órdenes de suministro",
  "settings": "Configuraciones",
  "dashboardNoun": "Tablero",
  "demandNoun": "Demanda",
  "planningNoun": "Planificación",
  "whatsNew": "¿Qué hay de nuevo?",
  "language": "Idioma",
  "profileSettings": "Configuración del perfil",
  "logout": "Cerrar sesión",
  "dimension": "Dimensión",
  "tableNoun": "Tabla",
  "creditsPlural": "Créditos | Crédito | Créditos",
  "pinTheSidebar": "Fijar la barra lateral",
  "unpinTheSidebar": "Desfijar la barra lateral",
  "selectionIsDisabledWhenSimulationIsValidated": "La selección está deshabilitada cuando la simulación está validada",
  "selectionIsOnlyAvailableForMtsFlowlityAiProducts": "La selección solo está disponible para productos MTS Flowlity AI",
  "selected": "{count} seleccionado | {count} seleccionado | {count} seleccionado",
  "itemsSelected": "Elementos seleccionados",
  "errorWhileFetchingTagCategories": "Ocurrió un error al obtener las categorías de etiquetas",
  "tagCategoryUpdateSuccess": "La categoría de etiqueta se ha actualizado con éxito",
  "tagCategoryUpdateError": "Ocurrió un error al actualizar la categoría de etiqueta",
  "tagCategoryDeleteSuccess": "La categoría de etiqueta \"{name}\" se ha eliminado con éxito",
  "tagCategoryDeleteError": "Ocurrió un error al eliminar la categoría de etiqueta",
  "deleteTagCategory": "¿Eliminar la categoría de etiqueta?",
  "deleteTagCategoryButton": "Eliminar la categoría",
  "tagCategoryCreateSuccess": "La categoría de etiqueta se ha creado con éxito",
  "tagCategoryCreateError": "Ocurrió un error al crear la categoría de etiqueta",
  "categoryName": "Nombre de la categoría",
  "optional": "Opcional",
  "NProductsSelected": "{countDisplay} producto seleccionado | {countDisplay} productos seleccionados",
  "downloadProductsCSVError": "Ocurrió un error al descargar el CSV de productos.",
  "cannotOpenDetailsWhenSelectingAll": "No puede abrir los detalles cuando hay más de {max} elementos seleccionados",
  "downloadCSV": "Descargar la lista en CSV",
  "dueDate": "Fecha de vencimiento",
  "fixPeriod": "Periodo de fijación de recomendaciones",
  "fixPeriodTitle": "¿Fijar periodo para productos seleccionados?",
  "discardPeriod": "Descartar periodo de recomendaciones",
  "discardPeriodTitle": "¿Descartar fijación de periodo para productos seleccionados?",
  "discardInventoryStrategyChanges": "Descartar cambios en la estrategia de inventario",
  "discardInventoryChangesWarningProductDetail": "Perderá todos los cambios de estrategia de inventario que se aplicaron a este producto.",
  "inventoryStrategyChangedWarning": "Estrategia de inventario cambiada.",
  "inventoryStrategyChangedBannerPart1": "Nuevo plan de suministro con",
  "incomingChanges": "Cambios entrantes",
  "inventoryStrategyChangedBannerPart2Planning": "estará listo después del próximo cálculo. Puede hacer clic en \"Optimizar\" para obtener un nuevo plan de suministro inmediatamente.",
  "inventoryStrategyChangedBannerPart2Demand": "estará listo después del próximo cálculo. Puede hacer clic en \"Ver detalles\" para verificar los detalles en la página de Planificación.",
  "discardInventoryStrategyChangesSuccess": "Sus cambios en la estrategia de inventario han sido descartados.",
  "discardInventoryStrategyChangesError": "No se pudieron revertir sus cambios en la estrategia de inventario, por favor intente nuevamente",
  "productListDiscardInventoryStrategyChangesTitle": "¿Descartar cambios en la estrategia de inventario?",
  "productListDiscardInventoryStrategyChangesTooltip": "Descartar cambios en la estrategia de inventario que aún no se han tenido en cuenta en el cálculo del plan de suministro.",
  "productsListDiscardInventoryStrategyChangesDescription": "Cuando descarta, se perderán los cambios realizados en los productos seleccionados que no se han aplicado al cálculo del plan de suministro.",
  "inventoryStrategyChangedBannerPart2": "estará listo después del próximo cálculo. Puede hacer clic en \"Optimizar\" para obtener un nuevo plan de suministro inmediatamente.",
  "optimize": "Optimizar",
  "discardPeriodDescription": "Si descarta, Flowlity recomendará un plan de suministro para todo el horizonte de planificación.",
  "discardPeriodConfirmButton": "Descartar",
  "discardPeriodSuccess": "El periodo de fijación ha sido descartado",
  "discardPeriodError": "Ocurrió un error al descartar la fijación del periodo",
  "fixPeriodSuccess": "El periodo ha sido fijado",
  "fixPeriodError": "Ocurrió un error al fijar el periodo",
  "downloadXlsx": "Descargar la lista en XLSX",
  "xlsxExplanation": "XLSX es un formato de archivo de hoja de cálculo utilizado por Microsoft Excel para almacenar datos en tablas con fórmulas y formato",
  "downloadCsv": "Descargar la lista en CSV",
  "csvExplanation": "Valores separados por comas",
  "downloadTsv": "Descargar la lista en TSV",
  "tsvExplanation": "Valores separados por tabulaciones",
  "noBom": "Este producto no forma parte de ninguna lista de materiales",
  "deleteOrder": "Eliminar orden",
  "orderDetailsAction": "{action} detalles de la orden",
  "open": "Abrir",
  "close": "Cerrar",
  "openOrderDetails": "Abrir detalles de la orden",
  "editInventoryStrategy": "Editar estrategia de inventario",
  "selectCurrentPage": "Seleccionar página actual",
  "selectAllPages": "Seleccionar todas las páginas",
  "clearSelection": "Borrar selección",
  "units": "unidades | unidad | unidades",
  "mixed": "Mixto",
  "myStockMaxValue": "Mi valor máximo de stock",
  "searchASupplier": "Buscar un proveedor",
  "supplier": "Proveedor",
  "delayCoverage": "Cobertura de retraso",
  "categoriesOfTags": "Categorías de etiquetas",
  "here": "aquí",
  "useDefaultBufferPolicy": "Usar política predeterminada",
  "supplierDelay": "Cobertura de retraso",
  "supplierMode": "Valor",
  "ropLevel": "Nivel de Rop",
  "quantity": "Cantidad",
  "qty": "Cant",
  "coverage": "Cobertura",
  "type": "Tipo",
  "time": "Tiempo",
  "stock": "Stock",
  "maxValue": "Mi valor máximo",
  "minValue": "Mi valor mínimo",
  "bufferLevel": "Nivel de Buffer",
  "bufferLevelTip": "El Nivel de Buffer de Flowlity impactará en su nivel de stock mínimo. Cuanto más bajo sea el nivel de buffer, más bajo será su nivel mínimo. Cuanto más alto sea el nivel de buffer, más alto será su nivel mínimo.",
  "back": "Atrás",
  "apply": "Aplicar",
  "select": "Seleccionar",
  "strategyType": "Tipo de estrategia",
  "strategyTypeDesc": "Aplicar estrategia MTO o MTS para todos los productos y especificar configuraciones detalladas.",
  "bufferPolicy": "Política de buffer",
  "bufferPolicyDesc": "Especificar si la política de buffer será Tiempo, Stock, Ninguna, o simplemente usar las configuraciones predeterminadas.",
  "supplyBufferPolicyDesc": "Especificar si la política de buffer será Tiempo, Stock, Ninguna, o simplemente usar las configuraciones predeterminadas.",
  "myStockMax": "Mi stock máximo",
  "myStockMaxDesc": "Establecer el valor máximo de stock para todos los productos.",
  "whatDoYouWantToEdit": "¿Qué desea editar?",
  "viewCreationSuccess": "La vista se ha creado con éxito",
  "viewUpdateSuccess": "La vista se ha actualizado con éxito",
  "viewRemovalSuccess": "La vista se ha eliminado con éxito",
  "excludeNewProducts": "Excluir nuevos productos",
  "excludeNewProductsTooltip": "Excluir productos que no estaban disponibles durante el período comparado pasado",
  "sum": "Suma",
  "avg": "Promedio",
  "samePeriodLastYear": "Mismo período del año pasado",
  "previousPeriod": "Período anterior",
  "startUsingFlowlity": "Comience a usar Flowlity",
  "compareWith": "Comparar con",
  "period": "Período",
  "lastMonth": "El mes pasado",
  "lastQuarter": "El trimestre pasado",
  "last6Months": "Los últimos 6 meses",
  "lastYear": "El año pasado",
  "zeroStockDays": "Días sin stock",
  "stockCoverage": "Cobertura de stock",
  "pastDemand": "Demanda pasada",
  "inventoryLevel": "Nivel de inventario",
  "average": "Promedio",
  "homeKpiPercentageTooltip": "Diferencia en porcentaje entre períodos:",
  "addView": {
    "title": "Agregar una nueva vista",
    "desc": "Una vista le permite personalizar la lista de productos guardando filtros, columnas y configuraciones de ordenación."
  },
  "clearAllFilters": "Borrar todo",
  "reset": "Restablecer",
  "saveView": "Guardar vista",
  "enterName": "Ingrese un nombre",
  "deleteViewTitle": "¿Desea eliminar la vista \"{name}\"?",
  "deleteViewDescription": "Si elimina esta vista, perderá todas las configuraciones como filtrado y configuraciones de columna.",
  "reorderViews": "Reordenar vistas",
  "renameView": "Renombrar vista",
  "name": "Nombre",
  "duplicate": "Duplicar",
  "rename": "Renombrar",
  "reorder": "Reordenar",
  "addANewView": "Agregar una nueva vista",
  "defaultView": "Vista predeterminada",
  "discard": "Descartar",
  "discardModalTitle": "¿Descartar los cambios?",
  "openDemand": "Abrir demanda",
  "openPlanning": "Abrir planificación",
  "product": "Productos | Producto | Productos",
  "by": "Por",
  "day": "Días | Día | Días",
  "week": "Semanas | Semana | Semanas",
  "month": "Meses | Mes | Meses",
  "quarter": "Trimestres | Trimestre | Trimestres",
  "year": "Años | Año | Años",
  "discardChanges": "Descartar cambios",
  "discardChangesDescription": "Perderá todos los cambios que se han realizado",
  "save": "Guardar",
  "customers": "Clientes",
  "customer": "Cliente",
  "selectCustomer": "Seleccionar un cliente",
  "andXMore": "y {count} más...",
  "noSuppliersFound": "No se encontraron proveedores",
  "noAlertsAvailable": "No hay alertas disponibles",
  "confirm": "Confirmar",
  "learnMore": "Aprender más",
  "new": "Nuevo",
  "days": "días | día | días",
  "ascending": "Ascendente",
  "descending": "Descendente",
  "baseTable": {
    "loadOlderData": "Cargar datos más antiguos",
    "simulation": "solo simulación",
    "simulationTooltipTitle": "Cambios en el suministro firme",
    "simulationTooltipSubtitle": "Los cambios en esta fila no se guardarán.",
    "simulationTooltipText": "El suministro firme solo se puede cambiar en su ERP."
  },
  "cancel": "Cancelar",
  "undo": "Deshacer",
  "dateRange": "Rango de fechas",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Algo salió mal durante la obtención del estado de actualización de trabajos de datos"
    }
  },
  "backToEditing": "Volver a editar",
  "delete": "Eliminar",
  "deleteOrdersWarningText": "¿Está seguro de que desea eliminar las órdenes seleccionadas?",
  "deleteWarningCancelButton": "Cancelar",
  "deleteWarningConfirmButton": "Sí, confirmar",
  "deleteWarningTitle": "Confirmación de eliminación",
  "deliveryDate": "Fecha de entrega",
  "demand": {
    "chart": {
      "allOrders": "Envíos futuros",
      "demandFinalForecast": "Pronóstico final",
      "demandForecastFlowlity": "Pronóstico de Flowlity",
      "demandForecastExternal": "Pronóstico externo",
      "firmOrders": "Envíos confirmados",
      "plannedOrders": "Envíos planificados",
      "historicalDemand": "Demanda pasada",
      "confidenceInterval": "Intervalo de confianza",
      "maxFlowPred": "Intervalo de confianza - máximo",
      "minFlowPred": "Intervalo de confianza - mínimo",
      "myforecast": "Mi pronóstico",
      "supplierDemand": "Consumo real del cliente",
      "title": "Pronósticos"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Vista agregada",
      "clientDemand": "Cliente",
      "finalForecast": "Pronóstico final",
      "demandForecastExternal": "Pronóstico externo",
      "finalForecastExpl": "El pronóstico total teniendo en cuenta la demanda futura, mi pronóstico y la línea base del pronóstico (Flowlity o Externo)",
      "externalForecast": "Pronóstico externo",
      "externalForecastExpl": "Pronóstico o demanda dependiente proporcionada por una fuente externa",
      "futureDemand": "Demanda futura",
      "futureDemandExpl": "Demanda en el futuro que se registra como firme o planificada",
      "firmDemand": "Demanda firme",
      "firmDemandExpl": "Demanda que es firme, acordada para suceder en el futuro",
      "plannedDemand": "Demanda planificada",
      "plannedDemandExpl": "Demanda que se planea suceder en el futuro",
      "updateError": "Ocurrió un error al guardar su valor",
      "updateSuccess": "El valor se ha guardado correctamente"
    },
    "discardChanges": "Descartar",
    "editingMyForecast": "Editando",
    "regularView": {
      "pastDemand": "Demanda pasada",
      "pastDemandExpl": "Demanda registrada en el pasado",
      "myForecast": "Mi pronóstico",
      "myForecastExpl": "Cifras de pronóstico establecidas manualmente para influir en el Pronóstico Final sobre la línea base (Flowlity o Externo)",
      "flowlityForecast": "Pronóstico de Flowlity",
      "flowlityForecastExpl": "Pronóstico generado por IA de Flowlity"
    },
    "saveUpdateButton": "Guardar",
    "saving": "Guardando",
    "supplierView": {
      "allOrders": "Envíos futuros",
      "firmOrders": "Envíos confirmados",
      "plannedOrders": "Envíos planificados",
      "historicalDemand": "Consumo real del cliente"
    }
  },
  "deselectAll": "Deseleccionar todo",
  "discardTitle": "¿Descartar cambios?",
  "discardText": "Perderá todos los cambios que se realizaron durante la edición",
  "discardCancelButton": "Volver a editar",
  "discardConfirmButton": "Descartar cambios",
  "productsCount": "0 productos | 1 producto | {count} productos",
  "tagsCount": "0 etiquetas | 1 etiqueta | {count} etiquetas",
  "dropdown": {
    "alerts": {
      "title": "Alertas"
    },
    "customers": "Clientes",
    "dateRange": "Rango de fechas",
    "deselect": "Deseleccionar",
    "deselectAll": "Deseleccionar todo",
    "products": "Productos",
    "search": "Buscar {suffix}",
    "selectAll": "Seleccionar todo",
    "subContractedComponents": "Componentes",
    "suppliers": {
      "title": "Proveedores | {name} | {count} Proveedores",
      "noSuppliersAvailable": "No hay proveedores disponibles"
    },
    "tags": {
      "title": "Etiquetas | {name} | {count} Etiquetas",
      "counter": "No hay etiquetas disponibles | {count} etiqueta disponible | {count} etiquetas disponibles",
      "cell": {
        "displayTitle": "- | {name} | {count} etiquetas"
      }
    }
  },
  "emptyData": "No hay datos para mostrar",
  "error": {
    "expiredStock": "Algo salió mal durante la obtención del stock vencido",
    "storageSites": "Algo salió mal durante la obtención del stock vencido"
  },
  "credits": {
    "available": "Créditos de suministro disponibles",
    "getMore": "Obtener más créditos",
    "currentLimit": "Actualmente tiene disponibles {limit} créditos de optimización cada mes",
    "needTitle": "¿Cuántos créditos por mes necesita?",
    "credits/month": "créditos/mes",
    "requestWillBeSentExplanation": "Su solicitud será enviada a support{'@'}flowlity.com",
    "cancel": "Cancelar",
    "topupSubject": "{companyName}: aumentar créditos para {siteName}",
    "topupBody": "Por favor, aumente el límite mensual disponible a {amount} créditos/mes para {siteName} / {companyName} (ID del sitio: {siteId})",
    "notEnough": "No hay suficientes créditos para ejecutar la planificación",
    "contactFlowlity": "Obtenga más créditos contactando a Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "Este día está marcado como",
    "empty": "vacío",
    "flowlitySupply": "Este valor es recomendado por Flowlity",
    "supplyIsConfirmed": "Este suministro está validado",
    "areValidated": "está validado | están validados | están validados",
    "fullyFrozen": "completamente congelado",
    "lotSize": "Tamaño de lote",
    "monthMarkedAs": "Este mes está marcado como",
    "moq": "MOQ",
    "originalValue": "Valor original",
    "partlyFrozen": "parcialmente congelado",
    "validated": "Advertencia, la orden para este suministro ha sido validada",
    "weekMarkedAs": "Esta semana está marcada como"
  },
  "lastOrderDate": "Fecha límite de colocación de orden",
  "lastConfirmedDeliveryDate": "Última fecha de entrega confirmada",
  "lastConfirmedDeliveryDateTitle": "Fecha de entrega",
  "lastConfirmedDeliveryDateSubtitle": "última confirmada",
  "modal": {
    "backToList": "Volver a la lista",
    "backToOrder": "Volver a la orden",
    "backToCapacityUnit": "Volver a la unidad de capacidad"
  },
  "no": "No",
  "notification": {
    "error": "Error",
    "success": "Éxito"
  },
  "dashboard": {
    "actual": "Real",
    "daysOfCoverage": "Cobertura de stock",
    "days": "días",
    "tagDropdownPlaceholder": "Filtrar los resultados usando una etiqueta"
  },
  "planning": {
    "chart": {
      "allOrders": "Órdenes",
      "currentDay": "D-1",
      "week": "[S]",
      "weekTooltip": "[Semana]",
      "Expired": "Vencido",
      "firmOrders": "Órdenes firmes",
      "intervalMax": "Flowlity Máximo",
      "intervalMin": "Flowlity Mínimo",
      "Maximum": "Máximo",
      "Minimum": "Mínimo",
      "myMinimum": "Mi Stock Mínimo",
      "reorderPoint": "Punto de Reorden (ROP)",
      "plannedOrders": "Órdenes planificadas",
      "Stock": "Stock",
      "simulatedMin": "Nuevo Stock Mínimo",
      "myStockMax": "Mi Stock Máximo"
    }
  },
  "planningTable": {
    "aggregatedView": "Vista agregada",
    "currentDay": "D-1",
    "restOf": "Resto de",
    "discardChanges": "Descartar cambios",
    "discardCancelButton": "Volver a editar",
    "discardConfirmButton": "Descartar cambios",
    "discardText": "Perderá todos los cambios que se realizaron durante la edición",
    "discardTitle": "¿Descartar cambios?",
    "editingMySupply": "Editando",
    "finalForecast": "Pronóstico final",
    "justInTime": "Este producto está configurado para no tener ningún plan de suministro generado",
    "leadTime": "días de tiempo de entrega",
    "maximum": "Flowlity Máximo",
    "minimum": "Flowlity Mínimo",
    "myMinimum": "Mi Stock Mínimo",
    "reorderPoint": "Punto de Reorden (ROP)",
    "severalWarningsFound": "Se encontraron {count} problemas con el producto",
    "noStock": "No se recibieron datos de stock para este producto. Por lo tanto, el stock se estableció en 0",
    "noSupplierWarning": "Este producto no tiene proveedor vinculado, por favor agregue proveedores",
    "orders": "Mi Suministro",
    "productAdminPage": "página de administración de productos",
    "recomputeSuppliesInfo": "No tenemos suficientes datos históricos sobre este producto para calcular nuevas recomendaciones de suministro",
    "recomputeSuppliesSuccess": "Las recomendaciones de suministro se han recalculado, actualice la página si desea verlas",
    "saveConfirmButton": "Sí",
    "saveTitle": "¿Aplicar cambios y actualizar orden(es)?",
    "saveUpdateButton": "Guardar",
    "saving": "Guardando",
    "status3Supplies": "Órdenes firmes",
    "plannedOrders": "Órdenes planificadas",
    "stock": "Stock",
    "averageFinalForecast": "Cobertura de stock en días",
    "stockExpired": "Stock vencido"
  },
  "products": "Productos",
  "productSearch": {
    "aboveFlowlityMax": "Por encima del nivel óptimo de stock",
    "alerts": "Alertas",
    "events": "Eventos",
    "belowFlowlityMin": "Por debajo del nivel óptimo de stock",
    "expiringStocks": "Stocks que expiran",
    "outOfStock": "Sin stock",
    "selectedAlerts": "0 Alertas  | 1 Alerta | {count} Alertas",
    "selectedEvents": "0 Eventos  | 1 Evento | {count} Eventos",
    "selectedProductsFiltersTags": "0 etiquetas  | 1 etiqueta  | {count} Etiquetas",
    "selectedTagCategories": "0 categorías de etiquetas  | 1 categoría de etiqueta  | {count} Categorías de etiquetas",
    "lastNDays": "últimos {count} días",
    "lastNDaysCapitalized": "@.capitalize:productSearch.lastNDays",
    "nextNDays": "próximos {count} días",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Todo el horizonte",
    "daysToAct": "Días para actuar",
    "daysToActExplanation": "Número de días restantes para resolver el problema. Esta opción incluye el parámetro \"tiempo de entrega\" en cuenta, por lo que representa la urgencia de la resolución de la alerta. Si existen varios proveedores, se tiene en cuenta el tiempo de entrega promedio",
    "toActWithin": "Para actuar dentro de @:productSearch.nextNDays",
    "alertDate": "Fecha de alerta",
    "alertDateExplanation": "Número de días antes de que ocurriera el problema",
    "alertDuration": "Duración de la alerta",
    "alertValue": "Valor de la alerta",
    "stockAlerts": "Alertas de stock",
    "demandAlerts": "Alertas de demanda",
    "externalForecastAlerts": "Alertas de pronóstico externo"
  },
  "search": "Buscar por nombre",
  "searchBySite": "Buscar sitio",
  "selectedSuppliers": "0 proveedores | 1 proveedor | {count} proveedores",
  "selectedSubContractedComponents": "0 componente | 1 componente | {count} componentes",
  "customersCount": "0 clientes | 1 cliente | {count} clientes",
  "selectedCustomersCount": "0 clientes seleccionados | 1 cliente seleccionado | {count} clientes seleccionados",
  "selectedAttributes": "0 Atributos | 1 Atributo | {count} Atributos",
  "site": "Sitio",
  "siteDropdown": {
    "discardCancelButton": "Volver a editar",
    "discardConfirmButton": "Descartar cambios",
    "discardText": "Perderá todos los cambios que se realizaron durante la edición",
    "discardTitle": "¿Descartar cambios?"
  },
  "suppliers": "Proveedores",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} etiquetas"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "y {count} más..."
    },
    "select": {
      "currentPage": "Seleccionar página actual",
      "allPages": "Seleccionar todas las páginas",
      "clearAll": "Borrar selección"
    }
  },
  "updateError": "No se pudieron actualizar correctamente las órdenes",
  "updateSuccess": "Órdenes actualizadas con éxito",
  "yes": "Sí",
  "yesterday": "Ayer",
  "today": "Hoy",
  "loadMore": "Cargar más",
  "editing": "Editando",
  "loading": "Cargando...",
  "restoreInitial": "Restaurar inicial",
  "applyToAllPeriods": "Aplicar a todos los períodos",
  "applyToSpecificPeriods": "Aplicar a períodos específicos",
  "clearAll": "Borrar todo",
  "none": "Ninguno",
  "toOpenInANewTab": "para abrir en una nueva pestaña",
  "noEntitiesFound": "No se encontraron {entities}",
  "byName": "por nombre",
  "byNameAndRef": "por nombre y referencia",
  "notFound": "{entity} no encontrado",
  "notFoundExplanation": "El ID {id} de {entity} no existe o fue eliminado",
  "selectionNotFoundExplanation": "Está intentando utilizar una selección de productos que aún no existe en su sesión. Vuelva a la página de la lista de productos y seleccione los productos de nuevo.",
  "nMore": "{count} más",
  "overdue": "Atrasado",
  "more": "Más",
  "greaterThan": "Mayor que",
  "between": "Entre",
  "lessThan": "Menor que",
  "enter": "Ingresar",
  "advancedConfiguration": "Configuración avanzada",
  "internalCustomerForecastTooltip": "El pronóstico no es aplicable para clientes internos. Solo se utiliza la demanda firme para el cálculo.",
  "familyConstraints": "Restricciones de familia",
  "familyTagName": "Nombre de etiqueta de familia",
  "familyMOQ": "MOQ de familia",
  "familyUnit": "Unidad de familia",
  "familyMOQExplanation": "Este MOQ se aplica al grupo de productos que pertenecen a esta familia",
  "familyLotSize": "Tamaño de lote de familia",
  "familyLotSizeExplanation": "Este tamaño de lote se aplica a nivel de \"familia\" en todos los productos que comparten el mismo componente de producto",
  "tags": "Etiquetas | Etiqueta | Etiquetas",
  "tagCategories": "Categorías de etiquetas | Categoría de etiqueta | Categorías de etiquetas",
  "usedToGroupOrders": "Usado para agrupar órdenes",
  "createTagCategory": "Crear categoría de etiqueta",
  "addToNewTagCategory": "Agregar a nueva categoría de etiqueta",
  "editTagCategory": "Editar categoría de etiqueta",
  "tagsSelected": "{count} etiquetas seleccionadas | {count} etiqueta seleccionada | {count} etiquetas seleccionadas",
  "tagCategoryName": "Nombre de la categoría de etiqueta",
  "tagCategoryExplanation1": "Las categorías de etiquetas son un sistema para agrupar etiquetas con el mismo significado para organizar y clasificar productos",
  "tagCategoryExplanation2": "por sus atributos o características. Por ejemplo, puede tener categorías de etiquetas como \"Color\", \"Tamaño\" y \"Material\", cada una de las cuales puede contener múltiples etiquetas específicas. Luego, puede usar estas categorías en listas de productos para tener selecciones más precisas.",
  "seeLess": "ver menos",
  "seeMore": "ver más",
  "createCategory": "Crear categoría",
  "saveCategory": "Guardar categoría",
  "add": "Agregar",
  "addToTagCategory": "Agregar a categoría de etiqueta",
  "unassignCategories": "Desasignar categorías",
  "addToANewCategory": "Agregar a una nueva categoría",
  "useToGroupOrders": "Usar para agrupar órdenes",
  "confirmToAssignNTagsToCategory": "¿Está seguro de que desea agregar esta etiqueta a la categoría \"{name}\"? | ¿Está seguro de que desea agregar estas {count} etiquetas a la categoría \"{name}\"?",
  "confirmToUnassignNTagsToCategory": "¿Está seguro de que desea desasignar esta etiqueta de su categoría? | ¿Está seguro de que desea desasignar estas {count} etiquetas de sus categorías?",
  "unassign": "Desasignar",
  "errorAssignmentTags": "Ocurrió un error al (des-)asignar etiquetas a la categoría",
  "successAssignmentTags": "{count} etiquetas se han agregado a la categoría {name}",
  "successUnassignmentTags": "{count} etiquetas se han desasignado de sus categorías",
  "successUseTagsToGroupOrders": "Las etiquetas se han actualizado",
  "errorUseTagsToGroupOrders": "Ocurrió un error al usar las etiquetas seleccionadas para agrupar órdenes",
  "confirmUseToGroupOrders": "¿Está seguro de que desea usar {count} etiquetas seleccionadas para agrupar órdenes?",
  "use": "Usar",
  "errorDeletingTags": "Ocurrió un error al eliminar las etiquetas seleccionadas",
  "successDeletingTags": "Las etiquetas se han eliminado",
  "deleteNTags": "¿Eliminar {count} etiquetas?",
  "theseTagsUsedInNProducts": "Estas etiquetas se utilizan en {count} productos. No podrá deshacer esta acción.",
  "tooltipProductDefaultSupplier": "Al seleccionar un proveedor preferido, las órdenes se asignarán por defecto a este proveedor.",
  "tagCategory": "Categoría de etiqueta",
  "quotas": {
    "createQuotaDisabledWarning": "No se le permite establecer un proveedor preferido y crear nuevas cuotas cuando existen cuotas permanentes. Para poder realizar estas configuraciones, elimine las cuotas permanentes",
    "createQuotas": "Crear cuotas",
    "defaultSupplierChanged": "El proveedor predeterminado ha sido actualizado",
    "defaultSupplierIntegratedWarning": "Este valor está integrado desde una fuente externa",
    "defaultSupplierNoAvailable": "El proveedor predeterminado no se puede cambiar cuando tiene cuotas activas",
    "defaultQuotaExistsWarning": "No puede crear cuotas cuando se define un proveedor predeterminado",
    "deleteQuotaWarningContent": "Si elimina estas cuotas, perderá las configuraciones creadas. No podrá deshacer esta acción.",
    "deleteQuotaWarningTitle": "¿Desea eliminar estas cuotas?",
    "expired": "Vencido",
    "expiredQuotas": "Cuotas vencidas",
    "integratedQuotaWarning": "Las cuotas están integradas desde una fuente externa",
    "noDefaultSupplier": "No hay proveedor predeterminado",
    "permanent": "Cuotas permanentes",
    "setDefaultSupplier": "Establecer proveedor predeterminado",
    "setDefaultSupplierTooltip": "Al seleccionar un proveedor preferido, las órdenes se asignarán por defecto a este proveedor.",
    "setQuotaForSuppliers": "Establecer cuotas para proveedores",
    "updateQuotas": "Actualizar cuotas",
    "form": {
      "create": "Crear cuotas",
      "duration": "Duración",
      "durationRequired": "Por favor seleccione la duración",
      "permanentDisabledTooltip": "No puede establecer cuotas como permanentes cuando existen cuotas con duración.",
      "rangeAlreadyExists": "No puede seleccionar un rango de fechas donde ya existen cuotas",
      "save": "Guardar",
      "setAsPermanent": "Establecer como permanente",
      "totalQuotaWarningCurrent": "La suma de cuotas actualmente es",
      "totalQuotaWarningExpected": "La suma de valores debe ser igual a"
    }
  },
  "productEditTabs": {
    "events": "Eventos",
    "info": "Información",
    "inventoryStrategies": "Estrategia de Inventario",
    "quotas": "Cuotas",
    "similarProducts": "Productos Similares",
    "suppliers": "Proveedores",
    "tags": "Etiquetas",
    "bom": "Lista de Materiales"
  },
  "defaultSupplier": "Proveedor predeterminado",
  "bomFetchError": "Ocurrió un error al obtener los datos de la lista de materiales",
  "configureColums": "Configurar las columnas",
  "yourDefaultCompany": "Su empresa predeterminada",
  "yourDefaultSite": "Su sitio predeterminado",
  "from": "Desde",
  "to": "Hasta",
  "selectDate": "Seleccionar fecha",
  "click": "Hacer clic",
  "thereAreOnlySitesWhereProductPresented": "Solo hay sitios donde se presenta el producto actual",
  "switchBackTo": "Volver a",
  "toNavigateThroughTheList": "Para navegar por la lista",
  "searchByNameOrPasteRefs": "Buscar por nombre o pegar referencias",
  "value": "Valor",
  "value€": "Valor, {currency}",
  "count": "Contar",
  "atNextDelivery": "En la próxima entrega",
  "atDeliveryDate": "En la fecha de entrega",
  "current": "Actual",
  "last7days": "Últimos 7 días",
  "last30days": "Últimos 30 días",
  "last3months": "Últimos 3 meses",
  "last6months": "Últimos 6 meses",
  "last12months": "Últimos 12 meses",
  "last7d": "Últimos 7d",
  "last30d": "Últimos 30d",
  "last3m": "Últimos 3m",
  "last6m": "Últimos 6m",
  "last12m": "Últimos 12m",
  "next30days": "Próximos 30 días",
  "next3months": "Próximos 3 meses",
  "next6months": "Próximos 6 meses",
  "next12months": "Próximos 12 meses",
  "next30d": "Próximos 30d",
  "next3m": "Próximos 3m",
  "next6m": "Próximos 6m",
  "next12m": "Próximos 12m",
  "dataSource": "Fuente de datos",
  "formula": "Fórmula",
  "kpiType": "Tipo de KPI",
  "firmDemand": "Demanda firme",
  "dataMeasure": "Medida de datos",
  "measure": "Medida",
  "forecastType": "Tipo de pronóstico",
  "ordersType": "Tipo de órdenes",
  "ordersNoun": "Órdenes de Suministro",
  "min": "Mín",
  "max": "Máx",
  "trend": "Tendencia",
  "ratio": "Proporción",
  "mape": "MAPE",
  "mae": "MAE",
  "fva": "FVA",
  "all": "Todo",
  "firm": "Firme",
  "released": "Liberado",
  "planned": "Planificado",
  "statistic": "Estadística",
  "pastPerformance": "Rendimiento pasado",
  "finalForecast": "Pronóstico final",
  "stockMin": "Stock mín",
  "stockMax": "Stock máx",
  "flowlityForecastStatisticExplanation": "El conjunto incluye indicadores de valor/cantidad para un período específico",
  "flowlityForecastPastPerformanceExplanation": "El conjunto incluye indicadores de calidad de predicción: MAPE, MAE, valor agregado del pronóstico",
  "trendGeneralExplanation": "La tendencia compara 2 períodos: el período seleccionado y el anterior. La tendencia describe cuánto ha cambiado el valor del período seleccionado en relación con el período anterior en porcentaje",
  "kpi": "KPI",
  "createKPI": "Crear KPI",
  "thisKpiAlreadyAdded": "Este KPI ya se ha agregado",
  "daysShort": "d",
  "deleteKPI": "Eliminar KPI",
  "deleteKPIMsg": "Cuando elimine, este KPI se eliminará de todas las vistas y sitios de su empresa, para todos los usuarios. Esta acción no se puede deshacer.",
  "kpiDeleted": "KPI eliminado",
  "allOrders": "Todas las órdenes",
  "firmOrders": "Órdenes firmes",
  "plannedOrders": "Órdenes planificadas",
  "inventory": "Inventario",
  "reference": "Referencia",
  "nextDelivery": "Próxima entrega",
  "simulated": "Simulado",
  "trendGapsLast7d": "[D-14; D-7] y [D-7; D-1]",
  "trendGapsLast30d": "[D-60; D-31] y [D-30; D-1]",
  "trendGapsLast3m": "[M-6; M-4] y [M-3; M-1]",
  "trendGapsLast6m": "[M-12; M-7] y [M-6; M-1]",
  "trendGapsLast12m": "[M-24; M-13] y [M-12; M-1]",
  "trendGapsNext30d": "[D-30; D-1] y [D+1; D+30]",
  "trendGapsNext3m": "[M-3; M-1] y [M+1; M+3]",
  "trendGapsNext6m": "[M-6; M-1] y [M+1; M+6]",
  "trendGapsNext12m": "[M-12; M-1] y [M+1; M+12]",
  "createKPIDescription": "Puede crear un KPI que se pueda mostrar como una columna en la lista de productos. Este KPI estará disponible en todas las vistas y sitios de su empresa, para todos los usuarios.",
  "pastDemandExplanation": "Demanda pasada (en {unit}) para {for}{formula}",
  "pastDemandTrendExplanation": "Compara la demanda pasada (en {unit}) entre los períodos {gaps}",
  "firmDemandExplanation": "Órdenes validadas (en {unit}) recibidas de sus clientes externos o internos (órdenes de transferencia, órdenes de producción, etc.) para {for}",
  "finalForecastExplanation": "Pronóstico final (en {unit}) para {for}",
  "finalForecastTrendExplanation": "Compara el pronóstico final (en {unit}) entre los períodos {gaps}",
  "externalForecastExplanation": "Pronóstico externo (en {unit}) para {for}",
  "externalForecastTrendExplanation": "Compara el pronóstico externo (en {unit}) entre los períodos {gaps}",
  "myForecastExplanation": "Mi pronóstico (en {unit}) para {for}",
  "myForecastTrendExplanation": "Compara mi pronóstico (en {unit}) entre los períodos {gaps}",
  "flowlityForecastExplanation": "Pronóstico de Flowlity (en {unit}) para {for}",
  "flowlityForecastTrendExplanation": "Compara el pronóstico de Flowlity (en {unit}) entre los períodos {gaps}",
  "maeExplanation": "Error absoluto entre el pronóstico de Flowlity y la demanda pasada durante los últimos {count} días: ABS(demanda - pronóstico) en cantidad",
  "mapeExplanation": "Error porcentual medio absoluto entre el pronóstico de Flowlity y la demanda pasada durante los últimos {count} días: ABS(demanda - pronóstico)/demanda (en %)",
  "forecastValueAddedExplanation": "Pronóstico de Flowlity contra pronóstico estadístico (Promedio Móvil de los tres meses anteriores), cuando el valor es positivo, el pronóstico de Flowlity es mejor en un X%",
  "ordersCountExplanation": "Conteo de {dataset} para {for}",
  "ordersValueExplanation": "Suma de valores de {dataset} para {for}",
  "ordersQuantityExplanation": "Suma de cantidades (en unidad predeterminada) de {dataset} para {for}",
  "inventoryExplanation": "Nivel de inventario (en {unit}), {for}{formula}",
  "inventoryTrendExplanation": "Compara el nivel de inventario (en {unit}) entre los períodos {gaps}",
  "stockCoverageExplanation": "Cobertura de stock (en días), {for}{formula}",
  "stockMinExplanation": "Cobertura de stock (en {unit}), {for}{formula}",
  "zeroStockDaysExplanation": "Porcentaje promedio de días sin stock durante el {for}",
  "zeroStockDaysTrendExplanation": "Compara el porcentaje promedio de días sin stock entre los períodos {gaps}",
  "minimum": "Mínimo",
  "maximum": "Máximo",
  "productDescription": "Descripción del producto",
  "leadTime": "Tiempo de entrega",
  "defaultUnitName": "Nombre de unidad predeterminada",
  "stockCoverageNext1YAvgTooltip": "Cobertura de stock en días - promedio para el próximo año",
  "stockReduction": "Cambio de stock %",
  "stockChangeTooltip": "Cambio en % del nivel de stock en comparación con la línea base",
  "productName": "Nombre del producto",
  "defaultUnit": "Unidad predeterminada",
  "endOfLife": "Fin de vida",
  "supplyBufferPolicyTooltip": "Estrategia aplicada para cubrir la incertidumbre del proveedor: buffer de stock o tiempo",
  "reviewDemand": "Revisión de demanda",
  "reviewPlanning": "Revisión de planificación",
  "inventoryStrategyNoun": "Estrategia de inventario",
  "similarProducts": "Productos similares",
  "fixingRecommendationsDate": "Recomendaciones fijadas",
  "until": "Hasta",
  "promotions": "Promociones",
  "demandPredictability": "Previsibilidad de la demanda",
  "demandPredictabilitySubtitle": "de la demanda",
  "demandPredictabilityTooltip": "La previsibilidad de la demanda es la calidad de los datos de demanda pasada que se utilizan para generar el pronóstico",
  "demandPredictabilityNoTooltip": "No hay datos de demanda pasada, no se puede generar ningún pronóstico",
  "demandPredictabilityLowTooltip": "Los datos de demanda pasada son limitados y no permiten generar el mejor pronóstico",
  "demandPredictabilityMediumTooltip": "Flowlity proporciona el mejor pronóstico, sin embargo, la demanda mostró un comportamiento errático",
  "demandPredictabilityHighTooltip": "Flowlity proporciona el mejor pronóstico",
  "demandType": "Tipo de demanda",
  "demandTypeDropdown": "Tipo de demanda | {name} | {count} Tipos de demanda",
  "bufferLevelTooltip": "Factor que influye en el nivel de stock mínimo: cuanto mayor sea el nivel de buffer, menos riesgo está dispuesto a asumir con el producto, y mayor será el stock mínimo",
  "firmOrdersQtyTooltip": "Cantidad de órdenes de suministro firmes",
  "nextDeliveryDate": "Próxima fecha de entrega",
  "shelfLife": "Vida útil",
  "alerts": "Alertas",
  "next1YAvg": "Promedio del próximo año",
  "zeroStockDaysNext1YearTooltip": "Porcentaje promedio de días sin stock durante el próximo año",
  "flowlityAi": "Flowlity AI",
  "initialValue": "Valor inicial",
  "tagName": "Nombre de etiqueta",
  "seeDetails": "Ver detalles",
  "noTags": "No se encontraron etiquetas",
  "nProductsFound": "0 productos encontrados | 1 producto encontrado | {count} productos encontrados",
  "createForecastEvent": "Crear nuevo evento de pronóstico",
  "editForecastEvent": "Editar evento de pronóstico",
  "eventsCount": "{count} evento | {count} eventos",
  "assignForecastEvent": "Agregar evento de pronóstico existente",
  "quantityShort": "Cant",
  "description": "Descripción",
  "startDate": "Fecha de inicio",
  "endDate": "Fecha de finalización",
  "impact": "Impacto",
  "create": "Crear",
  "event": "Evento | Eventos",
  "editEvent": "Editar evento",
  "addEventsTo": "Agregar eventos a {suffix} | Agregar eventos a {suffix}",
  "deleteEvent": "Eliminar evento",
  "future": "Futuro",
  "past": "Pasado",
  "andMore": "y {count} más...",
  "forecastEvent": "Evento de pronóstico | Eventos de pronóstico",
  "noDescription": "No se proporcionó descripción",
  "forecastEventSuccess": "Se ha creado un nuevo evento de pronóstico",
  "forecastEventsFetchError": "Ocurrió un error al obtener eventos de pronóstico",
  "forecastEventUpdateSuccess": "Este evento de pronóstico ha sido actualizado",
  "forecastEventAssignSuccess": "Los productos han sido asignados a los eventos seleccionados",
  "nTagsFound": "0 etiquetas encontradas | 1 etiqueta encontrada | {count} etiquetas encontradas",
  "hasInventoryIncomingChanges": "Estrategia de inventario cambiada",
  "noAttributesFound": "No se encontraron atributos",
  "noForecastModelDemandTypeFound": "No se encontraron tipos de demanda de pronóstico",
  "continuous": "Continuo",
  "demandBasedConstant": "Flowlity AI + Mi stock mín",
  "demandBasedFlowlity": "Flowlity AI",
  "general": "General",
  "futureForecastEvents": "Eventos de pronóstico futuros",
  "decreasingMrp": "MRP decreciente",
  "fixedPeriod": "Período fijo",
  "inventoryStrategyAttr": "Política de inventario",
  "timeBuffer": "Buffer de tiempo",
  "mto": "MTO",
  "mts": "MTS",
  "noEol": "Sin fin de vida",
  "noPolicy": "Sin buffer",
  "punctual": "Puntual",
  "reorderPoint": "Punto de reorden",
  "reorderPointAI": "Punto de reorden + AI",
  "reorderPointAIShort": "PDR + AI",
  "stockBuffer": "Buffer de stock",
  "supplyBufferPolicy": "Política de buffer de suministro",
  "withComponents": "Con componentes",
  "punctualConstantFast": "Puntual constante rápido",
  "punctualConstantSlow": "Puntual constante lento",
  "punctualLumpySlow": "Puntual irregular lento",
  "punctualLumpyFast": "Puntual irregular rápido",
  "punctualSingleDemand": "Puntual única",
  "continuousLumpy": "Continuo irregular",
  "continuousSmooth": "Continuo suave",
  "noData": "Sin datos",
  "poorData": "Con pocos datos",
  "less6mData": "Menos de 6 meses de datos",
  "noDataTooltip": "Producto sin demanda pasada",
  "poorDataTooltip": "Producto sin demanda pasada durante menos de 1 año",
  "newTooltip": "Productos con menos de seis meses de datos",
  "punctualConstantFastTooltip": "Producto puntual con baja volatilidad de demanda y corto intervalo entre dos demandas en promedio",
  "punctualConstantSlowTooltip": "Producto puntual con baja volatilidad de demanda y largo intervalo entre dos demandas en promedio",
  "punctualLumpySlowTooltip": "Producto puntual con demanda volátil y largo intervalo entre dos demandas en promedio",
  "punctualLumpyFastTooltip": "Producto puntual con demanda volátil y corto intervalo entre dos demandas en promedio",
  "punctualSingleDemandTooltip": "Producto puntual con un único punto de demanda en el pasado",
  "continuousLumpyTooltip": "Producto continuo pero la demanda es muy volátil",
  "continuousSmoothTooltip": "Producto continuo pero la demanda no es muy volátil",
  "noDemandPredictability": "No",
  "lowDemandPredictability": "Baja",
  "mediumDemandPredictability": "Media",
  "highDemandPredictability": "Alta",
  "forecastSource": "Fuente de pronóstico",
  "externalForecast": "Pronóstico externo",
  "flowlityForecast": "Pronóstico de Flowlity",
  "myForecast": "Mi pronóstico",
  "forecastEventsDeleteTitle": "¿Eliminar evento de pronóstico?",
  "deleteWarning": "No podrá deshacer esta acción",
  "datePlaceholder": "Elija una fecha",
  "keepEditing": "Seguir editando",
  "cancelModifications": "Cancelar modificaciones",
  "creationDiscardText": "Perderá todos los cambios que se realizaron durante la creación",
  "editionDiscardText": "Perderá todos los cambios que se realizaron durante la edición",
  "edit": "Editar",
  "companyMissMatch": "Solo los miembros de esta empresa pueden realizar esta acción",
  "applyForecastEventFor": "Aplicar evento para",
  "allCustomers": "Todos los clientes",
  "specificCustomers": "Clientes específicos",
  "specifCustomersInfos": "Tenga en cuenta que el Evento solo se agregará a aquellos productos vinculados a los Clientes especificados.",
  "futureEvents": "No hay eventos futuros | 1 evento futuro | {count} evento futuro",
  "pastEvents": "No hay eventos pasados | 1 evento pasado | {count} eventos pasados",
  "cantExceed": "{fieldName} no puede exceder {count} caracteres",
  "minLoadCannotBeGreaterMaxLimit": "La carga mínima no puede ser mayor que el límite de capacidad. Por favor, corrija los valores resaltados en rojo antes de guardar.",
  "forecastEventExpl": "Eventos personalizados definidos por el usuario",
  "createNewEventTooltip": "Crear nuevo evento",
  "daily": "Diario",
  "weekly": "Semanal",
  "monthly": "Mensual",
  "quarterly": "Trimestral",
  "yearly": "Anual",
  "granularityOfRecommendations": "Granularidad de las recomendaciones",
  "granularityOfRecommendationsExplanation": "La granularidad de planning es la frecuencia con la que Flowlity puede generar un pedido.",
  "deliveryDateExplanation": "La fecha de entrega de un pedido generado por Flowlity es el primer día disponible para entrega dentro de un período definido por la granularidad de planning.",
  "deliverableDaysExplanation": "Los días de entrega están definidos por el día de la semana, el horizonte congelado, el cierre del proveedor y el mantenimiento del sitio.",
  "flowlityMax": "Flowlity Max",
  "flowlityMin": "Flowlity Min",
  "supply": "Suministro",
  "flowlitySupply": "Suministro Flowlity",
  "tableSettings": "Configuración de la mesa",
  "myStockMin": "Mi stock mínimo",
  "shownInTable": "Mostrado en la tabla",
  "hiddenInTable": "Oculto en la tabla",
  "errorUpdatingDemandPlanningPreferences": "Se produjo un error al actualizar las preferencias de demanda/planificación",
  "includeProductsThatMatchThisFilter": "Incluir productos que coincidan con este filtro",
  "excludeProductsThatMatchThisFilter": "Excluir productos que coincidan con este filtro",
  "nAttributes": "0 attributos | 1 atributo | {count} attributos",
  "justInTime": "No suministro",
  "appVersion": "Versión de la aplicación",
  "comments": "Comentarios | Comentario | Comentarios",
  "comment": "Comentario",
  "inputPlaceholder": "Tu comentario...",
  "title": "Comentarios",
  "noCommentsYet": "Nadie ha dejado comentarios todavía",
  "validate": "Validar",
  "ordersView": "Órdenes | Orden | Órdenes",
  "orderLinesView": "Líneas de orden | Línea de orden | Líneas de orden",
  "collaborationState": "Estado de colaboración",
  "requestValidation": "Solicitar validación",
  "proposedChanges": "Cambios propuestos",
  "notRequested": "No solicitado",
  "rejectedBySupplier": "Rechazado por el proveedor",
  "sendRequest": "Enviar solicitud",
  "discardRequest": "Descartar solicitud",
  "updateRequest": "Actualizar solicitud",
  "accept": "Aceptar",
  "discardOrder": "Descartar pedido",
  "proposeChanges": "Proponer cambios",
  "customerKpi": "KPI Cliente",
  "supplierKpi": "KPI Proveedor",
  "currentSiteKpi": "KPI Sitio",
  "recommendedQuantity": "Cantidad recomendada",
  "recommendedQuantityTooltip": "Cantidad recomendada por Flowlity respetando sus restricciones",
  "accepted": "Aceptado",
  "changedByCustomer": "Cambiado por el cliente",
  "changedBySupplier": "Cambiado por el proveedor",
  "sendingRequestsTitle": "Enviar solicitud para {counter} de {total} pedidos",
  "sendingRequestsDescription": "Solo los pedidos con estado de colaboración",
  "sendingRequestsDescriptionPart2": "pueden ser solicitados. Los pedidos con otros estados no serán procesados.",
  "discardRequestsSuccess": "Las solicitudes se han descartado correctamente",
  "discardRequestsErrors": "Se ha producido un error al descartar las solicitudes",
  "undoRejectedRequestsSuccess": "Las solicitudes rechazadas se han restaurado correctamente",
  "undoRejectedRequestsErrors": "Se ha producido un error al restaurar las solicitudes rechazadas",
  "updateRequestsSuccess": "Las solicitudes se han actualizado correctamente",
  "updateRequestsErrors": "Se ha producido un error al actualizar las solicitudes",
  "acceptRequestsSuccess": "Las solicitudes se han aceptado correctamente",
  "acceptRequestsErrors": "Se ha producido un error al aceptar las solicitudes",
  "sendRequestsSuccess": "Las solicitudes se han enviado correctamente",
  "sendRequestsErrors": "Se ha producido un error al enviar las solicitudes",
  "undoRejection": "Deshacer el rechazo",
  "reject": "Rechazar",
  "yourComment": "Tu comentario",
  "changes": "Cambios",
  "deleted": "Eliminado",
  "acceptRequestsConfirmDescription": "¿Está seguro de que desea aceptar este pedido? | ¿Está seguro de que desea aceptar estos pedidos?",
  "acceptRequestsConfirmTitle": "¿Aceptar pedido? | ¿Aceptar {count} pedidos?",
  "acceptRequestsPartiallyTitle": "Aceptar {affected} de {selected} pedidos",
  "acceptRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar a pedidos con estado de colaboración",
  "acceptRequestsPartiallySubDescriptionCollaborationStatus": "Solicitado, Modificado por el Cliente",
  "acceptRequestsPartiallySubDescriptionSuffix": "Los pedidos con otros estados no serán procesados",
  "discardSupplierRequestsConfirmTitle": "¿Descartar cambios propuestos? | ¿Descartar cambios propuestos para {count} pedidos",
  "discardSupplierRequestsPartiallyTitle": "Descartar cambio propuesto para {affected} de {selected} pedidos",
  "discardSupplierRequestsConfirmDescription": "Si descarta, perderá todos los cambios que propuso al Cliente | Si descarta, perderá todos los cambios que propuso al Cliente",
  "discardSupplierRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar a pedidos con estado de colaboración",
  "discardSupplierRequestsPartiallySubDescriptionCollaborationStatus": "Cambios propuestos",
  "discardSupplierRequestsPartiallySubDescriptionSuffix": "Los pedidos con otros estados no serán procesados",
  "rejectRequestsConfirmTitle": "¿Rechazar pedido? | ¿Rechazar {count} pedidos?",
  "rejectRequestsPartiallyTitle": "Rechazar {affected} de {selected} pedidos",
  "rejectRequestsConfirmDescription": "¿Está seguro de que desea rechazar este pedido? | ¿Está seguro de que desea rechazar estos pedidos?",
  "rejectRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar a pedidos con todos los estados de colaboración excepto",
  "rejectRequestsPartiallySubDescriptionCollaborationStatus": "No solicitado",
  "rejectRequestsPartiallySubDescriptionSuffix": "Los pedidos con otros estados no serán procesados",
  "undoRejectionRequestsConfirmTitle": "Deshacer rechazo de pedido | Deshacer rechazo para {count} pedidos",
  "undoRejectionRequestsPartiallyTitle": "Deshacer rechazo para {affected} de {selected} pedidos",
  "undoRejectionRequestsConfirmDescription": "¿Está seguro de que desea deshacer el rechazo del pedido? | ¿Está seguro de que desea deshacer el rechazo de los pedidos?",
  "undoRejectionRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar a pedidos con estado de colaboración",
  "undoRejectionRequestsPartiallySubDescriptionCollaborationStatus": "Rechazado",
  "undoRejectionRequestsPartiallySubDescriptionSuffix": "Los pedidos con otros estados no serán procesados",
  "sendRequestsConfirmConfirmBtnText": "Enviar solicitud",
  "sendRequestsConfirmDescription": "Cuando envíe, el proveedor podrá aceptar, cambiar o rechazar esta orden. | Cuando envíe, el proveedor podrá aceptar, cambiar o rechazar estas órdenes.",
  "sendRequestsConfirmTitle": "¿Enviar solicitud? | ¿Enviar solicitudes para {count} órdenes?",
  "sendRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar para órdenes con estado de colaboración",
  "sendRequestsPartiallySubDescriptionCollaborationStatus": "No solicitado",
  "sendRequestsPartiallySubDescriptionSuffix": "Las órdenes con otros estados no serán procesadas.",
  "sendRequestsPartiallyTitle": "¿Enviar solicitudes para {affected} de {selected} órdenes?",
  "errorWhileFetchingCustomersLinkedSites": "Se ha producido un error al obtener los sitios clientes vinculados",
  "addOrderLine": "Agregar línea de pedido",
  "rejected": "Rechazado",
  "discardRequestsConfirmDescription": "¿Está seguro de que desea descartar la solicitud seleccionada? | ¿Está seguro de que desea descartar las {count} solicitudes seleccionadas?",
  "discardRequestsConfirmTitle": "¿Descartar solicitud? | ¿Descartar solicitudes para {count} órdenes?",
  "discardRequestsPartiallyTitle": "¿Descartar solicitudes para {affected} de {selected} órdenes?",
  "discardRequestsPartiallySubDescriptionPrefix": "Esta acción solo se puede aplicar para órdenes con estado de colaboración",
  "discardRequestsPartiallySubDescriptionCollaborationStatus": "Solicitado, Cambios propuestos, Cambiado por el proveedor y Rechazado",
  "discardRequestsPartiallySubDescriptionSuffix": "Las órdenes con otros estados no serán procesadas.",
  "deleteOrdersConfirmDescription": "¿Está seguro de que desea eliminar esta orden? Esto eliminará todos los suministros planificados dentro de esta orden. | ¿Está seguro de que desea eliminar estas órdenes? Esto eliminará todos los suministros planificados dentro de estas órdenes.",
  "deleteOrdersConfirmTitle": "¿Eliminar orden? | ¿Eliminar {count} órdenes?",
  "deleteOrdersSuccess": "Las órdenes se han eliminado correctamente",
  "deleteOrdersErrors": "Se ha producido un error al eliminar las órdenes",
  "exportSFTP": "Exportar a SFTP"
}
