import { ORDER_STATUS } from "@/views/orders/constants";
import { ColumnSortingDirection } from "@/components/table/table.types";
import { IOrderLine } from "@/typings/models/v2/order-line";
import { IPartnerSite } from "@/typings/models/v2/supplier";
import { Dayjs } from "dayjs";
import { IOrder } from "@/typings/models/v2/order";
import { IOrderTag } from "@/typings/models/tag";
import { IOrderLinesListSortQueryByStatus, IOrdersListSortingStateByStatus } from "@/views/orders/utils/list/sorting";
import { IDropdownOrderAlert, ISubContractedComponentDropdown, IDropdownCollaborationState } from "@/views/orders/typings";
import { IOrderLineFiltersDTO, IOrdersFiltersDTO } from "@/services/api/types/order-lines.api.v2.types";
import { EnumOrderCollaborationState } from "@/typings/models/orders/typings";

export type IOrderViewsItemsCountByStatus = Record<ORDER_STATUS, number>;
export type IOrderViewsItemsCountByCollaborationState = Record<EnumOrderCollaborationState, number>;

export interface IOrderViewsSharedFilters {
  pattern: string;
  statusIds: ORDER_STATUS[];
  components: {
    exclusionMode: boolean;
    selected: ISubContractedComponentDropdown[];
  };
  tags: {
    selected: IOrderTag[];
    currentPage: number;
  };
  suppliers: {
    selected: IPartnerSite[];
    items: IPartnerSite[];
    searchText?: string;
    isLoading: boolean;
    hasMore: boolean;
  };
  collaborationStates: IDropdownCollaborationState[];
  lastOrderDateFrom: Dayjs | undefined;
  lastOrderDateTo: Dayjs | undefined;
  deliveryDateFrom: Dayjs | undefined;
  deliveryDateTo: Dayjs | undefined;
  alerts: IDropdownOrderAlert[];
}

export interface IOrdersViewFilters extends IOrderViewsSharedFilters {
}

export interface IOrderLinesViewFilters extends IOrderViewsSharedFilters {
}

export interface IOrdersAbstractList<TypeEntity, TypeFilters, TypeSorting> {
  items: TypeEntity[];
  selectedItems: TypeEntity[];
  exclusionModeIsEnabled: boolean;
  pages: number;
  hasMore: boolean;
  total: number;
  loading: boolean;
  counts: IOrderViewsItemsCountByStatus;
  filters: TypeFilters;
  sorting: TypeSorting;
  page: number;
}

export enum ORDERS_MODULE {
  SUPPLY_ORDERS = "supply-orders",
  SALES_ORDERS = "sales-orders",
}

export enum ORDERS_ENTITY {
  ORDERS = "orders",
  ORDER_LINES = "order-lines",
}

export type IOrdersList = IOrdersAbstractList<IOrder, IOrdersViewFilters, IOrdersListSortingStateByStatus>;
export type IOrderLinesList = IOrdersAbstractList<IOrderLine, IOrderLinesViewFilters, IOrderLinesListSortQueryByStatus> & {
  // user saves changes → we compute new KPIs in 5-30s (obsolete = true)
  // when the request is processed → refetch the obsolete page (patch "remote" → "local" for kpis only)
  dataAreObsolete: boolean;
};

export interface IOrderListsByModule {
  [ORDERS_ENTITY.ORDERS]: IOrdersList;
  [ORDERS_ENTITY.ORDER_LINES]: IOrderLinesList;
}

export interface IOrderLists {
  [ORDERS_MODULE.SUPPLY_ORDERS]: IOrderListsByModule;
  [ORDERS_MODULE.SALES_ORDERS]: IOrderListsByModule;
}

// computed list – pre-computed state based on static state (filters etc.)
// useful to compute once and use across the module
// e.g. for 2 actions it's enough to compute filters once
export interface IAbstractComputedSupplyList<TypeFilters extends object> {
  // contains pure filters
  // used to get list of orders/lines or counts for statuses
  getListFiltersDTO: TypeFilters;
  // contains pure filters + selection filters (include/exclude) = new filters state (but same type of filters DTO)
  // used for bulk actions (e.g. "validate all lines except [1, 2, 3]")
  getSelectionFiltersDTO: TypeFilters;
}

export interface IComputedOrderLists {
  [ORDERS_ENTITY.ORDERS]: IAbstractComputedSupplyList<IOrdersFiltersDTO>;
  [ORDERS_ENTITY.ORDER_LINES]: IAbstractComputedSupplyList<IOrderLineFiltersDTO>;
}

export interface IFetchOrdersSortingParams {
  field: string;
  order: ColumnSortingDirection;
}

// the names of diffs are different on purpose to avoid misleading:
//   in some cases we should patch "quantity" (no collaboration)
//   in some cases we should patch "proposedQty" (with collaboration, if status is not changed)
// export interface IOrderLinePartialPatch {
//   newQty?: number | null;
//   newDeliveryDate?: string;
//   latestOrderDate?: string;
// }

export type ORDERS_ENTITY_TYPE = "orders" | "orderLines";

// export interface IOrderLineDiff {
//   // diff over order line
//   patch: Partial<IOrderLine>;
//   // initial non-touched state of order line
//   origin?: IOrderLine;
// }

type IOrderLinePatchRequiredPart = Pick<IOrderLine, "productSiteId" | "orderId" | "partnerSiteId" | "siteId">;
export type IOrderLinePatchOptionalPart = Partial<Pick<IOrderLine, "proposedQuantity" | "quantity" | "proposedDeliveryDate" | "date" | "latestOrderDate" | "collaborationState">>;
export type IOrderLinePatchObject = IOrderLinePatchRequiredPart & IOrderLinePatchOptionalPart & Pick<IOrderLine, "supplyId">;
export type INewOrderLineObject = IOrderLinePatchRequiredPart & IOrderLinePatchOptionalPart;
