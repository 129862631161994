import { AxiosRequestConfig } from "axios";
import { API_V2 } from "@/services/api";
import { IGetCompanyTagCategoriesParams, IGetTagCategoryParams } from "@/services/api/types/tag-categories.api.v2.types";
import { ITagCategory } from "@/typings/models/v2/tag-category";
import { IApiDeleteResult, IApiInsertResult, IApiUpdateResult } from "@/services/api/types/shared.api.types";
import { IPaginatedResponse } from "@/typings/models/v2/api";

export const TagCategoriesAPI = {
  async getOne(
    id: number,
    params?: IGetTagCategoryParams,
    config?: AxiosRequestConfig,
  ) {
    const { data } = await API_V2.post<ITagCategory>(
      `/api/v2/tag-categories/${id}/get`,
      params,
      undefined,
      config,
    );

    return data;
  },

  async getMany(
    params: IGetCompanyTagCategoriesParams,
    config?: AxiosRequestConfig,
  ) {
    const { data } = await API_V2.post<IPaginatedResponse<ITagCategory>>(
      `/api/v2/tag-categories/get`,
      params,
      undefined,
      config,
    );

    return data;
  },

  async createOne(
    payload: Partial<ITagCategory>,
    config?: AxiosRequestConfig,
  ): Promise<IApiInsertResult> {
    const { data } = await API_V2.post<IApiInsertResult>(
      "/api/v2/tag-categories",
      payload,
      undefined,
      config,
    );

    return data;
  },

  async patchOne(
    id: number,
    payload: Partial<ITagCategory>,
  ): Promise<IApiUpdateResult> {
    const { data } = await API_V2.patch<IApiUpdateResult>(
      `/api/v2/tag-categories/${id}`,
      payload,
    );

    return data;
  },

  async deleteOne(
    id: number,
  ): Promise<IApiDeleteResult> {
    const { data } = await API_V2.delete<IApiDeleteResult>(
      `/api/v2/tag-categories/${id}`,
    );

    return data;
  },
};
