import { defineStore } from "pinia";
import { ref } from "vue";
import { ICompany } from "@/typings/models/v2/company";
import { CompaniesAPIV2 } from "@/services/api/companies.v2.api";
import { NotificationService } from "@/services/notifications";
import { useUserStore } from "@/store/user.store";
import { usePreferencesStore } from "@/store/preferences.store";
import { EnumProductCustomKpiColumnKey } from "@/shared/tables/columns/custom-kpi.types";

export const useCompanyStore = defineStore("company", () => {
  const preferencesStore = usePreferencesStore();
  const userStore = useUserStore();

  const company = ref<ICompany | null>();
  const isFetching = ref<boolean>(false);

  const fetchCompany = async (companyId: number) => {
    isFetching.value = true;

    try {
      company.value = await CompaniesAPIV2.getCompany(companyId, {
        relations: ["sites"],
        select: {
          id: true,
          name: true,
          allowSftpExport: true,
          customKpis: true,
          sites: {
            id: true,
            name: true,
          },
        },
      });
    } catch (e: any) {
      NotificationService.error("Error occurred while fetching the company data");
      throw e;
    } finally {
      isFetching.value = false;
    }

    preferencesStore.preferences.lastUsedCompanyId = companyId;
    preferencesStore.save();

    if (!userStore.currentUser.isSuperAdmin) {
      const userSiteIds = userStore.currentUser.sites.map(site => site.id);
      company.value.sites = company.value.sites.filter(site => userSiteIds.includes(site.id));
    }
  };

  const patchCompany = async (patch: Partial<ICompany>) => {
    try {
      await CompaniesAPIV2.patchCompany(company.value!.id, patch);
    } catch (e: unknown) {
      NotificationService.error("Error occurred while updating company");
      throw e;
    }

    company.value = {
      ...company.value,
      ...patch,
    } as ICompany;
  };

  const removeCustomKpiColumn = async (columnNameToRemove: EnumProductCustomKpiColumnKey) => {
    const indexOfColumnToDelete: number = company.value!.customKpis.indexOf(columnNameToRemove);
    if (indexOfColumnToDelete === -1) return;
    const newColumns: EnumProductCustomKpiColumnKey[] = company.value!.customKpis.slice();
    newColumns.splice(indexOfColumnToDelete, 1);

    await patchCompany({
      customKpis: newColumns,
    });
  };

  return {
    company,
    isFetching,
    fetchCompany,
    patchCompany,
    removeCustomKpiColumn,
  };
});
