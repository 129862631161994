import { ref } from "vue";
import { defineStore } from "pinia";
import { useCompanyStore } from "@/store/company.store";
import { useSiteStore } from "@/store/site.store";
import { NotificationService } from "@/services/notifications";
import { ColumnSortingDirection } from "@/components/table/table.types";
import { ITagCategory } from "@/typings/models/v2/tag-category";
import { TagCategoriesAPI } from "@/services/api/tag-categories.api";

// we fetch only categories related to products of current site (where if 1+ products related)
// tag categories can be used in Tactical and F&R,
// so it makes sense to keep shared store to not request data every time we go between modules
export const useSiteTagCategoriesStore = defineStore("tag-categories-store", () => {
  const companyStore = useCompanyStore();
  const siteStore = useSiteStore();

  const categories = ref<ITagCategory[]>([]);
  const areLoading = ref<boolean>(false);

  const fetchCategories = async () => {
    areLoading.value = true;

    try {
      const { data } = await TagCategoriesAPI.getMany({
        select: {
          id: true,
          name: true,
        },
        filters: {
          companyId: companyStore.company!.id,
          siteId: siteStore.site!.id,
        },
        sort: {
          name: ColumnSortingDirection.ASC,
        },
      },
      );

      categories.value = data;
    } catch (e: unknown) {
      NotificationService.error("errorWhileFetchingTagCategories");
      throw e;
    } finally {
      areLoading.value = false;
    }
  };

  return {
    categories,
    areLoading,
    fetchCategories,
  };
});
