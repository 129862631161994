import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { NotificationService } from "@/services/notifications";
import { SiteAPI } from "@/services/api/site.v2.api";
import { useSiteStore } from "@/store/site.store";
import { i18n } from "@/i18n/i18n@next";
import { useCompanyStore } from "@/store/company.store";
import { ICustomerDataForSalesOrder } from "@/typings/models/v2/customers-data-for-sales-orders";

export const useCustomersDataForSalesOrdersStore = defineStore("customers-data-for-sales-orders-store", () => {
  const { t } = i18n.global;
  const companyStore = useCompanyStore();
  const siteStore = useSiteStore();
  const customersData = ref<ICustomerDataForSalesOrder[]>([]);
  const customersLinkedSiteIds = computed<number[]>(() => customersData.value.map(obj => obj.site.id));

  const fetchCustomersDataForSalesOrders = async () => {
    try {
      customersData.value = await SiteAPI.getCustomersDataForSalesOrders(siteStore.site!.id, companyStore.company!.id);
    } catch (e: unknown) {
      NotificationService.error(t("errorWhileFetchingCustomersLinkedSites"));
      throw e;
    }
  };

  return {
    fetchCustomersDataForSalesOrders,
    customersData,
    customersLinkedSiteIds,
  };
});
