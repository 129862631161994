export const injectSvgSprite = () => {
  const container = document.createElement("div");
  container.style.display = "none";

  const content = import.meta.glob("@/assets/svg/sprite.svg", {
    query: "?raw",
    eager: true,
    import: "default",
  });

  container.innerHTML = Object.values(content)[0] as string;
  document.body.append(container);
};
