{
  "netRequirement": "Net requirement",
  "product_constraint": "Product constraint",
  "component_shortage": "Component shortage",
  "supplier_constraint": "Supplier constraint",
  "allocation_needed": "Allocation needed",
  "below_net_requirement": "Below net requirement",
  "above_net_requirement": "Above net requirement",
  "over_fulfilled": "Over-fulfilled",
  "under_fulfilled": "Under-fulfilled",
  "alertsDropdown-component_shortage": "Component shortage",
  "alertsDropdown-product_constraint": "Product constraints issue",
  "alertsDropdown-supplier_constraint": "Supplier constraints issue",
  "alertsDropdown-allocation_needed": "Allocation needed",
  "alertsDropdown-below_net_requirement": "Below net requirement",
  "alertsDropdown-above_net_requirement": "Above net requirement",
  "alertsDropdown-out_of_stock": "Out of stock",
  "alertsDropdown-over_fulfilled": "Over-fulfilled",
  "alertsDropdown-under_fulfilled": "Under-fulfilled",
  "similarProductTooltip": "Show and add similar products",
  "similarProductExplanation1": "The demand history of the source product will be copied into the demand history of the target product. This is particularly efficient to manage product replacements.",
  "similarProductExplanation2": "You can apply a coefficient when transferring the demand history, for example if two products are replaced by only one product.",
  "similarProductExplanation3": "Note that the impact on the demand history is immediate, but the forecast will only be updated at the next forecast calculation.",
  "datepickerEndDateCantBeBeforeStartDate": "The end date cannot be before the start date",
  "datepickerStartDateCantBeAfterEndDate": "The start date cannot be after the end date",
  "possible": "Possible",
  "outlierAlreadyExistForthisPeriod": "An outlier event already exists for this period",
  "eventXMarkedAsRead": "Event “{name}” was marked as read",
  "outlierCreationExplanation": "This period will be excluded by Flowlity when creating the demand forecast. The changes will take effect after the weekend's forecast update, next Monday.",
  "outlierAutoAcceptedTooltip": "This historic outlier detected by Flowlity will be automatically excluded when creating the demand forecast",
  "weekAbbreviation": "W",
  "outlierDetectedByFlowlityExplanation": "This historic outlier was detected by Flowlity and was automatically excluded when creating the demand forecast",
  "editOutlier": "Edit outlier",
  "outlierEventCreationSuccess": "Outlier event created successfully",
  "outlierEventCreationError": "An error occurred while creating the outlier event",
  "outlierEventEditingSuccess": "Outlier event edited successfully",
  "outlierEventEditingError": "An error occurred while editing the outlier event",
  "date": "Date",
  "addOutlierWrongTimebucket": "Switch to the computation time bucket \"%{timebucket}\" to add an outlier",
  "addOutlierFuturePeriod": "You can add an outlier only in the past",
  "addOutlierAlreadyExists": "An outlier already exists for this period, you can edit it instead of creating a new one",
  "addOutlier": "Add an outlier",
  "default": "Default",
  "forecastGranularity": "Forecast granularity",
  "tooltipDefaultComputationTimebucketDropdownDemand": "This granularity is used for forecast computation",
  "tooltipDefaultViewTimebucketDropdown": "This is the default view",
  "tooltipDefaultViewTimebucketDropdownDemand": "My Forecast can be edited in this granularity only",
  "format": "Format",
  "destination": "Destination",
  "file": "File",
  "download": "Download",
  "analytics": "Analytics",
  "tacticalNoun": "Tactical",
  "capacity": "Capacity",
  "salesOrders": "Sales orders",
  "supplyOrders": "Supply orders",
  "settings": "Settings",
  "dashboardNoun": "Dashboard",
  "demandNoun": "Demand",
  "planningNoun": "Planning",
  "whatsNew": "What's new?",
  "language": "Language",
  "profileSettings": "Profile settings",
  "logout": "Logout",
  "dimension": "Dimension",
  "tableNoun": "Table",
  "creditsPlural": "Credits | Credit | Credits",
  "pinTheSidebar": "Pin the sidebar",
  "unpinTheSidebar": "Unpin the sidebar",
  "selectionIsDisabledWhenSimulationIsValidated": "Selection is disabled when simulation is validated",
  "selectionIsOnlyAvailableForMtsFlowlityAiProducts": "Selection is only available for MTS Flowlity AI products",
  "selected": "{count} selected | {count} selected | {count} selected",
  "itemsSelected": "Items selected",
  "errorWhileFetchingTagCategories": "An error occurred while fetching the tag categories",
  "tagCategoryUpdateSuccess": "The tag category has been successfully updated",
  "tagCategoryUpdateError": "An error occurred while updating the tag category",
  "tagCategoryDeleteSuccess": "The \"{name}\" tag category has been successfully deleted",
  "tagCategoryDeleteError": "An error occurred while deleting the tag category",
  "deleteTagCategory": "Delete the tag category ?",
  "deleteTagCategoryButton": "Delete the category",
  "tagCategoryCreateSuccess": "The tag category has been successfully created",
  "tagCategoryCreateError": "An error occurred while creating the tag category",
  "categoryName": "Category name",
  "optional": "Optional",
  "NProductsSelected": "{countDisplay} product selected | {countDisplay} products selected",
  "downloadProductsCSVError": "An error occurred while downloading the products CSV.",
  "cannotOpenDetailsWhenSelectingAll": "You cannot open the details when more than {max} items are selected",
  "downloadCSV": "Download the list in CSV",
  "dueDate": "End fixation date",
  "fixPeriod": "Fix recommendations period",
  "fixPeriodTitle": "Fix period for selected products?",
  "discardPeriod": "Discard recommendations period",
  "discardPeriodTitle": "Discard period fixation for selected products?",
  "discardInventoryStrategyChanges": "Discard inventory strategy changes",
  "discardInventoryChangesWarningProductDetail": "You will lose all inventory strategy changes that were applied to this product.",
  "inventoryStrategyChangedWarning": "Inventory strategy changed.",
  "inventoryStrategyChangedBannerPart1": "New supply plan with",
  "incomingChanges": "Incoming changes",
  "inventoryStrategyChangedBannerPart2Planning": "will be ready after the next computation. You can click \"Optimize\" to get new supply plan immediately.",
  "inventoryStrategyChangedBannerPart2Demand": "will be ready after the next computation. You can click \"See details\" to verify the details in Planning page.",
  "discardInventoryStrategyChangesSuccess": "Your inventory strategy changes has been discarded.",
  "discardInventoryStrategyChangesError": "Your inventory strategy changes couldn't be reverted, please try again",
  "productListDiscardInventoryStrategyChangesTitle": "Discard inventory strategy changes?",
  "productListDiscardInventoryStrategyChangesTooltip": "Discard Inventory strategy changes that have not yet been taken into account in supply plan computation.",
  "productsListDiscardInventoryStrategyChangesDescription": "When you discard, any changes made to the selected products that have not been applied to the supply plan computation will be lost.",
  "inventoryStrategyChangedBannerPart2": "will be ready after the next computation. You can click \"Optimize\" to get new Supply plan immediately.",
  "optimize": "Optimize",
  "discardPeriodDescription": "If you discard, Flowlity will recommend a supply plan for the entire planning horizon.",
  "discardPeriodConfirmButton": "Discard",
  "discardPeriodSuccess": "The period fixation has been discarded",
  "discardPeriodError": "An error occurred while discarding the period fixation",
  "fixPeriodSuccess": "The period has been fixed",
  "fixPeriodError": "An error occurred while fixing the period",
  "downloadXlsx": "Download the list in XLSX",
  "xlsxExplanation": "XLSX is a spreadsheet file format used by Microsoft Excel to store data in tables with formulas and formatting",
  "downloadCsv": "Download the list in CSV",
  "csvExplanation": "Comma-separated values",
  "downloadTsv": "Download the list in TSV",
  "tsvExplanation": "Tab-separated values",
  "noBom": "This product is not part of any BOM",
  "deleteOrder": "Delete order",
  "orderDetailsAction": "{action} order details",
  "open": "Open",
  "close": "Close",
  "openOrderDetails": "Open order details",
  "editInventoryStrategy": "Edit inventory strategy",
  "selectCurrentPage": "Select current page",
  "selectAllPages": "Select all pages",
  "clearSelection": "Clear selection",
  "units": "units | unit | units",
  "mixed": "Mixed",
  "myStockMaxValue": "My stock max value",
  "searchASupplier": "Search a supplier",
  "supplier": "Supplier",
  "delayCoverage": "Delay coverage",
  "categoriesOfTags": "Categories of tags",
  "here": "here",
  "useDefaultBufferPolicy": "Use default policy",
  "supplierDelay": "Delay coverage",
  "supplierMode": "Value",
  "ropLevel": "Rop level",
  "quantity": "Quantity",
  "qty": "Qty",
  "coverage": "Coverage",
  "type": "Type",
  "time": "Time",
  "stock": "Stock",
  "maxValue": "My max value",
  "minValue": "My min value",
  "bufferLevel": "Buffer Level",
  "bufferLevelTip": "The Flowlity Buffer Level will impact your buffer stock level. The lower is the buffer level, the lower will be your minimum level. The higher is the buffer level, the higher will be your minimum level.",
  "back": "Back",
  "apply": "Apply",
  "select": "Select",
  "strategyType": "Strategy type",
  "strategyTypeDesc": "Apply MTO or MTS strategy for all products and specify detailed configurations.",
  "bufferPolicy": "Buffer policy",
  "bufferPolicyDesc": "Specify whether the buffer policy will be Time, Stock, None, or just use the default configurations.",
  "supplyBufferPolicyDesc": "Specify whether the buffer policy will be Time, Stock, None, or just use the default configurations.",
  "myStockMax": "My stock max",
  "myStockMaxDesc": "Set the max stock value for all products.",
  "whatDoYouWantToEdit": "What do you want to edit?",
  "viewCreationSuccess": "View has been successfully created",
  "viewUpdateSuccess": "View has been successfully updated",
  "viewRemovalSuccess": "View has been successfully deleted",
  "excludeNewProducts": "Exclude new products",
  "excludeNewProductsTooltip": "Exclude products that were not available during the past compared period",
  "sum": "Sum",
  "avg": "Avg",
  "samePeriodLastYear": "Same period last year",
  "previousPeriod": "Previous period",
  "startUsingFlowlity": "Start using Flowlity",
  "compareWith": "Compare with",
  "period": "Period",
  "lastMonth": "Last month",
  "lastQuarter": "Last quarter",
  "last6Months": "Last 6 months",
  "lastYear": "Last year",
  "zeroStockDays": "Zero stock days",
  "stockCoverage": "Stock coverage",
  "pastDemand": "Past demand",
  "inventoryLevel": "Inventory level",
  "average": "Average",
  "homeKpiPercentageTooltip": "Difference in percentage between periods:",
  "addView": {
    "title": "Add a new view",
    "desc": "A view allows you to customize the product list by saving filters, columns, and sorting settings."
  },
  "clearAllFilters": "Clear all",
  "reset": "Reset",
  "saveView": "Save view",
  "enterName": "Enter a name",
  "deleteViewTitle": "Do you want to delete the view \"{name}\"?",
  "deleteViewDescription": "If you delete this view, you will lose all configurations such filtering and column settings.",
  "reorderViews": "Reorder views",
  "renameView": "Rename view",
  "name": "Name",
  "duplicate": "Duplicate",
  "rename": "Rename",
  "reorder": "Reorder",
  "addANewView": "Add a new view",
  "defaultView": "Default view",
  "discard": "Discard",
  "discardModalTitle": "Discard the changes?",
  "openDemand": "Open demand",
  "openPlanning": "Open in planning",
  "product": "Products | Product | Products",
  "by": "By",
  "day": "Days | Day | Days",
  "week": "Weeks | Week | Weeks",
  "month": "Months | Month | Months",
  "quarter": "Quarters | Quarter | Quarters",
  "year": "Years | Year | Years",
  "discardChanges": "Discard changes",
  "discardChangesDescription": "You will lose all changes that have been made",
  "save": "Save",
  "customers": "Customers",
  "customer": "Customer",
  "selectCustomer": "Select a customer",
  "andXMore": "and {count} more...",
  "noSuppliersFound": "No suppliers found",
  "noAlertsAvailable": "No alerts available",
  "confirm": "Confirm",
  "learnMore": "Learn more",
  "new": "New",
  "days": "days | day | days",
  "ascending": "Ascending",
  "descending": "Descending",
  "baseTable": {
    "loadOlderData": "Load older data",
    "simulation": "simulation only",
    "simulationTooltipTitle": "Changes on firm supply",
    "simulationTooltipSubtitle": "Changes in this row will not be saved.",
    "simulationTooltipText": "Firm supply can be changed in your ERP only."
  },
  "cancel": "Cancel",
  "undo": "Undo",
  "dateRange": "Date range",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Something went wrong during the fetch of data jobs update status"
    }
  },
  "backToEditing": "Back to Editing",
  "delete": "Delete",
  "deleteOrdersWarningText": "Are you sure that you want to remove the selected orders ?",
  "deleteWarningCancelButton": "Cancel",
  "deleteWarningConfirmButton": "Yes, confirm",
  "deleteWarningTitle": "Delete confirmation",
  "deliveryDate": "Delivery date",
  "demand": {
    "chart": {
      "allOrders": "Future shipments",
      "demandFinalForecast": "Final forecast",
      "demandForecastFlowlity": "Flowlity forecast",
      "demandForecastExternal": "External forecast",
      "firmOrders": "Confirmed shipments",
      "plannedOrders": "Planned shipments",
      "historicalDemand": "Past demand",
      "confidenceInterval": "Confidence interval",
      "maxFlowPred": "Confidence interval - max",
      "minFlowPred": "Confidence interval - min",
      "myforecast": "My forecast",
      "supplierDemand": "Actual customer consumption",
      "title": "Forecasts"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Aggregated view",
      "clientDemand": "Customer",
      "finalForecast": "Final forecast",
      "demandForecastExternal": "External forecast",
      "finalForecastExpl": "The total forecast taking in account Future demand, My forecast, and Forecast baseline (Flowlity or External)",
      "externalForecast": "External forecast",
      "externalForecastExpl": "Forecast or dependent demand provided by an external source",
      "futureDemand": "Future demand",
      "futureDemandExpl": "Demand in the future that is recorded as firm or planned",
      "firmDemand": "Firm demand",
      "firmDemandExpl": "Demand that is firm, agreed to happen in the future",
      "plannedDemand": "Planned demand",
      "plannedDemandExpl": "Demand that is planned to happen in the future",
      "updateError": "An error occurred when saving your value",
      "updateSuccess": "The value has been properly saved"
    },
    "discardChanges": "Discard",
    "editingMyForecast": "Editing",
    "regularView": {
      "pastDemand": "Past demand",
      "pastDemandExpl": "Demand recorded in the past",
      "myForecast": "My forecast",
      "myForecastExpl": "Manually set forecast figures to influence the Final Forecast over the baseline (Flowlity or External)",
      "flowlityForecast": "Flowlity forecast",
      "flowlityForecastExpl": "AI generated forecast by Flowlity"
    },
    "saveUpdateButton": "Save",
    "saving": "Saving",
    "supplierView": {
      "allOrders": "Future shipments",
      "firmOrders": "Confirmed shipments",
      "plannedOrders": "Planned shipments",
      "historicalDemand": "Actual customer consumption"
    }
  },
  "deselectAll": "Deselect all",
  "discardTitle": "Discard changes ?",
  "discardText": "You will loose all changes that was made during editing",
  "discardCancelButton": "Back to Editing",
  "discardConfirmButton": "Discard changes",
  "productsCount": "0 products | 1 product | {count} products",
  "tagsCount": "0 tags | 1 tag | {count} tags",
  "dropdown": {
    "alerts": {
      "title": "Alerts"
    },
    "customers": "Customers",
    "dateRange": "Date Range",
    "deselect": "Deselect",
    "deselectAll": "Deselect all",
    "products": "Products",
    "search": "Search {suffix}",
    "selectAll": "Select all",
    "subContractedComponents": "Components",
    "suppliers": {
      "title": "Suppliers | {name} | {count} Suppliers",
      "noSuppliersAvailable": "No suppliers available"
    },
    "tags": {
      "title": "Tags | {name} | {count} Tags",
      "counter": "No tags available | {count} tag available | {count} tags available",
      "cell": {
        "displayTitle": "- | {name} | {count} tags"
      }
    }
  },
  "emptyData": "No data to display",
  "error": {
    "expiredStock": "Something went wrong during the fetch of expired stock",
    "storageSites": "Something went wrong during the fetch of expired stock"
  },
  "credits": {
    "available": "Supply credits available",
    "getMore": "Get more credits",
    "currentLimit": "Currently you have available {limit} optim credits each month",
    "needTitle": "How many credits per month do you need?",
    "credits/month": "credits/month",
    "requestWillBeSentExplanation": "Your request will be sent to support{'@'}flowlity.com",
    "cancel": "Cancel",
    "topupSubject": "{companyName}: increase credits for {siteName}",
    "topupBody": "Please increase available monthly limit to {amount} credits/month for {siteName} / {companyName} (Site ID: {siteId})",
    "notEnough": "Not enough credit to run planning",
    "contactFlowlity": "Get more credits by contacting Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "This day is marked as",
    "empty": "empty",
    "flowlitySupply": "This value is recommended by Flowlity",
    "supplyIsConfirmed": "This supply is validated",
    "areValidated": "is validated | are validated | are validated",
    "fullyFrozen": "fully frozen",
    "lotSize": "Lot size",
    "monthMarkedAs": "This month is marked as",
    "moq": "MOQ",
    "originalValue": "Original value",
    "partlyFrozen": "partly frozen",
    "validated": "Warning, the order for this supply has been validated",
    "weekMarkedAs": "This week is marked as"
  },
  "lastOrderDate": "Order Placement Deadline",
  "lastConfirmedDeliveryDate": "Last confirmed delivery date",
  "lastConfirmedDeliveryDateTitle": "Delivery date",
  "lastConfirmedDeliveryDateSubtitle": "last confirmed",
  "modal": {
    "backToList": "Back to list",
    "backToOrder": "Back to order",
    "backToCapacityUnit": "Back to capacity unit"
  },
  "no": "No",
  "notification": {
    "error": "Error",
    "success": "Success"
  },
  "dashboard": {
    "actual": "Actual",
    "daysOfCoverage": "Stock coverage",
    "days": "days",
    "tagDropdownPlaceholder": "Filter the results using a tag"
  },
  "planning": {
    "chart": {
      "allOrders": "Orders",
      "currentDay": "D-1",
      "week": "[W]",
      "weekTooltip": "[Week]",
      "Expired": "Expired",
      "firmOrders": "Firm Orders",
      "intervalMax": "Flowlity Max",
      "intervalMin": "Flowlity Min",
      "Maximum": "Maximum",
      "Minimum": "Minimum",
      "myMinimum": "My Stock Min",
      "reorderPoint": "Reorder Point (ROP)",
      "plannedOrders": "Planned Orders",
      "Stock": "Stock",
      "simulatedMin": "New Stock Min",
      "myStockMax": "My Stock Max"
    }
  },
  "planningTable": {
    "aggregatedView": "Aggregated view",
    "currentDay": "D-1",
    "restOf": "Rest of",
    "discardChanges": "Discard changes",
    "discardCancelButton": "Back to Editing",
    "discardConfirmButton": "Discard changes",
    "discardText": "You will loose all changes that was made during editing",
    "discardTitle": "Discard changes ?",
    "editingMySupply": "Editing",
    "finalForecast": "Final Forecast",
    "justInTime": "This product is set to not have any supply plan generated",
    "leadTime": "lead time days",
    "maximum": "Flowlity Max",
    "minimum": "Flowlity Min",
    "myMinimum": "My Stock Min",
    "reorderPoint": "Reorder Point (ROP)",
    "severalWarningsFound": "{count} issues were found with the product",
    "noStock": "No stock data was received for this product. Therefore, stock was set to 0",
    "noSupplierWarning": "This product has no supplier linked, please add suppliers",
    "orders": "My Supply",
    "productAdminPage": "product admin page",
    "recomputeSuppliesInfo": "We don't have enough historical data on this product to compute new supply recommendations",
    "recomputeSuppliesSuccess": "The supply recommendations were recomputed, refresh the page if you want to see them",
    "saveConfirmButton": "Yes",
    "saveTitle": "Apply changes and update order(s)?",
    "saveUpdateButton": "Save",
    "saving": "Saving",
    "status3Supplies": "Firm Orders",
    "plannedOrders": "Planned Orders",
    "stock": "Stock",
    "averageFinalForecast": "Stock Coverage in days",
    "stockExpired": "Expiring Stock"
  },
  "products": "Products",
  "productSearch": {
    "aboveFlowlityMax": "Above optimal stock level",
    "alerts": "Alerts",
    "events": "Events",
    "belowFlowlityMin": "Below optimal stock level",
    "expiringStocks": "Expiring Stocks",
    "outOfStock": "Out of stock",
    "selectedAlerts": "0 Alerts  | 1 Alert | {count} Alerts",
    "selectedEvents": "0 Events  | 1 Event | {count} Events",
    "selectedTagCategories": "0 tag categories  | 1 tag category  | {count} Tag categories",
    "selectedProductsFiltersTags": "0 tags  | 1 tag  | {count} Tags",
    "lastNDays": "last {count} days",
    "lastNDaysCapitalized": "@.capitalize:productSearch.lastNDays",
    "nextNDays": "next {count} days",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Whole horizon",
    "daysToAct": "Days to act",
    "daysToActExplanation": "Number of days remaining to resolve the problem. This option includes \"lead time\" parameter into account so it represents the urgency of the alert resolution. If several suppliers exist, average lead time is taken into account",
    "toActWithin": "To act within @:productSearch.nextNDays",
    "alertDate": "Alert date",
    "alertDateExplanation": "Number of days before the problem occurred",
    "alertDuration": "Alert duration",
    "alertValue": "Alert value",
    "stockAlerts": "Stock alerts",
    "demandAlerts": "Demand alerts",
    "externalForecastAlerts": "External forecast alerts"
  },
  "search": "Search by name",
  "searchBySite": "Search site",
  "selectedSuppliers": "0 suppliers | 1 supplier | {count} suppliers",
  "selectedSubContractedComponents": "0 component | 1 component | {count} components",
  "customersCount": "0 customers | 1 customer | {count} customers",
  "selectedCustomersCount": "0 customers selected | 1 customer selected | {count} customers selected",
  "selectedAttributes": "0 Attributes | 1 Attribute | {count} Attributes",
  "site": "Site",
  "siteDropdown": {
    "discardCancelButton": "Back to Editing",
    "discardConfirmButton": "Discard changes",
    "discardText": "You will loose all changes that was made during editing",
    "discardTitle": "Discard changes ?"
  },
  "suppliers": "Suppliers",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} tags"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "and {count} more..."
    },
    "select": {
      "currentPage": "Select current page",
      "allPages": "Select all pages",
      "clearAll": "Clear selection"
    }
  },
  "updateError": "Order(s) could not be updated correctly",
  "updateSuccess": "Successfully updated order(s)",
  "yes": "Yes",
  "yesterday": "Yesterday",
  "today": "Today",
  "loadMore": "Load More",
  "editing": "Editing",
  "loading": "Loading...",
  "restoreInitial": "Restore initial",
  "applyToAllPeriods": "Apply to all periods",
  "applyToSpecificPeriods": "Apply to specific periods",
  "clearAll": "Clear all",
  "none": "None",
  "toOpenInANewTab": "to open in a new tab",
  "noEntitiesFound": "No {entities} found",
  "byName": "by name",
  "byNameAndRef": "by name and reference",
  "notFound": "{entity} not found",
  "notFoundExplanation": "{entity} ID {id} doesn't exist or was removed",
  "selectionNotFoundExplanation": "You are trying to use a product selection that does not already exist in your session. Please go back to the product list page and select the products again.",
  "nMore": "{count} more",
  "overdue": "Overdue",
  "more": "More",
  "greaterThan": "Greater than",
  "between": "Between",
  "lessThan": "Less than",
  "enter": "Enter",
  "advancedConfiguration": "Advanced configuration",
  "internalCustomerForecastTooltip": "Forecast is not applicable for internal customers. Only firm demand of is used for calculation.",
  "familyConstraints": "Family constraints",
  "familyTagName": "Family tag name",
  "familyMOQ": "Family MOQ",
  "familyUnit": "Family Unit",
  "familyMOQExplanation": "This MOQ is applied to the group of products belonging to this family",
  "familyLotSize": "Family lot size",
  "familyLotSizeExplanation": "This lot size is applied at \"family level\" on all products sharing the same product component",
  "tags": "Tags | Tag | Tags",
  "tagCategories": "Tag categories | Tag category | Tag categories",
  "usedToGroupOrders": "Used to group orders",
  "createTagCategory": "Create tag category",
  "addToNewTagCategory": "Add to new tag category",
  "editTagCategory": "Edit tag category",
  "tagsSelected": "{count} tags selected | {count} tag selected | {count} tags selected",
  "tagCategoryName": "Tag category name",
  "tagCategoryExplanation1": "Tag categories is as a system to group tags with the same meaning to organize and classify products",
  "tagCategoryExplanation2": "by their attributes or characteristics. For instance, you might have tag categories like \"Color\", \"Size\", and \"Material\", each of which can contain multiple specific tags. Then, you can use these categories in product lists to have more precious selections.",
  "seeLess": "see less",
  "seeMore": "see more",
  "createCategory": "Create category",
  "saveCategory": "Save category",
  "add": "Add",
  "addToTagCategory": "Add to tag category",
  "unassignCategories": "Unassign categories",
  "addToANewCategory": "Add to a new category",
  "useToGroupOrders": "Use to group orders",
  "confirmToAssignNTagsToCategory": "Are you sure you want to add this tag to the \"{name}\" category? | Are you sure you want to add these {count} tags to the \"{name}\" category?",
  "confirmToUnassignNTagsToCategory": "Are you sure you want to unassign this tag from its category? | Are you sure you want to unassign these {count} tags from their categories?",
  "unassign": "Unassign",
  "errorAssignmentTags": "Error occurred while (un-)assigning tags to the category",
  "successAssignmentTags": "{count} tags have been added to {name} category",
  "successUnassignmentTags": "{count} tags have been unassigned from their categories",
  "successUseTagsToGroupOrders": "Tags have been updated",
  "errorUseTagsToGroupOrders": "Error occurred while using selected tags to group orders",
  "confirmUseToGroupOrders": "Are you sure you want to use {count} selected tags to group order?",
  "use": "Use",
  "errorDeletingTags": "Error occurred while deleting selected tags",
  "successDeletingTags": "Tags have been removed",
  "deleteNTags": "Delete {count} tags?",
  "theseTagsUsedInNProducts": "These tags used in {count} products. You will not be able to undo this action.",
  "tooltipProductDefaultSupplier": "When selecting a preferred supplier, the orders will be allocated by default to this supplier.",
  "tagCategory": "Tag category",
  "quotas": {
    "createQuotaDisabledWarning": " You are not allowed to set preferred supplier and create new quotas when Permanent quotas exist. To be allowed doing these configurations, please remove Permanent quotas",
    "createQuotas": "Create quotas",
    "defaultSupplierChanged": "Default supplier has been updated",
    "defaultSupplierIntegratedWarning": "This value is integrated from an external source",
    "defaultSupplierNoAvailable": "Default supplier cannot be changed when you have active quotas",
    "defaultQuotaExistsWarning": "You cannot create quotas when default supplier is defined",
    "deleteQuotaWarningContent": "If you delete this quotas, you will lose created  configurations. You will not be able to undo this action.",
    "deleteQuotaWarningTitle": "Do you want to delete this quotas",
    "expired": "Expired",
    "expiredQuotas": "Expired Quotas",
    "integratedQuotaWarning": "Quotas are integrated from an external source",
    "noDefaultSupplier": "No default supplier",
    "permanent": "Permanent quotas",
    "setDefaultSupplier": "Set default supplier",
    "setDefaultSupplierTooltip": "When selecting a preferred supplier, the orders will be allocated by default to this supplier.",
    "setQuotaForSuppliers": "Set quotas for suppliers",
    "updateQuotas": "Update quotas",
    "form": {
      "create": "Create quotas",
      "duration": "Duration",
      "durationRequired": "Please select duration",
      "permanentDisabledTooltip": "You can’t set quotas as permanent, when quotas with duration exists.",
      "rangeAlreadyExists": "You can’t select date range where quotas already exists",
      "save": "Save",
      "setAsPermanent": "Set as permanent",
      "totalQuotaWarningCurrent": "Sum of quotas currently is",
      "totalQuotaWarningExpected": "The sum of values should be equal to"
    }
  },
  "productEditTabs": {
    "events": "Events",
    "info": "Info",
    "inventoryStrategies": "Inventory Strategy",
    "quotas": "Quotas",
    "similarProducts": "Similar Products",
    "suppliers": "Suppliers",
    "tags": "Tags",
    "bom": "Bom"
  },
  "defaultSupplier": "Default supplier",
  "bomFetchError": "An error occurred while fetching BOM data",
  "configureColums": "Configure the columns",
  "yourDefaultCompany": "Your default company",
  "yourDefaultSite": "Your default site",
  "from": "From",
  "to": "To",
  "selectDate": "Select date",
  "click": "Click",
  "thereAreOnlySitesWhereProductPresented": "There are only sites where the current product is presented",
  "switchBackTo": "Switch back to",
  "toNavigateThroughTheList": "To navigate through the list",
  "searchByNameOrPasteRefs": "Search by name or paste references",
  "value": "Value",
  "value€": "Value, {currency}",
  "count": "Count",
  "atNextDelivery": "At the next delivery",
  "atDeliveryDate": "At delivery date",
  "current": "Current",
  "last7days": "Last 7 days",
  "last30days": "Last 30 days",
  "last3months": "Last 3 months",
  "last6months": "Last 6 months",
  "last12months": "Last 12 months",
  "last7d": "Last 7d",
  "last30d": "Last 30d",
  "last3m": "Last 3m",
  "last6m": "Last 6m",
  "last12m": "Last 12m",
  "next30days": "Next 30 days",
  "next3months": "Next 3 months",
  "next6months": "Next 6 months",
  "next12months": "Next 12 months",
  "next30d": "Next 30d",
  "next3m": "Next 3m",
  "next6m": "Next 6m",
  "next12m": "Next 12m",
  "dataSource": "Data source",
  "formula": "Formula",
  "kpiType": "KPI type",
  "firmDemand": "Firm demand",
  "dataMeasure": "Data measure",
  "measure": "Measure",
  "forecastType": "Forecast type",
  "ordersType": "Orders type",
  "ordersNoun": "Supply Orders",
  "min": "Min",
  "max": "Max",
  "trend": "Trend",
  "ratio": "Ratio",
  "mape": "MAPE",
  "mae": "MAE",
  "fva": "FVA",
  "all": "All",
  "firm": "Firm",
  "released": "Released",
  "planned": "Planned",
  "statistic": "Statistic",
  "pastPerformance": "Past performance",
  "finalForecast": "Final forecast",
  "stockMin": "Stock min",
  "stockMax": "Stock max",
  "flowlityForecastStatisticExplanation": "The set includes value/quantity indicators for a specific period",
  "flowlityForecastPastPerformanceExplanation": "The set includes prediction quality indicators: MAPE, MAE, forecast value added",
  "trendGeneralExplanation": "Trend compares 2 periods: the selected period and the previous one. The trend describes how much the value of the selected period has changed in relation to the previous period in percentage",
  "kpi": "KPI",
  "createKPI": "Create KPI",
  "thisKpiAlreadyAdded": "This KPI already added",
  "daysShort": "d",
  "deleteKPI": "Delete KPI",
  "deleteKPIMsg": "When you delete, this KPI will be removed from all views and sites across your company, for all users. This action cannot be undone.",
  "kpiDeleted": "KPI deleted",
  "allOrders": "All orders",
  "firmOrders": "Firm orders",
  "plannedOrders": "Planned orders",
  "inventory": "Inventory",
  "reference": "Reference",
  "nextDelivery": "Next delivery",
  "simulated": "Simulated",
  "trendGapsLast7d": "[D-14; D-7] and [D-7; D-1]",
  "trendGapsLast30d": "[D-60; D-31] and [D-30; D-1]",
  "trendGapsLast3m": "[M-6; M-4] and [M-3; M-1]",
  "trendGapsLast6m": "[M-12; M-7] and [M-6; M-1]",
  "trendGapsLast12m": "[M-24; M-13] and [M-12; M-1]",
  "trendGapsNext30d": "[D-30; D-1] and [D+1; D+30]",
  "trendGapsNext3m": "[M-3; M-1] and [M+1; M+3]",
  "trendGapsNext6m": "[M-6; M-1] and [M+1; M+6]",
  "trendGapsNext12m": "[M-12; M-1] and [M+1; M+12]",
  "createKPIDescription": "You can create KPI that can be displayed as a column in the product list. This KPI will be available in all views and sites across your company, for all users.",
  "pastDemandExplanation": "Past demand (in {unit}) for {for}{formula}",
  "pastDemandTrendExplanation": "Compares the past demand (in {unit}) between the periods {gaps}",
  "firmDemandExplanation": "Validated orders (in {unit}) received from your external or internal customers (transfer orders, production orders, etc.) for {for}",
  "finalForecastExplanation": "Final forecast (in {unit}) for {for}",
  "finalForecastTrendExplanation": "Compares the final forecast (in {unit}) between the periods {gaps}",
  "externalForecastExplanation": "External forecast (in {unit}) for {for}",
  "externalForecastTrendExplanation": "Compares external forecast (in {unit}) between the periods {gaps}",
  "myForecastExplanation": "My forecast (in {unit}) for {for}",
  "myForecastTrendExplanation": "Compares my forecast (in {unit}) between the periods {gaps}",
  "flowlityForecastExplanation": "Flowlity forecast (in {unit}) for {for}",
  "flowlityForecastTrendExplanation": "Compares flowlity forecast (in {unit}) between the periods {gaps}",
  "maeExplanation": "Absolute error between flowlity forecast and past demand over the last {count} days: ABS(demand - forecast) in qty",
  "mapeExplanation": "Mean average percentage error between flowlity forecast and past demand over the last {count} days: ABS(demand - forecast)/demand (in %)",
  "forecastValueAddedExplanation": "Flowlity forecast against statistical forecast (Rolling Average of the previous three months), when the value is positive the flowlity forecast is better by X%",
  "ordersCountExplanation": "Count of {dataset} for {for}",
  "ordersValueExplanation": "Sum of values of {dataset} for {for}",
  "ordersQuantityExplanation": "Sum of quantities (in default unit) of {dataset} for {for}",
  "inventoryExplanation": "Inventory level (in {unit}), {for}{formula}",
  "inventoryTrendExplanation": "Compares inventory level (in {unit}) between the periods {gaps}",
  "stockCoverageExplanation": "Stock coverage (in days), {for}{formula}",
  "stockMinExplanation": "Stock coverage (in {unit}), {for}{formula}",
  "zeroStockDaysExplanation": "Average percentage of days without stock over the {for}",
  "zeroStockDaysTrendExplanation": "Compares average percentage of days without stock between the periods {gaps}",
  "minimum": "Minimum",
  "maximum": "Maximum",
  "productDescription": "Product description",
  "leadTime": "Lead time",
  "defaultUnitName": "Default unit name",
  "stockCoverageNext1YAvgTooltip": "Stock coverage in days - average for the next year",
  "stockReduction": "Stock change %",
  "stockChangeTooltip": "Change in % of the stock level compared to baseline",
  "productName": "Product name",
  "defaultUnit": "Default unit",
  "endOfLife": "End of life",
  "supplyBufferPolicyTooltip": "Strategy applied to cover for supplier uncertainty: stock or time buffer",
  "reviewDemand": "Demand review",
  "reviewPlanning": "Planning review",
  "inventoryStrategyNoun": "Inventory strategy",
  "similarProducts": "Similar products",
  "fixingRecommendationsDate": "Fixed recommendations",
  "until": "Until",
  "promotions": "Promotions",
  "demandPredictability": "Demand predictability",
  "demandPredictabilitySubtitle": "of demand",
  "demandPredictabilityTooltip": "Demand predictability is the quality of past demand data which is being used for generating the forecast",
  "demandPredictabilityNoTooltip": "No past demand data, cannot generate any forecast",
  "demandPredictabilityLowTooltip": "Past demand data is limited and does not allow generating the best forecast",
  "demandPredictabilityMediumTooltip": "Flowlity provides the best forecast, however, demand demonstrated an erratic behavior",
  "demandPredictabilityHighTooltip": "Flowlity provides the best forecast",
  "demandType": "Demand type",
  "demandTypeDropdown": "Demand type | {name} | {count} Demand types",
  "bufferLevelTooltip": "Factor influencing the level of minimum stock: the higher the buffer level, the less risk you are ready to take on the product, and the higher the minimum stock",
  "firmOrdersQtyTooltip": "Quantity of firm supply orders",
  "nextDeliveryDate": "Next delivery date",
  "shelfLife": "Shelf life",
  "alerts": "Alerts",
  "next1YAvg": "Next 1Y avg.",
  "zeroStockDaysNext1YearTooltip": "Average percentage of days without stock over the next year",
  "flowlityAi": "Flowlity AI",
  "initialValue": "Initial value",
  "tagName": "Tag name",
  "seeDetails": "See details",
  "noTags": "No Tags Found",
  "nProductsFound": "0 products found | 1 product found | {count} products found",
  "createForecastEvent": "Create new forecast event",
  "editForecastEvent": "Edit forecast event",
  "eventsCount": "{count} event | {count} events",
  "assignForecastEvent": "Add to existing forecast event",
  "quantityShort": "Qty",
  "description": "Description",
  "startDate": "Start date",
  "endDate": "End date",
  "impact": "Impact",
  "impactRange": "Yearly",
  "create": "Create",
  "event": "Event | Events",
  "editEvent": "Edit event",
  "addEventsTo": "Add events to {suffix} | Add events to {suffix}",
  "deleteEvent": "Remove event",
  "future": "Future",
  "past": "Past",
  "andMore": "and {count} more...",
  "forecastEvent": "Forecast event | Forecast events",
  "noDescription": "No description provided",
  "forecastEventSuccess": "A new forecast event has been created",
  "forecastEventsFetchError": "An error occurred while fetching forecast events",
  "forecastEventUpdateSuccess": "This forecast event has been updated",
  "forecastEventAssignSuccess": "Products has been assigned to selected events",
  "nTagsFound": "0 tags found | 1 tag found | {count} tags found",
  "hasInventoryIncomingChanges": "Inventory strategy changed",
  "noAttributesFound": "No attributes found",
  "noForecastModelDemandTypeFound": "No forecast model demand type found",
  "continuous": "Continuous",
  "demandBasedConstant": "Flowlity AI + My stock min",
  "demandBasedFlowlity": "Flowlity AI",
  "general": "General",
  "futureForecastEvents": "Future forecast events",
  "decreasingMrp": "Decreasing MRP",
  "fixedPeriod": "Fixed period",
  "inventoryStrategyAttr": "Inventory policy",
  "timeBuffer": "Time buffer",
  "mto": "MTO",
  "mts": "MTS",
  "noEol": "No end of life",
  "noPolicy": "No buffer",
  "punctual": "Punctual",
  "reorderPoint": "Reorder point",
  "reorderPointAI": "Reorder point + AI",
  "reorderPointAIShort": "ROP + AI",
  "stockBuffer": "Stock buffer",
  "supplyBufferPolicy": "Supply buffer policy",
  "withComponents": "With components",
  "punctualConstantFast": "Punctual constant fast",
  "punctualConstantSlow": "Punctual constant slow",
  "punctualLumpySlow": "Punctual lumpy slow",
  "punctualLumpyFast": "Punctual lumpy fast",
  "punctualSingleDemand": "Punctual single",
  "continuousLumpy": "Continuous lumpy",
  "continuousSmooth": "Continuous smooth",
  "noData": "No data",
  "poorData": "No or poor data",
  "less6mData": "Less 6 months data",
  "noDataTooltip": "Product with no past demand data",
  "poorDataTooltip": "Product with no past demand for less than 1 year",
  "newTooltip": "Products with less than six months of data",
  "punctualConstantFastTooltip": "Punctual product with a low demand volatile and short interval between two demands in average",
  "punctualConstantSlowTooltip": "Punctual product with a low demand volatile and long interval between two demands in average",
  "punctualLumpySlowTooltip": "Punctual product with a volatile demand and a long interval between two demands in average",
  "punctualLumpyFastTooltip": "Punctual product with a volatile demand and short interval between two demands in average",
  "punctualSingleDemandTooltip": "Punctual product with a unique point of demand in the past",
  "continuousLumpyTooltip": "Continuous product but demand is very volatile",
  "continuousSmoothTooltip": "Continuous product but demand is not very volatile",
  "noDemandPredictability": "No",
  "lowDemandPredictability": "Low",
  "mediumDemandPredictability": "Medium",
  "highDemandPredictability": "High",
  "forecastSource": "Forecast source",
  "externalForecast": "External forecast",
  "flowlityForecast": "Flowlity forecast",
  "myForecast": "My forecast",
  "forecastEventsDeleteTitle": "Delete forecast event?",
  "deleteWarning": "You will not be able to undo this action",
  "datePlaceholder": "Choose a date",
  "keepEditing": "Keep editing",
  "cancelModifications": "Cancel modifications",
  "creationDiscardText": "You will loose all changes that was made during creation",
  "editionDiscardText": "You will loose all changes that was made during edition",
  "edit": "Edit",
  "companyMissMatch": "Only members of this company can perform this action",
  "applyForecastEventFor": "Apply event for",
  "allCustomers": "All customers",
  "specificCustomers": "Specific customers",
  "specifCustomersInfos": "Please note that the Event will be added only to those products linked to the specified Customers.",
  "futureEvents": "No future events | 1 future event | {count} future event",
  "pastEvents": "No past events | 1 past event | {count} past events",
  "cantExceed": "{fieldName} can't exceed {count} characters",
  "minLoadCannotBeGreaterMaxLimit": "Min load cannot be greater than capacity limit. Please correct the values highlighted in red before saving.",
  "forecastEventExpl": "Custom events defined by user",
  "createNewEventTooltip": "Create new event",
  "appVersion": "App version",
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "quarterly": "Quarterly",
  "yearly": "Yearly",
  "granularityOfRecommendations": "Granularity of recommendations",
  "granularityOfRecommendationsExplanation": "The planning granularity is the frequency at which Flowlity can generate an order.",
  "deliveryDateExplanation": "The delivery date of an order generated by Flowlity is the first deliverable day within a time period defined by the planning granularity.",
  "deliverableDaysExplanation": "Deliverable days are defined by the weekday, lead time, the fixed recommendations horizon, supplier closure and site maintenance.",
  "flowlityMax": "Flowlity Max",
  "flowlityMin": "Flowlity Min",
  "supply": "Supply",
  "flowlitySupply": "Flowlity Supply",
  "tableSettings": "Table settings",
  "myStockMin": "My Stock Min",
  "shownInTable": "Shown in table",
  "hiddenInTable": "Hidden in table",
  "errorUpdatingDemandPlanningPreferences": "Error occurred while updating demand/planning preferences",
  "includeProductsThatMatchThisFilter": "Include products that match this filter",
  "excludeProductsThatMatchThisFilter": "Exclude products that match this filter",
  "nAttributes": "0 attributes | 1 attribute | {count} attributes",
  "justInTime": "No supply",
  "comments": "Comments | Comment | Comments",
  "comment": "Comment",
  "orderId": "Order ID",
  "openOrder": "Open in Order page",
  "inputPlaceholder": "Your comment...",
  "noCommentsYet": "Nobody left comments yet",
  "validate": "Validate",
  "ordersView": "Orders | Order | Orders",
  "orderLinesView": "Order lines | Order line | Order lines",
  "requestValidation": "Requested",
  "collaborationState": "Collaboration status",
  "proposedChanges": "Proposed changes",
  "notRequested": "Not requested",
  "rejectedBySupplier": "Rejected by supplier",
  "customerKpi": "Customer's KPI",
  "supplierKpi": "Supplier's KPI",
  "currentSiteKpi": "Current site's KPI",
  "sendRequest": "Send request",
  "discardRequest": "Discard request",
  "updateRequest": "Update request",
  "accept": "Accept",
  "discardOrder": "Discard order",
  "proposeChanges": "Propose changes",
  "recommendedQuantity": "Recommended quantity",
  "recommendedQuantityTooltip": "Quantity recommended by Flowlity respecting your constraints",
  "accepted": "Accepted",
  "changedByCustomer": "Changed by customer",
  "changedBySupplier": "Changed by supplier",
  "sendingRequestsTitle": "Send request for {counter} of {total} orders",
  "sendingRequestsDescription": "Only orders with collaboration status",
  "sendingRequestsDescriptionPart2": "can be requested. Orders with other statuses will not be processed.",
  "discardRequestsSuccess": "Requests have been successfully discarded",
  "discardRequestsErrors": "An error occurred while discarding the requests",
  "undoRejectedRequestsSuccess": "Rejected requests have been successfully restored",
  "undoRejectedRequestsErrors": "An error occurred while restoring the rejected requests",
  "updateRequestsSuccess": "Requests have been successfully updated",
  "updateRequestsErrors": "An error occurred while updating the requests",
  "acceptRequestsSuccess": "Requests have been successfully accepted",
  "acceptRequestsErrors": "An error occurred while accepting the requests",
  "yourComment": "Your comment",
  "changes": "Changes",
  "deleted": "Deleted",
  "sendRequestsSuccess": "Requests have been successfully sent",
  "sendRequestsErrors": "An error occurred while sending the requests",
  "undoRejection": "Undo rejection",
  "reject": "Reject",
  "acceptRequestsConfirmDescription": "Are you sure you want to accept this order? | Are you sure you want to accept these orders?",
  "acceptRequestsConfirmTitle": "Accept order ? | Accept {count} orders?",
  "acceptRequestsPartiallyTitle": "Accept {affected} of {selected} orders",
  "acceptRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with collaboration status",
  "acceptRequestsPartiallySubDescriptionCollaborationStatus": "Requested, Changed by Customer",
  "acceptRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed",
  "discardSupplierRequestsConfirmTitle": "Discard proposed changes? | Discard proposed changes for {count} orders",
  "discardSupplierRequestsPartiallyTitle": "Discard proposed change for {affected} of {selected} orders",
  "discardSupplierRequestsConfirmDescription": "When you discard, you will loose all the changes that you proposed to Customer | When you discard, you will loose all the changes that you proposed to Customer",
  "discardSupplierRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with collaboration status",
  "discardSupplierRequestsPartiallySubDescriptionCollaborationStatus": "Proposed changes",
  "discardSupplierRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed.",
  "rejectRequestsConfirmTitle": "Reject order? | Reject {count} orders ?",
  "rejectRequestsPartiallyTitle": "Reject {affected} of {selected} orders",
  "rejectRequestsConfirmDescription": "Are you sure you want to discard reject this order? | Are you sure you want to reject these order?",
  "rejectRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with all collaboration status except",
  "rejectRequestsPartiallySubDescriptionCollaborationStatus": "Not requested",
  "rejectRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed",
  "undoRejectionRequestsConfirmTitle": "Undo order rejection | Undo rejection for {count} orders",
  "undoRejectionRequestsPartiallyTitle": "Undo rejection for {affected} of {selected} orders",
  "undoRejectionRequestsConfirmDescription": "Are you sure you want to undo order rejection? | Are you sure you want to undo orders rejection?",
  "undoRejectionRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with collaboration status",
  "undoRejectionRequestsPartiallySubDescriptionCollaborationStatus": "Rejected",
  "undoRejectionRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed",
  "deleteOrdersConfirmDescription": "Are you sure you want to delete this order? This will delete all the planned supplies inside this order. | Are you sure you want to delete these order? This will delete all the planned supplies inside these orders.",
  "deleteOrdersConfirmTitle": "Delete orders ? | Delete {count} orders?",
  "deleteOrdersSuccess": "Orders have been successfully deleted",
  "deleteOrdersErrors": "An error occurred while deleting the orders",
  "sendRequestsConfirmConfirmBtnText": "Send request",
  "sendRequestsConfirmDescription": "When you send, the Supplier will be able to accept, change or reject this order | When you send, the Supplier will be able to accept, change or reject these orders",
  "sendRequestsConfirmTitle": "Send request? | Send requests for {count} orders?",
  "sendRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with collaboration status",
  "sendRequestsPartiallySubDescriptionCollaborationStatus": "Not requested",
  "sendRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed.",
  "sendRequestsPartiallyTitle": "Send requests for {affected} of {selected} orders?",
  "errorWhileFetchingCustomersLinkedSites": "An error occurred while fetching customers linked sites",
  "addOrderLine": "Add order line",
  "rejected": "Rejected",
  "discardRequestsConfirmDescription": "When you discard, the Supplier will not see the order request. | When you discard, the Supplier will not see the orders requests.",
  "discardRequestsConfirmTitle": "Discard request? | Discard requests for {count} orders?",
  "discardRequestsPartiallyTitle": "Discard requests for {affected} of {selected} orders?",
  "discardRequestsPartiallySubDescriptionPrefix": "This action can be applied only for orders with collaboration status",
  "discardRequestsPartiallySubDescriptionCollaborationStatus": "Requested, Proposed changes, Changed by Supplier and Rejected",
  "discardRequestsPartiallySubDescriptionSuffix": "Orders with other statuses will not be processed",
  "exportSFTP": "Export to SFTP"
}
