{
  "requiredError": "Este campo es obligatorio",
  "requiredNumberError": "Por favor, proporcione un número válido",
  "requiredStringError": "Por favor, proporcione una cadena válida",
  "main": {
    "exportTitle": "Descargar datos",
    "settings": "Configuraciones",
    "company": "Empresa",
    "sites": "Sitios",
    "users": "Usuarios",
    "suppliers": "Proveedores",
    "customers": "Clientes",
    "products": "Productos",
    "promotions": "Promociones",
    "bufferPolicy": "Política de Buffer",
    "closures": "Cierres de Sitio",
    "tags": "Etiquetas",
    "general": "General",
    "profile": "Perfil",
    "parameters": "Parámetros"
  },
  "company": {
    "title": "Empresa",
    "name": "Nombre",
    "nameError": "Por favor, agregue un nombre de empresa",
    "cancel": "Cancelar"
  },
  "bufferPolicy": {
    "orderPointValue": "Valor del punto de pedido",
    "constantMinimumTip": "Lo usaremos como valor fijo para todo el horizonte",
    "useLowerLimitMin": "Agregar límite inferior",
    "lowerLimitMin": "Valor del límite",
    "title": "Política de Buffer",
    "MTSFlowlityAITitle": "Política de Buffer de Suministro Predeterminada para la estrategia MTS Flowlity AI",
    "MTSMyMinTitle": "Política de Buffer de Suministro Predeterminada para la estrategia MTS Mi mínimo",
    "MTSROPTitle": "Política de Buffer de Suministro Predeterminada para la estrategia MTS Punto de Reorden",
    "MTSROPAITitle": "Política de Buffer de Suministro Predeterminada para la estrategia MTS Punto de Reorden + AI",
    "MTOtitle": "Política de Buffer de Suministro Predeterminada para la estrategia MTO",
    "subtitle": "Se aplicará a todos los productos con estas estrategias donde no se configure una política personalizada.",
    "none": "Ninguno",
    "time": "Tiempo",
    "stock": "Stock",
    "supplierDelay": "Retraso del Proveedor",
    "inputDisabledTooltip": "No hay retraso del proveedor porque no hay política aplicada",
    "errorEmpty": "El retraso del proveedor no puede estar vacío, debe ser de 1 día o más",
    "errorZero": "El retraso del proveedor no puede ser 0, debe ser de 1 día o más",
    "errorNegative": "El retraso del proveedor no puede ser negativo, debe ser de 1 día o más",
    "save": "Guardar",
    "discard": "Descartar cambios",
    "updateSuccess": "Política de Buffer actualizada",
    "updateError": "Ocurrió un error al actualizar la Política de Buffer",
    "saveTitle": "¿Guardar cambios?",
    "saveDescription": "Los cambios se aplicarán a los productos que utilizan la Política de Buffer predeterminada",
    "saveConfirmButton": "Guardar Cambios",
    "cancel": "Cancelar",
    "days": "días",
    "minFixedValue": "Mi nivel de Stock Mínimo"
  },
  "export": {
    "title": "Descargas",
    "desc": "Por favor, seleccione el archivo y formato que desea descargar",
    "salesOrders": "Órdenes de Venta",
    "purchaseOrders": "Órdenes de Compra",
    "planning": "Planificación",
    "demands": "Pronósticos de Demanda",
    "file": "Archivo(s)",
    "localDownload": "Descarga Local",
    "sftpSuccess": "Datos exportados con éxito",
    "exportError": "La exportación ha fallado con el siguiente error: ",
    "period": "Período",
    "site": "Sitio(s)",
    "timebucket": "Tipo de período",
    "units": "Unidades",
    "week": "Semana",
    "month": "Mes",
    "primaryUnit": "Primaria",
    "secondaryUnit": "Secundaria"
  },
  "closures": {
    "add": "Agregar cierre",
    "back": "atrás",
    "cancel": "Cancelar",
    "createSuccess": "Cierre creado con éxito",
    "createTitle": "Crear cierre de sitio",
    "delete": "Eliminar",
    "deleteDescription": "Esta acción es permanente",
    "deleteQuestion": "Esto eliminará permanentemente este cierre. ¿Continuar?",
    "deleteSuccess": "Cierre eliminado con éxito",
    "editTitle": "Editar cierre",
    "endDate": "Fecha de finalización",
    "closureDetails": "Detalles del cierre",
    "name": "Nombre",
    "noData": "No se encontró cierre",
    "notFound": "Cierre no encontrado",
    "percentageOff": "Porcentaje de descuento",
    "requiredError": "Este campo es obligatorio",
    "requiredNumberError": "Por favor, proporcione un número válido",
    "save": "Guardar",
    "search": "Buscar por nombre",
    "startDate": "Fecha de inicio",
    "title": "Todos los cierres de sitio",
    "updateSuccess": "Cierre actualizado con éxito"
  },
  "products": {
    "add": "Agregar Producto",
    "addPromotion": "Agregar Promoción",
    "addSecondaryUnit": "Agregar Unidad Secundaria",
    "addSimilarProducts": "Agregar Producto Similar",
    "addSuppliers": "Agregar proveedores",
    "addTag": "Agregar Etiqueta",
    "ajustedBy": "Ajustado por",
    "averageDelays": " cobertura de retraso",
    "source": "Fuente",
    "bufferLeadTimeTip": "Órdenes realizadas con anticipación a los requisitos",
    "bufferLevel": "Nivel de Buffer",
    "bufferLevelTip": "El Nivel de Buffer de Flowlity impactará su nivel de stock mínimo. Cuanto más bajo sea el nivel de buffer, más bajo será su nivel mínimo. Cuanto más alto sea el nivel de buffer, más alto será su nivel mínimo.",
    "bufferPolicy": "Política de Buffer",
    "bufferStockTip": "El stock mínimo tiene en cuenta los retrasos de los proveedores",
    "cancel": "Cancelar",
    "cases": "casos",
    "coeff": "Coef",
    "coverPercentageOfRisk": "para cubrir {count}% del riesgo",
    "coverage": "Cobertura",
    "computeNewMin": "Calcular nuevo mínimo",
    "computeNewMinTooltip": "Disponible solo en vista de planificación",
    "days": "día | día | días",
    "defaultSimilarSelect": "Ninguno",
    "defaultUnit": "Unidad predeterminada",
    "delayForecastingDescription": "Valores recomendados por Flowlity",
    "delete": "Eliminar",
    "deleteDescription": "Será redirigido automáticamente a la página de lista después de la eliminación.",
    "deleteProduct": "Eliminar Producto",
    "deleteTitle": "¿Realmente desea eliminar este producto?",
    "demandBasedBuffer": "Basado en demanda - Flowlity AI",
    "demandBasedBufferDescription": "Las recomendaciones de suministro se basarán en el pronóstico de Flowlity y el nivel de stock. El nivel de stock de buffer se genera en función de la demanda de Flowlity y las incertidumbres de suministro",
    "description": "Descripción",
    "discard": "Descartar",
    "noPermissionToEdit": "No tiene permiso para editar esta estrategia de inventario",
    "discardCancelButton": "Volver a Editar",
    "discardConfirmButton": "Descartar cambios",
    "discardDescription": "Perderá todos los cambios realizados en la pestaña actual",
    "discardTitle": "¿Descartar cambios?",
    "displayUnit": "Unidad de visualización",
    "editTitle": "Editar Detalles del Producto",
    "emptyDelayForecasting": "No hay recomendaciones de retraso, necesitamos más datos para generar",
    "emptyError": "No puede estar vacío",
    "enter": "Ingresar",
    "events": "Eventos",
    "externalIntegration": "Estos datos están integrados desde un sistema externo",
    "selectedProductsUseDifferentUnits": "Los productos seleccionados usan diferentes unidades",
    "inventoryStrategies": "Estrategia de Inventario",
    "myStockMin": "Flowlity AI + Mi mínimo",
    "myStockMinDescription": "Las recomendaciones de suministro se basan en el pronóstico de Flowlity AI y el nivel de stock. El nivel de stock de buffer es definido por el usuario",
    "fullHorizon": "Horizonte completo",
    "manualPeriod": "Período manual",
    "manualHorizon": "Horizonte manual",
    "mts": "Hacer para stock",
    "mtsDescription": "Las recomendaciones de suministro se basan en el nivel de stock de buffer",
    "mto": "Hacer para pedido",
    "mtoDescription": "Las recomendaciones de suministro se basan en la demanda firme. No hay stock de buffer",
    "flowlityAi": "Flowlity AI",
    "internalCustomer": "Cliente Interno",
    "internalCustomerTooltip": "Los datos de pronóstico de clientes internos se excluyen del cálculo del pronóstico del producto. Solo se utiliza la demanda firme de clientes internos para el cálculo.",
    "fixedLotSize": "Tamaño de lote fijo",
    "info": "Información",
    "infoRatio": "Ratio",
    "initialValue": "Valor Inicial - {initialBufferLevel}",
    "exactValue": "Valor exacto",
    "inventoryStrategy": "Estrategia de Inventario",
    "lockedByTacticalChanges": "Bloqueado por cambios tácticos",
    "leadTime": "Tiempo de Entrega",
    "manualStockCoverage": "Cobertura de Stock Manual",
    "moq": "Cantidad Mínima de Pedido",
    "myMaxValue": "Mi valor máximo",
    "myStockMax": "Mi Stock Máximo",
    "myStockMaxTooltip": "Se activa una alerta cuando el nivel de stock está por encima de Mi valor máximo de stock. Mi stock máximo no actúa como una restricción en el cálculo del plan de suministro",
    "name": "Nombre",
    "next6mAvgDays": "Promedio de los próximos 6m.",
    "next6mAvgDaysSimulated": "Promedio simulado de los próximos 6m.",
    "next6mAvgQtySimulated": "Promedio simulado de los próximos 6m.",
    "noData": "No se encontró producto",
    "none": "Ninguno",
    "noPolicy": "Sin Política",
    "price": "Precio",
    "productionEnd": "Fin de Producción",
    "productionStart": "Inicio de Producción",
    "productLifeCycle": "Ciclo de vida del producto",
    "promotions": "Promociones",
    "quantity": "Cantidad",
    "ratio": "Ratio (0-100%)",
    "remove": "Eliminar",
    "reorderPoint": "Punto de Reorden",
    "reorderPointDescription": "Las recomendaciones de suministro se activan cuando el stock alcanza o cae por debajo del nivel del Punto de Reorden. Solo se considera la demanda firme dentro del horizonte ROP (sin pronóstico)",
    "ropAI": "Punto de Reorden + AI",
    "ropAIDescription": "La AI genera el nivel ROP dinámicamente en función de los tiempos de entrega y consumos pasados",
    "salesEnd": "Fin de Vida",
    "salesEndTooltip": "Estos datos están integrados desde un sistema externo",
    "salesStart": "Inicio de Ventas",
    "save": "Guardar",
    "apply": "Aplicar",
    "changesApplied": "Cambios de estrategia de inventario aplicados",
    "incomingChanges": "Cambios entrantes:",
    "inventoryStrategyWasChanged": "La estrategia de inventario fue cambiada.",
    "saveBufferPolicyDescription": "Verá los cambios después del próximo cálculo.",
    "saveBufferPolicyDescriptionWithOptimizeBtn": "Verá los cambios después del próximo cálculo. También puede usar el botón \"Optimizar\" en Planificación para recalcular el mínimo y las recomendaciones de suministro inmediatamente.",
    "saveBufferPolicyTitle": "¿Guardar cambios de la configuración de la Estrategia de Inventario?",
    "saveChanges": "Guardar Cambios",
    "search": "Buscar por nombre",
    "secondaryUnit": "Unidad secundaria",
    "selectADate": "Seleccione una fecha",
    "shelfLife": "Vida Útil",
    "similarProducts": "Productos Similares",
    "similarProductsSubTitle": "Agregar productos similares anteriores",
    "showNewMin": "Mostrar nuevo mínimo",
    "sourceCustomer": "Cliente Fuente",
    "sourceProduct": "Producto Fuente",
    "stock": "Stock",
    "stockCoverageTitle": "Cobertura de stock, días",
    "stockMinCoverage": "Cobertura de Stock Mínimo",
    "stockPolicyHelpContent": "El stock mínimo se ajusta para tener en cuenta esos retrasos",
    "atLeastOneProductCannotUseThisPolicy": "Al menos 1 producto seleccionado no puede usar esta política",
    "storageSites": "Sitios de almacenamiento",
    "strategy": "Estrategia",
    "suppliers": "Proveedores",
    "supplyBufferPolicy": "Política de buffer de suministro",
    "supplyBufferPolicyNoDefaultStockTip": "La Política de Buffer de Suministro Predeterminada es “Stock” pero no se puede usar con la configuración actual",
    "supplyStrategy": "Estrategia de Suministro",
    "tagGrouper": "Etiquetas para agrupación de pedidos",
    "tags": "Etiquetas",
    "targetCustomer": "Cliente Objetivo",
    "time": "Tiempo",
    "timePolicyHelpContent": "Fechas de entrega en pedidos realizados con anticipación a los requisitos de planificación.",
    "title": "Todos los productos",
    "units": "Unidades",
    "useDefaultBufferPolicy": "Usar política predeterminada",
    "value": "Valor",
    "withCustomer": "x Cliente",
    "mixed": "Mixto",
    "values": "Valores",
    "allSuppliers": "Todos los proveedores",
    "perSupplier": "Por proveedor",
    "delayCoverage": "Cobertura de retraso",
    "productUseDefaultPolicy": "El producto usa política predeterminada",
    "bom": "Bom",
    "bomParents": "Productos padres",
    "bomChildren": "Productos hijos",
    "bomSubTitle": "En productos padres mostramos valores de la referencia actual como un componente en su lista de materiales.",
    "openPlanning": "Abrir planificación",
    "bomParentsQuantity": "Cantidad Bom (Ref. actual)",
    "bomParentsOffset": "Desplazamiento BOM (Ref. actual)",
    "bomChildrenQuantity": "Cantidad Bom",
    "bomChildrenOffset": "Desplazamiento BOM"
  },
  "promotions": {
    "add": "Agregar Promoción",
    "addButton": "Crear",
    "addTitle": "Crear una nueva promoción",
    "back": "atrás",
    "cancel": "Cancelar",
    "coefficient": "Impacto en ventas en (%)",
    "create": "Crear",
    "createSuccess": "Promoción creada con éxito",
    "delete": "Eliminar",
    "deleteDescription": "Esta acción es permanente",
    "deleteQuestion": "Esto eliminará permanentemente esta promoción. ¿Continuar?",
    "deleteSuccess": "Promoción eliminada con éxito",
    "description": "Descripción",
    "editTitle": "Editar promoción",
    "editButton": "Guardar",
    "endDate": "Fecha de finalización",
    "name": "Nombre",
    "noData": "No se encontró promoción",
    "products": "Productos",
    "promotionDetails": "Detalles de la promoción",
    "requiredError": "Este campo es obligatorio",
    "requiredNumberError": "Por favor, proporcione un número válido",
    "search": "Buscar por nombre",
    "startDate": "Fecha de inicio",
    "title": "Todas las promociones",
    "updateSuccess": "Promoción actualizada con éxito"
  },
  "sites": {
    "title": "Todos los sitios",
    "noSite": "No se encontró sitio",
    "back": "todos los sitios",
    "editTitle": "Editar el sitio",
    "name": "Nombre",
    "address": "Dirección",
    "currency": "Moneda (EUR, USD, CAD, ...)",
    "save": "Guardar",
    "cancel": "Cancelar",
    "errorName": "Por favor, agregue un nombre de sitio",
    "errorAddress": "Por favor, agregue una dirección de sitio",
    "demandDistribution": "Distribución de la demanda a lo largo de la semana",
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "addClosure": "Agregar un nuevo tiempo de cierre",
    "showFutureDashboard": "Mostrar valores futuros en el panel de inventario",
    "inventoryDashboardTitle": "Propiedades del Panel de Inventario",
    "stockCompTitle": "Propiedades de cálculo de stock",
    "shelfLife": "Vida útil de los productos",
    "mustBeEq100": "Debe ser = 100%"
  },
  "suppliers": {
    "search": "Buscar por nombre",
    "add": "Agregar Proveedor",
    "addButton": "Crear",
    "address": "dirección (opcional)",
    "addTitle": "Crear un nuevo proveedor",
    "back": "todos los proveedores",
    "cancel": "Cancelar",
    "constraints": "Restricciones (opcional)",
    "days": "días",
    "editButton": "Guardar",
    "editTitle": "Editar el proveedor",
    "email": "Correo de contacto (opcional)",
    "errorExtId": "Por favor, agregue una referencia de proveedor",
    "errorName": "Por favor, agregue un nombre de proveedor",
    "externalId": "Ref",
    "fullTruckQte": "Cantidad de camión completo",
    "maxSuppliesByOrder": "Máximo de filas por pedido (solo exportación)",
    "minOrderQte": "Cantidad mínima de pedido",
    "name": "Nombre",
    "noSupplier": "No se encontró proveedor",
    "orderFrequency": "Frecuencia de pedido",
    "searchSupplier": "Buscar por nombre",
    "title": "Todos los proveedores",
    "unit": "unidad",
    "unitPlaceholder": "Seleccione unidad",
    "noAvailableUnitsMsg": "No hay unidades disponibles, debido a que no hay productos asignados al proveedor o no hay unidades asignadas a los productos.",
    "updateSuccess": "El proveedor ha sido actualizado con éxito",
    "createSuccess": "El proveedor ha sido creado con éxito"
  },
  "tags": {
    "add": "Crear etiqueta",
    "addTag": "Para etiquetar",
    "all": "Lista de productos",
    "back": "atrás",
    "cancel": "Cancelar",
    "create": "Crear",
    "createError": "Ocurrió un error durante la creación de la etiqueta",
    "createSuccess": "Etiqueta Creada",
    "delete": "Eliminar",
    "deleteDescription": "Esta etiqueta se usa en {count} productos",
    "deleteQuestion": "¿Eliminar etiqueta?",
    "deleteAction": "Eliminar etiqueta",
    "deleteSuccess": "Etiqueta \"{name}\" eliminada",
    "editTitle": "Editar etiqueta",
    "isTagGrouper": "Usado para agrupar pedidos",
    "isSimulated": "Usado para simulación",
    "name": "Nombre de la etiqueta",
    "noData": "No se encontró etiqueta",
    "nonAdminWarningText": "Por favor, contacte a su administrador para cualquier modificación",
    "notTaggedProducts": "productos disponibles | producto disponible | productos disponibles",
    "products": "Productos",
    "remove": "Desetiquetar",
    "requiredError": "Este campo es obligatorio",
    "save": "Guardar",
    "search": "Buscar",
    "searchByProduct": "Buscar por nombre de producto",
    "selectAll": "Seleccionar todo",
    "deselectAll": "Deseleccionar todo",
    "tagDetails": "Detalles de la etiqueta",
    "productsSelected": "1 producto seleccionado | {count} productos seleccionados",
    "title": "Todas las Etiquetas",
    "updateError": "Ocurrió un error al actualizar la etiqueta",
    "updateSuccess": "Etiqueta Actualizada",
    "tagType": "Tipo",
    "integrated": "Integrado",
    "createdInFlowlity": "Creado en Flowlity",
    "integratedDescription": "Integrado desde un sistema externo",
    "discardCancelButton": "Volver a Editar",
    "discardConfirmButton": "Descartar cambios",
    "discardText": "Perderá todos los cambios realizados durante la edición",
    "discardTitle": "¿Descartar cambios?"
  },
  "users": {
    "active": "Usuario Activo",
    "back": "todos los usuarios",
    "cancel": "Cancelar",
    "editTitle": "Editar el usuario",
    "profile": "Perfil",
    "email": "Correo Electrónico",
    "errorEmail": "Por favor, agregue un correo electrónico de usuario",
    "errorEmailPattern": "Por favor, ingrese un correo electrónico adecuado",
    "errorName": "Por favor, agregue un nombre de usuario",
    "job": "Título del Trabajo (opcional)",
    "name": "Nombre",
    "notFound": "Usuario no encontrado",
    "noUser": "No se encontró usuario",
    "phone": "Teléfono de Oficina (opcional)",
    "sameSiteError": "No puede eliminarse del Sitio que se está utilizando actualmente en la aplicación",
    "title": "Todos los usuarios",
    "userInfo": "Información",
    "scope": "Alcance",
    "suppliersScope": "Alcance de Proveedores",
    "updateSuccess": "Cambios guardados",
    "updateError": "Ocurrió un error al actualizar el perfil",
    "save": "Guardar",
    "discard": "Descartar cambios"
  }
}
