import dayjs, { Dayjs, QUnitType } from "dayjs";
import { i18n } from "@/i18n/i18n@next";
import { ComputedRef, computed } from "vue";

const { t } = i18n.global;

export type FlowlityDateUnit = "year" | "quarter" | "month" | "week" | "day";

type PeriodTypes = Record<Uppercase<FlowlityDateUnit>, FlowlityDateUnit>;

export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const READABLE_DATE_FORMAT = "D MMM, YYYY";
export const READABLE_DATE_W_WEEKDAY_FORMAT = "ddd D MMM, YYYY";

export const gridDateFormats: ComputedRef<Record<FlowlityDateUnit, string>> = computed(() => ({
  day: "MMM D",
  // [] is for escaping ([W] = just a string, W = template for a week number)
  week: `[${t("weekAbbreviation")}]` + "W",
  month: "MMM",
  quarter: "[Q]Q",
  year: "YYYY",
}));

export const now = () => dayjs();

export const PERIOD_TYPES: PeriodTypes = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
};

export interface IPeriod<T extends Dayjs | string = Dayjs> {
  start: T;
  end: T;
}

export type PeriodBoundary = "start" | "end";

export const generateDays = (
  startDate: string | Dayjs,
  endDate: string | Dayjs,
): Dayjs[] => {
  const days: Dayjs[] = [];
  let iteratorTimestamp: Dayjs = dayjs(startDate, { utc: true }).utc(true).startOf("day");
  const endDateUTC: Dayjs = dayjs(endDate).utc(true).endOf("day");

  while (iteratorTimestamp.isSameOrBefore(endDateUTC)) {
    days.push(iteratorTimestamp);
    iteratorTimestamp = iteratorTimestamp.add(1, "day");
  }

  return days;
};

export const generatePeriods = (
  fromDate: string | Dayjs,
  toDate: string | Dayjs,
  timebucket: FlowlityDateUnit,
  // extracts D-1 to the dedicated period (for planning only)
  extractYesterdayToDedicatedPeriod: boolean = false,
  // for unit tests to mock current date
  mockedToday?: Dayjs,
): IPeriod[] => {
  const periods: IPeriod[] = [];
  const finishDate = dayjs(toDate, { utc: true }).utc(true).endOf(timebucket);

  // moving gaps of iterator
  let iteratorStartOfPeriod = dayjs(fromDate, { utc: true }).utc(true).startOf(timebucket);
  let iteratorEndOfPeriod = iteratorStartOfPeriod.endOf(timebucket);

  // for computing D-1
  const today = mockedToday ?? dayjs().utc(true);
  const startOfYesterday = today.subtract(1, "day").startOf("day");
  // it does optimization: to not call "isSame" every time if "D-1" is already added to the array
  let isYesterdayAdded: boolean = false;

  while (iteratorStartOfPeriod.isBefore(finishDate, timebucket as QUnitType)
    || iteratorStartOfPeriod.isSame(finishDate, timebucket as QUnitType)) {
    const shouldSplitThisPeriod: boolean = extractYesterdayToDedicatedPeriod
      && !isYesterdayAdded
      && startOfYesterday.isSame(iteratorStartOfPeriod, timebucket as QUnitType);

    if (shouldSplitThisPeriod) {
      // If we need to extract D-1 as a separated period:
      // and the iterator is in the same week/month/quarter/year, we should split this period by 2 or 3 periods.
      // Why 2 or 3?
      // Example:
      // 1️⃣ Case 1: D-1 is Monday, we should add {"Mon 00:00", "Mon 23:59"} + {"Tue 00:00", "Sun 23:59"} → 2 periods
      // 2️⃣ Case 2: D-1 is Wednesday, we should add {"Mon 00:00", "Tue 23:59"} + {"Wed 00:00", "Wed 23:59"} + {"Thu 00:00", "Sun 23:59"} → 3 periods
      // 3️⃣ Case 3: D-1 is Sunday, we should add {"Mon 00:00", "Sat 23:59"} + {"Sun 00:00", "Sun 23:59"} → 2 periods
      // The same cases are applied for other periods as well (for month/quarter/year)
      periods.push(
        ...startOfYesterday.isSame(iteratorStartOfPeriod, "day")
          ? []
          : [{
            // e.g. from "Monday" up to "yesterday minus 1 day"
              start: iteratorStartOfPeriod,
              end: startOfYesterday.subtract(1, "day").endOf("day"),
            }],
        {
          // D-1 (yesterday)
          start: startOfYesterday,
          end: startOfYesterday.endOf("day"),
        },
        ...startOfYesterday.isSame(iteratorEndOfPeriod, "day")
          ? []
          : [{
              // e.g. from "yesterday plus 1 day" up to "Sunday"
              start: startOfYesterday.add(1, "day"),
              end: iteratorStartOfPeriod.endOf(timebucket),
            }],
      );

      // it does optimization: to not call "isSame" every time if "D-1" is already added to the array
      isYesterdayAdded = true;
    } else {
      // for all other cases (tactical/demand/capacity/planning for the rest periods)
      periods.push({
        start: iteratorStartOfPeriod,
        end: iteratorEndOfPeriod,
      });
    }

    iteratorStartOfPeriod = iteratorStartOfPeriod.add(1, timebucket as QUnitType);
    iteratorEndOfPeriod = iteratorStartOfPeriod.endOf(timebucket as QUnitType);
  }

  return periods;
};

export const isYearThreshold = (timestamp: Dayjs, periodType: FlowlityDateUnit): boolean => {
  const endOfPeriod: Dayjs = timestamp.endOf(periodType);
  const startOfYearOfEndOfPeriod: Dayjs = endOfPeriod.startOf("year");
  const diffInBuckets: number = endOfPeriod.diff(startOfYearOfEndOfPeriod, periodType);
  return diffInBuckets === 0;
};

export const toRelativeDate = (dayjsDate: Dayjs): string => {
  if (dayjsDate.isYesterday()) {
    return t("yesterday");
  } else if (dayjsDate.isToday()) {
    return t("today");
  } else {
    return dayjsDate.format("MMM D");
  }
};

export const toRelativeDateTime = (dayjsDate: Dayjs): string => {
  const relativeDate = toRelativeDate(dayjsDate);
  return `${dayjsDate.format("HH:mm")}, ${relativeDate}`;
};

export interface IDaterangeChunk {
  startDate: Dayjs;
  endDate: Dayjs;
}

export const buildDaterangeChunks = (startDate: Dayjs, endDate: Dayjs, chunkSizeDays: number): IDaterangeChunk[] => {
  const chunks: IDaterangeChunk[] = [];
  let chunkStart: Dayjs = startDate.clone();

  while (chunkStart.isSameOrBefore(endDate, "day")) {
    let chunkEndDate = chunkStart.add(chunkSizeDays, "days");

    if (chunkEndDate.isAfter(endDate, "day")) {
      chunkEndDate = endDate.clone();
    }

    chunks.push({
      startDate: chunkStart,
      endDate: chunkEndDate,
    });

    chunkStart = chunkEndDate.add(1, "day");
  }

  return chunks;
};

// site.configSiteGlobal.forecastGranularity/planningGranularity are in the format of "weekly" | "monthly"
export const SITEGLOBAL_TO_DATEUNIT_MAPPING = {
  weekly: "week",
  monthly: "month",
} as const;

export const datepickerDisplayedDate = (
  date: Dayjs | null,
  timebucket: FlowlityDateUnit,
): string => {
  if (!date) return "";

  const format = {
    [PERIOD_TYPES.DAY]: READABLE_DATE_FORMAT,
    [PERIOD_TYPES.WEEK]: `[${t("weekAbbreviation")}]W, GGGG`,
    [PERIOD_TYPES.MONTH]: "MMM, YYYY",
  }[timebucket];

  return date.utc(true).format(format);
};

export const computeDaysFromToday = (dateToDiffFromToday: Dayjs, periodBoundary: PeriodBoundary): number => {
  const currentDate = dayjs().utc(true);
  let daysFromToday = 0;

  // we should either take todays start or end of day to compute the diff correctly
  // because the dateToDiffFromToday is also based on start or end of day depending on the boundary
  if (periodBoundary === "start") {
    daysFromToday = dateToDiffFromToday.diff(currentDate.startOf("day"), "day");
  } else if (periodBoundary === "end") {
    daysFromToday = dateToDiffFromToday.diff(currentDate.endOf("day"), "day");
  }

  return daysFromToday;
};
