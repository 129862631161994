// @ts-nocheck
import tinycolor from "tinycolor2";
import resolveConfig from "tailwindcss/resolveConfig";
// eslint-disable-next-line import/extensions
import tailwindConfig from "../../tailwind.config.js";
interface IColor {
  [key: string]: string;
}

export interface IThemeColors {
  "primary": IColor;
  "ui": IColor;
  "green": IColor;
  "orange": IColor;
  "red": IColor;
  "purple": IColor;
  "additional-1": "#FF7557";
  "additional-2": "#E34F2F";
  "additional-3": "#B2586E";
  "additional-4": "#CA80DC";
  "additional-5": "#7856FF";
  "additional-6": "#0D7EA0";
  "additional-7": "#72BEF4";
  "additional-8": "#5BB7AF";
  "additional-9": "#3BA974";
  "additional-9-2": "#93D0B3";
  "additional-9-3": "#BAE1CE";
  "additional-10": "#F8BC3B";
  // TODO: refactor or remove colors above after new design mockups will be implemented
  "fl-green-3": "#0BF293";
  "fl-blue": "#38BFF0";
  "fl-dark-blue": "#172945";
}

type themeColor = IThemeColors;

const fullConfig = resolveConfig(tailwindConfig);
export const themeColors: themeColor = fullConfig.theme.colors;

export const hexToRgba = (hexString, alpha = 1) => {
  const color = tinycolor(hexString);
  color.setAlpha(alpha);
  return color.toRgbString();
};
