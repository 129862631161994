{
  "en": {
    "changesValidated": "Changes under validation",
    "productsWithChanges": "0 products with changes | 1 product with changes | {count} products with changes",
    "editionMode": "Editing mode",
    "editionModeDescription": "Make inventory strategy changes in mass for all products",
    "simulationMode": "Simulation mode",
    "simulationModeDescription": "Make buffer level changes in mass & simulate them to see new projection of the available KPIs",
    "tooltipCantSwitchModeWhileEditingBufferLevel": "You can't switch to editing mode while editing buffer levels",
    "tooltipCantSwitchModeWhileOnGoingSimulation": "You can't switch to editing mode while a simulation is ongoing. You need to validate your scenario first and then cascade your changes to Flowlity.",
    "tooltipCantSwitchModeIfNotCascaded": "You can't switch to editing mode while a simulation is ongoing. You need to cascade your changes to Flowlity first.",
    "fullSimulationExplanationsModalTitle": "Full simulation mode",
    "upsellFromEditionToSimulationModal": {
      "title": "Get the full power of Simulation!",
      "simuDesc1": "Generate simulations on different scenarios",
      "simuDesc2": "Adjust your inventory strategies and measure the impact straight away on your KPI’s",
      "simuDesc3": "Chose the scenario that will maximize your chances to reach your objectives",
      "simuDesc4": "1 to multiple years of simulations to balance stock value, coverage and performance",
      "buttonText": "Get simulation mode",
      "topupSubject": "{companyName}: get simulation mode for Tactical {siteName}",
      "topupBody": "Please give access to the simulation mode for Tactical for {siteName} / {companyName} (Site ID: {siteId}); email: {email}"
    },
    "upsellFromLiteToFullModal": {
      "buttonTrigger": "Get the full power of Simulation",
      "current": "Current",
      "dontShowAgain": "Don’t show this message again",
      "fullButtonText": "Get full simulation",
      "fullDesc1": "Best simulation precision: 50 scenarios adapted to your lead times",
      "fullDesc2": "All constraints available",
      "fullDesc3": "All inventory strategies available",
      "fullDesc4": "Simulation adapted to your needs",
      "fullVersion": "Full simulation",
      "liteButtonText": "Continue with lite version",
      "liteDesc1": "Simulation precision: limited scenarios monthly",
      "liteDesc2": "Basic constraints",
      "liteDesc3": "Only Flowlity AI strategy",
      "liteDesc4": "Simulation updated every quarter",
      "liteVersion": "Lite simulation",
      "title": "Get the full power of Simulation!",
      "topupBody": "Please give access to full simulation for Tactical for {siteName} / {companyName} (Site ID: {siteId}); email: {email}",
      "topupSubject": "{companyName}: get full simulation for Tactical {siteName}",
      "ZDFBannerText": "Please be aware that in the Lite simulation mode, the “Zero Stock Days“ metric is estimated and may lack precision. For detailed and accurate calculations, consider upgrading to our",
      "ZDFBannerLink": "Full simulation mode"
    },
    "chart": {
      "demand": "Demand plan",
      "stockCoverage": "Stock Coverage",
      "stockLevel": "Inventory Level",
      "supply": "Supply Plan",
      "zeroStockDay": "Zero Stock Days",
      "noTags": "No Tags Found",
      "actualSimulation": "Actual simulation",
      "objective": "Objective",
      "newSimulation": "New simulation",
      "value": "Value, Euro",
      "defaultUnit": "Quantity, Default unit",
      "secondaryUnit": "Quantity, Secondary unit",
      "days": "Days",
      "dayUnit": "d",
      "percentage": "%, Percentage",
      "default": "Default",
      "fullSiteScope": "Full Site Scope",
      "chartOnly": "Graph",
      "chartAndTable": "Graph and Table",
      "baseline": "Perfect projection",
      "perfectProjectionTooltipText": "Perfect projection is the projection of your KPI with current inventory strategy as if all happens according to current plan (Forecast 100% accurate, No supplier delays etc.)",
      "actualSimulationTooltipText": "Actual simulation means that its projection is a simulation with the current inventory strategy parameters",
      "newSimulationTooltipText": "New simulation means that its projection is a simulation with the new inventory strategy parameters"
    },
    "columnsSettings": {
      "all": "All",
      "avgStockMinQtyNext6M": "Stock Min (qty)",
      "avgStockMinCoverageNext6M": "Stock Min (days)",
      "customers": "Customers",
      "leadTime": "Lead Time",
      "defaultUnitName": "Default Unit",
      "endOfLife": "End of Life",
      "events": "Events",
      "promotions": "Promotions",
      "similarProducts": "Similar Products",
      "finalForecast": "Final Forecast",
      "firmDemand": "Firm Demand",
      "firmOrders": "Firm Orders",
      "general": "General",
      "hidden": "Hidden",
      "demand": "Demand",
      "inventory": "Inventory",
      "pastDemand": "Past Demand",
      "productName": "Product Name",
      "reference": "Reference",
      "resetModalCancelButton": "Cancel",
      "resetModalConfirmButton": "Back to Default",
      "resetModalText": "You will lose your current active columns and order",
      "resetModalTitle": "Back to Default Columns Order?",
      "bufferLevel": "Buffer Level %",
      "shelfLife": "Shelf Life",
      "shown": "Shown",
      "supplier": "Suppliers",
      "supplyBufferPolicy": "Supply Buffer Policy",
      "title": "Columns",
      "stockReduction": "Stock Change %",
      "zeroStockDays": "Zero Stock Days",
      "stockCoverage": "Stock Coverage",
      "simulated": "simulated",
      "nextOneYearAvgSimulated": "average next year simulated",
      "tabSimulated": "Simulated",
      "tags": "Tags",
      "stockChangeTooltip": "Change in % of the stock level compared to baseline",
      "resetOrder": "Reset Order",
      "forecastModelDemandType": "Demand Type"
    },
    "objectives": {
      "addScope": "Add Scope",
      "demandPlan": "Demand Plan",
      "discardConfirmButton": "Discard changes",
      "discardDescription": "Your will loose all changes that was made on current tab",
      "discardTitle": "Discard changes ?",
      "discardCancelButton": "Back to Editing",
      "editTitle": "Edit Objectives",
      "fullSite": "Full Site",
      "headerTitle": "Objectives",
      "inventoryLevel": "Inventory Level",
      "stockCoverage": "Stock Coverage",
      "supplyPlan": "Supply Plan",
      "tag": "Tag",
      "zeroStockDays": "Zero Stock Days"
    },
    "list": {
      "headers": {
        "bufferLevel": "Buffer Level %",
        "stockReduction": "Stock Change %",
        "zeroStockDays": "Zero Stock Days",
        "stockCoverage": "Stock Coverage",
        "simulated": "simulated",
        "nextOneYearAvgSimulated": "next 1Y avg. simulated",
        "tags": "Tags"
      },
      "filters": {
        "clear": "Clear",
        "NoTags": "No Tags Found",
        "Tags": "Tags | {name} | {count} Tags",
        "NoSuppliers": "No Suppliers",
        "Suppliers": "Suppliers | {name} | {count} Suppliers",
        "suppliersExcluded": "Suppliers | All except {name} | All except {count} Suppliers",
        "bufferLevel": "Buffer Level %",
        "ZeroStockDays": "Zero Stock Days",
        "ZeroStockDaysShort": "ZSD",
        "lastMonth": "Last month",
        "last6M": "Last 6 months average",
        "StockCoverage": "Stock Coverage",
        "StockCoverageShort": "Stock Cover.",
        "current": "Current Month",
        "next6Mavg": "next 6M avg.",
        "next1Yavg": "Next 1 year average",
        "days": "days",
        "upTo": "up to",
        "from": "from",
        "EditedOnly": "Edited Only",
        "deselectAll": "Deselect all",
        "minDays": "min 0 day | min 1 day | min {count} days",
        "maxDays": "max 0 day | max 1 day | max {count} days",
        "withoutSuppliersMode": "Without supplier"
      },
      "NTagsSelected": "{count} Tag Selected | {count} Tags Selected",
      "editBufferLevel": "Edit buffer level",
      "editInventoryStrategy": "Edit inventory strategy",
      "Mixed": "Mixed",
      "DeselectAllPage": "Deselect all on this page",
      "ClearSelection": "Clear Selection",
      "tags": "Tags | Tag | Tags",
      "title": "Products",
      "initialServiceLevel": "Initial Value - {value}",
      "onGoingChangesMsg": "A change is already on going on this parameter: {value}",
      "rowIsDisabledTooltip": "This product is not using Flowlity AI for its inventory strategy, therefore, buffer level is not available for editing"
    },
    "editing": {
      "discardChanges": "Discard changes",
      "applyToAll": "Apply to all Buffer Level:",
      "exactValue": "Exact Value",
      "adjustBy": "Adjust By",
      "simulate": "Simulate",
      "save": "Save",
      "mixed": "Mixed",
      "discardText": "You will loose all changes that was made during editing",
      "discardCancelButton": "Back to Editing",
      "discardConfirmButton": "Discard changes",
      "discardTitle": "Discard changes ?"
    },
    "validation": {
      "validate": "Validate",
      "validated": "Validated",
      "discardValidation": "Discard Validation",
      "discardSimulation": "Delete Simulated Data",
      "confirmationTitleValidation": "Validate Scenario?",
      "confirmationDescriptionValidation": "After validation, modification of Buffer Level will be blocked.",
      "discardConfirmationValidation": "Discard Validation",
      "discardConfirmationTitleValidation": "Discard Validation?",
      "discardConfirmationDescriptionValidation": "Your will loose validation status and possibility to cascade to Flowlity",
      "discardConfirmationSimulation": "Delete Simulated Data",
      "discardConfirmationTitleSimulation": "Discard Simulation?",
      "discardConfirmationDescriptionSimulation": "You will lose all simulated data in products"
    },
    "cascade": {
      "confirmationTitle": "Apply scenario to Flowlity?",
      "confirmationDescription": "Modifications will be taken into account at next calculation of supply plan.",
      "confirm": "Confrim",
      "apply": "Apply",
      "applyExplanation": "Apply new configuration to Flowlity",
      "successNotifTitle": "All Simulated changes applied to Flowlity",
      "successNotifDescription": "All simulated values will become active values of products"
    },
    "inventoryStrategy": {
      "strategyType": "Strategy type",
      "bufferPolicy": "Buffer policy",
      "bufferLevel": "Buffer level",
      "rop": "Reorder point value",
      "myStockMin": "My stock min",
      "myStockMax": "My stock max",
      "changedMessage": "Inventory strategy changes applied",
      "title": {
        "strategyType": "Edit strategy type",
        "bufferPolicy": "Edit buffer policy",
        "bufferLevel": "Edit buffer level",
        "rop": "Edit reorder point value",
        "stockMin": "Edit my stock min",
        "stockMax": "Edit my stock max"
      }
    },
    "waitingNextCalculation": "Waiting for next calculation"
  },
  "fr": {
    "changesValidated": "Changements en validation",
    "productsWithChanges": "0 produit avec des changements | 1 produit avec des changements | {count} produits avec des changements",
    "editionMode": "Mode édition",
    "editionModeDescription": "Pour éditer en masse la stratégie de stock de tous les produits",
    "simulationMode": "Mode simulation",
    "simulationModeDescription": "Pour éditer en masse les niveaux de stock de sécurité et simuler des projections sur différents KPIs",
    "tooltipCantSwitchModeWhileEditingBufferLevel": "Vous ne pouvez passer en mode édition lorsque vous modifiez des niveaux de stock de sécurité",
    "tooltipCantSwitchModeWhileOnGoingSimulation": "Vous ne pouvez passer en mode édition lorsque une simulation est en cours. Vous devez d'abord valider le scénario puis cascader vos modifications dans Flowlity.",
    "tooltipCantSwitchModeIfNotCascaded": "Vous ne pouvez passer en mode édition lorsque une simulation est en cours. Vous devez d'abord cascader vos modifications dans Flowlity.",
    "fullSimulationExplanationsModalTitle": "Mode simulation complète",
    "upsellFromEditionToSimulationModal": {
      "title": "Obtenez toute la puissance de la Simulation !",
      "simuDesc1": "Générez des simulations sur différents scénarios",
      "simuDesc2": "Ajustez vos stratégies de stock et mesurez l'impact immédiat sur vos indicateurs de performance",
      "simuDesc3": "Choisissez le scénario qui maximisera vos chances d'atteindre vos objectifs",
      "simuDesc4": "De une à plusieurs années de simulations pour équilibrer la valeur du stock, la couverture et la performance",
      "buttonText": "Obtenir le mode simulation",
      "topupSubject": "{companyName}: activer le mode simulation dans le module Tactical {siteName}",
      "topupBody": "Veuillez donner accès au mode simulation dans le module Tactical pour {siteName} / {companyName} (Site ID: {siteId}); email: {email}"
    },
    "upsellFromLiteToFullModal": {
      "buttonTrigger": "Obtenez toute la puissance de la Simulation",
      "current": "Actuelle",
      "dontShowAgain": "Ne plus montrer ce message",
      "fullButtonText": "Demander la simulation complète",
      "fullDesc1": "Simulation la plus précise: 50 scénarios adaptés à vos délais de livraison",
      "fullDesc2": "Toutes les contraintes disponibles",
      "fullDesc3": "Toutes les stratégies de stock disponibles",
      "fullDesc4": "Simulation adaptée à vos besoins",
      "fullVersion": "Simulation complète",
      "liteButtonText": "Continuer avec la version restreinte",
      "liteDesc1": "Précision de la simulation: scénarios à la maille mensuelle",
      "liteDesc2": "Contraintes basiques",
      "liteDesc3": "Stratégie Flowlity IA uniquement",
      "liteDesc4": "Simulation mise à jour trimestriellement",
      "liteVersion": "Simulation restreinte",
      "title": "Obtenez toute la puissance de la Simulation !",
      "topupBody": "Veuillez donner accès à la simulation complète dans le module Tactical pour {siteName} / {companyName} (Site ID: {siteId}); email: {email}",
      "topupSubject": "{companyName}: activer la simulation complète dans le module Tactical {siteName}",
      "ZDFBannerText": "En version restreinte, les valeurs simulées de “Jours de Stock à Zéro“ sont estimées et peuvent manquer de précision. Pour des calculs précis, envisagez de passer à la ",
      "ZDFBannerLink": "simulation complète"
    },
    "chart": {
      "demand": "Demande",
      "stockCoverage": "Couverture de stock",
      "stockLevel": "Valeur de stock",
      "supply": "Approvisionnement",
      "zeroStockDay": "Jours de stock à zéro",
      "noTags": "Aucun Tag Trouvé",
      "actualSimulation": "Simulation actuelle",
      "objective": "Objectif",
      "newSimulation": "Nouvelle simulation",
      "value": "Valeur, Euro",
      "defaultUnit": "Quantité, Unité par défaut",
      "secondaryUnit": "Quantité, Unité secondaire",
      "days": "Jours",
      "dayUnit": "j",
      "percentage": "%, Pourcentage",
      "Default": "Défaut",
      "fullSiteScope": "Site Entier",
      "chartOnly": "Graphe",
      "chartAndTable": "Graphe et Table",
      "baseline": "Projection parfaite",
      "perfectProjectionTooltipText": "La projection parfaite est la projection du KPI avec la stratégie d'inventaire actuelle conformément au plan actuel (prévision 100 % précise, aucun retard fournisseur, etc.)",
      "actualSimulationTooltipText": "La simulation actuelle est une simulation avec les paramètres de stratégie d'inventaire actuels",
      "newSimulationTooltipText": "La nouvelle simulation est une simulation avec les nouveaux paramètres de stratégie d'inventaire"
    },
    "columnsSettings": {
      "all": "Tout",
      "avgStockMinQtyNext6M": "Stock Min (qté)",
      "avgStockMinCoverageNext6M": "Stock Min (jours)",
      "customers": "Clients",
      "leadTime": "Délai de livraison",
      "defaultUnitName": "Unité par défaut",
      "endOfLife": "Fin de vie",
      "events": "Événements",
      "promotions": "Promotions",
      "similarProducts": "Produits similaires",
      "finalForecast": "Prédiction finale",
      "firmDemand": "Demande ferme",
      "firmOrders": "Commandes fermes",
      "general": "Général",
      "hidden": "Non-visible dans la table",
      "demand": "Demande",
      "inventory": "Stock",
      "pastDemand": "Demande passée",
      "productName": "Désignation produit",
      "reference": "Référence",
      "resetModalCancelButton": "Annuler",
      "resetModalConfirmButton": "Réinitialiser",
      "resetModalText": "Vous perdrez les paramètres actuels concernant l'ordre et l'activation des colonnes",
      "resetModalTitle": "Réinitialiser l'affichage par défaut ?",
      "bufferLevel": "Niv. de stock sécurité %",
      "shelfLife": "Durée de vie",
      "shown": "Visible dans la table",
      "supplier": "Fournisseurs",
      "supplyBufferPolicy": "Politique de buffer (Appros)",
      "title": "Colonnes",
      "stockReduction": "Changement de stock %",
      "zeroStockDays": "Jours de stock à zéro",
      "stockCoverage": "Couverture de stock",
      "simulated": "simulé",
      "nextOneYearAvgSimulated": "moy. futur 1 an simulé",
      "tabSimulated": "Simulés",
      "tags": "Tags",
      "stockChangeTooltip": "Variation en % du niveau de stock par rapport à la ligne de base",
      "resetOrder": "Réinitialiser l'ordre",
      "forecastModelDemandType": "Type de demande"
    },
    "objectives": {
      "addScope": "Ajouter un Scope",
      "demandPlan": "Demande",
      "discard": "Annuler les modifications",
      "discardDescription": "Vous ne pourrez plus récupérer les modifications effectuées.",
      "discardTitle": "Annuler les modifications ?",
      "discardConfirmButton": "Annuler les modifications",
      "discardCancelButton": "Continuer d'éditer",
      "editTitle": "Éditer les objectifs",
      "fullSite": "Site Entier",
      "headerTitle": "Objectifs",
      "inventoryLevel": "Valeur de stock",
      "stockCoverage": "Couverture de stock",
      "supplyPlan": "Approvisionnement",
      "tag": "Tag",
      "zeroStockDays": "Jours de stock à zéro"
    },
    "list": {
      "headers": {
        "bufferLevel": "Niv. de stock sécurité",
        "stockReduction": "Chgt. de stock %",
        "zeroStockDays": "Jours de stock à zéro",
        "stockCoverage": "Couverture de stock",
        "simulated": "simulé",
        "nextOneYearAvgSimulated": "moy. futur 1 an simulé",
        "tags": "Tags"
      },
      "filters": {
        "clear": "Réinitialiser",
        "NoTags": "Aucun Tag Trouvé",
        "Tags": "Tags | {name} | {count} Tags",
        "NoSuppliers": "Aucun Fournisseur Trouvé",
        "Suppliers": "Fournisseurs | {name} | {count} Fournisseurs",
        "suppliersExcluded": "Fournisseurs | Tous sauf {name} | Tous sauf {count} Fournisseurs",
        "bufferLevel": "Niv. de stock sécurité %",
        "ZeroStockDays": "Jours de stock à zéro",
        "ZeroStockDaysShort": "JSZ",
        "lastMonth": "Mois dernier",
        "last6M": "Moyenne 6 derniers mois",
        "StockCoverage": "Couverture de stock",
        "StockCoverageShort": "Couv. de stock",
        "current": "Mois actuel",
        "next6Mavg": "suivant 6M moyen",
        "next1Yavg": "Moyenne futur 1 an",
        "days": "jours",
        "upTo": "max",
        "from": "min",
        "EditedOnly": "Modifié uniquement",
        "deselectAll": "Tout désélectionner",
        "minDays": "min 0 jour | min 1 jour | min {count} jours",
        "maxDays": "max 0 jour | max 1 jour | max {count} jours",
        "withoutSuppliersMode": "Sans fournisseur"
      },
      "NTagsSelected": "0 tag sélectionné | 1 tag sélectionné | {count} tags sélectionnés",
      "DeselectAllPage": "Désélectionner toute la page",
      "editBufferLevel": "Modifier le niveau de stock de sécurité",
      "editInventoryStrategy": "Modifier la stratégie de stock",
      "Mixed": "Mixte",
      "ClearSelection": "Tout désélectionner",
      "tags": "tag | tag | tags",
      "initialServiceLevel": "Valeur Initiale - {value}",
      "title": "Produits",
      "onGoingChangesMsg": "Une modification est déjà en cours pour ce paramètre : {value}",
      "rowIsDisabledTooltip": "Ce produit n'utilise pas Flowlity IA pour sa stratégie de stocks, par conséquent, le niveau de stock sécurité n'est pas modifiable"
    },
    "editing": {
      "discardChanges": "Annuler",
      "applyToAll": "Appliquer à tous les niveaux de stock sécurité:",
      "exactValue": "Valeur exacte",
      "adjustBy": "Ajuster par",
      "simulate": "Simuler",
      "save": "Enregistrer",
      "mixed": "Mixte",
      "discardText": "Vous perdrez tous les modifications qui ont été faits pendant l'édition.",
      "discardCancelButton": "Continuer d'éditer",
      "discardConfirmButton": "Annuler les modifications",
      "discardTitle": "Annuler les modifications ?"
    },
    "validation": {
      "validate": "Valider",
      "validated": "Validé",
      "discardValidation": "Supprimer la validation",
      "discardSimulation": "Supprimer les données simulées",
      "confirmationTitleValidation": "Valider le scénario ?",
      "confirmationDescriptionValidation": "Après avoir validé, la modification des niveaux de stock sécurité sera bloquée.",
      "discardConfirmationValidation": "Supprimer la validation",
      "discardConfirmationTitleValidation": "Supprimer la validation?",
      "discardConfirmationDescriptionValidation": "Vous perdrez le statut de validation et la possibilité de cascader vers Flowlity",
      "discardConfirmationSimulation": "Supprimer la simulation",
      "discardConfirmationTitleSimulation": "Supprimer la simulation ?",
      "discardConfirmationDescriptionSimulation": "Vous perdrez toutes les données simulées dans les produits"
    },
    "cascade": {
      "confirmationTitle": "Appliquer le scénario dans Flowlity ?",
      "confirmationDescription": "Les modifications seront prises en compte lors du prochain calcul du plan d'approvisionnement.",
      "confirm": "Confirmer",
      "apply": "Appliquer",
      "applyExplanation": "Appliquer la nouvelle configuration à Flowlity",
      "successNotifTitle": "Toutes les modifications simulées sont appliquées sur Flowlity",
      "successNotifDescription": "Toutes les valeurs simulées deviendront les valeurs actives des produits"
    },
    "waitingNextCalculation": "En attente du prochain calcul"
  },
  "ru": {
    "changesValidated": "Изменения подлежат проверке",
    "productsWithChanges": "0 продуктов с изменениями | 1 продукт с изменениями | {count} продуктов с изменениями",
    "editionMode": "Режим редактирования",
    "editionModeDescription": "Внесите массовые изменения в стратегию инвентаризации для всех продуктов",
    "simulationMode": "Режим симуляции",
    "simulationModeDescription": "Внесите массовые изменения в уровень буфера и смоделируйте их, чтобы увидеть новую проекцию доступных KPI",
    "tooltipCantSwitchModeWhileEditingBufferLevel": "Вы не можете переключиться в режим редактирования во время редактирования уровня буфера",
    "tooltipCantSwitchModeWhileOnGoingSimulation": "Вы не можете переключиться в режим редактирования, пока идет симуляция. Сначала необходимо подтвердить ваш сценарий, а затем перенести изменения на Flowlity.",
    "tooltipCantSwitchModeIfNotCascaded": "Вы не можете переключиться в режим редактирования, пока идет симуляция. Сначала необходимо перенести изменения на Flowlity.",
    "fullSimulationExplanationsModalTitle": "Полный режим симуляции",
    "upsellFromEditionToSimulationModal": {
      "title": "Получите всю мощь симуляции!",
      "simuDesc1": "Создавайте симуляции для различных сценариев",
      "simuDesc2": "Настройте свою стратегию инвентаризации и мгновенно измерьте влияние на ваши KPI",
      "simuDesc3": "Выберите сценарий, который максимизирует ваши шансы на достижение целей",
      "simuDesc4": "От 1 до нескольких лет симуляций для балансировки стоимости запасов, покрытия и производительности",
      "buttonText": "Получить режим симуляции",
      "topupSubject": "{companyName}: получение режима симуляции для тактического {siteName}",
      "topupBody": "Пожалуйста, предоставьте доступ к режиму симуляции для тактического {siteName} / {companyName} (ID склада: {siteId}); email: {email}"
    },
    "upsellFromLiteToFullModal": {
      "buttonTrigger": "Получите всю мощь симуляции",
      "current": "Текущий",
      "dontShowAgain": "Больше не показывать это сообщение",
      "fullButtonText": "Получить полную симуляцию",
      "fullDesc1": "Лучшая точность симуляции: 50 сценариев, адаптированных к вашим срокам",
      "fullDesc2": "Все доступные ограничения",
      "fullDesc3": "Все стратегии инвентаризации доступны",
      "fullDesc4": "Симуляция, адаптированная к вашим потребностям",
      "fullVersion": "Полная симуляция",
      "liteButtonText": "Продолжить с Lite версией",
      "liteDesc1": "Точность симуляции: ограниченные сценарии ежемесячно",
      "liteDesc2": "Базовые ограничения",
      "liteDesc3": "Только стратегия Flowlity AI",
      "liteDesc4": "Симуляция обновляется ежеквартально",
      "liteVersion": "Lite симуляция",
      "title": "Получите всю мощь симуляции!",
      "topupBody": "Пожалуйста, предоставьте доступ к полной симуляции для тактического {siteName} / {companyName} (ID склада: {siteId}); email: {email}",
      "topupSubject": "{companyName}: получение полной симуляции для тактического {siteName}",
      "ZDFBannerText": "Обратите внимание, что в режиме Lite симуляции метрика “Zero Stock Days“ оценочная и может быть неточной. Для детальных и точных расчетов рассмотрите возможность обновления до",
      "ZDFBannerLink": "Полный режим симуляции"
    },
    "chart": {
      "demand": "План спроса",
      "stockCoverage": "Покрытие запасов",
      "stockLevel": "Уровень запасов",
      "supply": "План поставок",
      "zeroStockDay": "Дни без запасов",
      "noTags": "Теги не найдены",
      "actualSimulation": "Текущая симуляция",
      "objective": "Цель",
      "newSimulation": "Новая симуляция",
      "value": "Значение, Евро",
      "defaultUnit": "Количество, основная единица",
      "secondaryUnit": "Количество, вторичная единица",
      "days": "Дни",
      "dayUnit": "д",
      "percentage": "%, Процент",
      "default": "По умолчанию",
      "fullSiteScope": "Полный охват склада",
      "chartOnly": "График",
      "chartAndTable": "График и таблица",
      "baseline": "Идеальная проекция",
      "perfectProjectionTooltipText": "Идеальная проекция - это проекция вашего KPI с текущей стратегией инвентаризации, если все происходит согласно текущему плану (100% точный прогноз, отсутствие задержек у поставщиков и т.д.)",
      "actualSimulationTooltipText": "Текущая симуляция означает, что ее проекция - это симуляция с параметрами текущей стратегии инвентаризации",
      "newSimulationTooltipText": "Новая симуляция означает, что ее проекция - это симуляция с параметрами новой стратегии инвентаризации"
    },
    "columnsSettings": {
      "all": "Все",
      "avgStockMinQtyNext6M": "Минимальный запас (кол-во)",
      "avgStockMinCoverageNext6M": "Минимальный запас (дни)",
      "customers": "Клиенты",
      "leadTime": "Время выполнения",
      "defaultUnitName": "Основная единица",
      "endOfLife": "Конец жизненного цикла",
      "events": "События",
      "promotions": "Акции",
      "similarProducts": "Похожие продукты",
      "finalForecast": "Итоговый прогноз",
      "firmDemand": "Твердый спрос",
      "firmOrders": "Твердые заказы",
      "general": "Общее",
      "hidden": "Скрытые",
      "demand": "Спрос",
      "inventory": "Инвентаризация",
      "pastDemand": "Прошлый спрос",
      "productName": "Название продукта",
      "reference": "Артикул",
      "resetModalCancelButton": "Отмена",
      "resetModalConfirmButton": "Вернуться к умолчаниям",
      "resetModalText": "Вы потеряете текущие активные столбцы и порядок",
      "resetModalTitle": "Вернуться к умолчаниям столбцов?",
      "bufferLevel": "Уровень буфера %",
      "shelfLife": "Срок годности",
      "shown": "Показанные",
      "supplier": "Поставщики",
      "supplyBufferPolicy": "Политика буфера поставок",
      "title": "Столбцы",
      "stockReduction": "Изменение запасов %",
      "zeroStockDays": "Дни без запасов",
      "stockCoverage": "Покрытие запасов",
      "simulated": "симулированное",
      "nextOneYearAvgSimulated": "среднее за следующий год симулированное",
      "tabSimulated": "Симулированное",
      "tags": "Теги",
      "stockChangeTooltip": "Изменение % уровня запасов по сравнению с базовым",
      "resetOrder": "Сбросить порядок",
      "forecastModelDemandType": "Тип спроса"
    },
    "objectives": {
      "addScope": "Добавить охват",
      "demandPlan": "План спроса",
      "discardConfirmButton": "Отменить изменения",
      "discardDescription": "Вы потеряете все изменения, сделанные на текущей вкладке",
      "discardTitle": "Отменить изменения?",
      "discardCancelButton": "Вернуться к редактированию",
      "editTitle": "Редактировать цели",
      "fullSite": "Полный склад",
      "headerTitle": "Цели",
      "inventoryLevel": "Уровень запасов",
      "stockCoverage": "Покрытие запасов",
      "supplyPlan": "План поставок",
      "tag": "Тег",
      "zeroStockDays": "Дни без запасов"
    },
    "list": {
      "headers": {
        "bufferLevel": "Уровень буфера %",
        "stockReduction": "Изменение запасов %",
        "zeroStockDays": "Дни без запасов",
        "stockCoverage": "Покрытие запасов",
        "simulated": "симулированное",
        "nextOneYearAvgSimulated": "среднее за следующий год симулированное",
        "tags": "Теги"
      },
      "filters": {
        "clear": "Очистить",
        "NoTags": "Теги не найдены",
        "Tags": "Теги | {name} | {count} Теги",
        "NoSuppliers": "Нет поставщиков",
        "Suppliers": "Поставщики | {name} | {count} Поставщики",
        "suppliersExcluded": "Поставщики | Все кроме {name} | Все кроме {count} Поставщики",
        "bufferLevel": "Уровень буфера %",
        "ZeroStockDays": "Дни без запасов",
        "ZeroStockDaysShort": "ЗБД",
        "lastMonth": "Прошлый месяц",
        "last6M": "Среднее за последние 6 месяцев",
        "StockCoverage": "Покрытие запасов",
        "StockCoverageShort": "Покрытие запасов",
        "current": "Текущий месяц",
        "next6Mavg": "среднее за следующие 6 месяцев",
        "next1Yavg": "Среднее за следующий год",
        "days": "дни",
        "upTo": "до",
        "from": "с",
        "EditedOnly": "Только измененные",
        "deselectAll": "Отменить выбор всех",
        "minDays": "мин. 0 дней | мин. 1 день | мин. {count} дней",
        "maxDays": "макс. 0 дней | макс. 1 день | макс. {count} дней",
        "withoutSuppliersMode": "Без поставщика"
      },
      "NTagsSelected": "{count} тег выбран | {count} тегов выбрано",
      "editBufferLevel": "Редактировать уровень буфера",
      "editInventoryStrategy": "Редактировать стратегию инвентаризации",
      "Mixed": "Смешанный",
      "DeselectAllPage": "Отменить выбор всех на этой странице",
      "ClearSelection": "Очистить выбор",
      "tags": "Теги | Тег | Теги",
      "title": "Продукты",
      "initialServiceLevel": "Начальное значение - {value}",
      "onGoingChangesMsg": "Изменения уже происходят по этому параметру: {value}",
      "rowIsDisabledTooltip": "Этот продукт не использует Flowlity AI для своей стратегии инвентаризации, поэтому уровень буфера недоступен для редактирования"
    },
    "editing": {
      "discardChanges": "Отменить изменения",
      "applyToAll": "Применить ко всем уровням буфера:",
      "exactValue": "Точное значение",
      "adjustBy": "Регулировать на",
      "simulate": "Симулировать",
      "save": "Сохранить",
      "mixed": "Смешанный",
      "discardText": "Вы потеряете все изменения, сделанные во время редактирования",
      "discardCancelButton": "Вернуться к редактированию",
      "discardConfirmButton": "Отменить изменения",
      "discardTitle": "Отменить изменения?"
    },
    "validation": {
      "validate": "Подтвердить",
      "validated": "Подтверждено",
      "discardValidation": "Отменить проверку",
      "discardSimulation": "Удалить симулированные данные",
      "confirmationTitleValidation": "Подтвердить сценарий?",
      "confirmationDescriptionValidation": "После подтверждения изменение уровня буфера будет заблокировано.",
      "discardConfirmationValidation": "Отменить проверку",
      "discardConfirmationTitleValidation": "Отменить проверку?",
      "discardConfirmationDescriptionValidation": "Вы потеряете статус проверки и возможность каскадировать на Flowlity",
      "discardConfirmationSimulation": "Удалить симулированные данные",
      "discardConfirmationTitleSimulation": "Отменить симуляцию?",
      "discardConfirmationDescriptionSimulation": "Вы потеряете все симулированные данные в продуктах"
    },
    "cascade": {
      "confirmationTitle": "Применить сценарий к Flowlity?",
      "confirmationDescription": "Изменения будут учтены при следующем расчете плана поставок.",
      "confirm": "Подтвердить",
      "apply": "Применить",
      "applyExplanation": "Применить новую конфигурацию к Flowlity",
      "successNotifTitle": "Все симулированные изменения применены к Flowlity",
      "successNotifDescription": "Все симулированные значения станут активными значениями продуктов"
    },
    "inventoryStrategy": {
      "changedMessage": "Изменения в стратегии инвентаризации применены"
    },
    "waitingNextCalculation": "Ожидание следующего расчета"
  },
  "es": {
    "changesValidated": "Cambios en validación",
    "productsWithChanges": "0 productos con cambios | 1 producto con cambios | {count} productos con cambios",
    "editionMode": "Modo de edición",
    "editionModeDescription": "Realiza cambios masivos en la estrategia de inventario para todos los productos",
    "simulationMode": "Modo de simulación",
    "simulationModeDescription": "Realiza cambios masivos en los niveles de buffer y simúlalos para ver la nueva proyección de los KPI disponibles",
    "tooltipCantSwitchModeWhileEditingBufferLevel": "No puedes cambiar al modo de edición mientras estás editando los niveles de buffer",
    "tooltipCantSwitchModeWhileOnGoingSimulation": "No puedes cambiar al modo de edición mientras una simulación está en curso. Primero debes validar tu escenario y luego aplicar los cambios a Flowlity.",
    "tooltipCantSwitchModeIfNotCascaded": "No puedes cambiar al modo de edición mientras una simulación está en curso. Primero debes aplicar tus cambios a Flowlity.",
    "fullSimulationExplanationsModalTitle": "Modo de simulación completa",
    "upsellFromEditionToSimulationModal": {
      "title": "¡Obtén todo el poder de la simulación!",
      "simuDesc1": "Genera simulaciones con diferentes escenarios",
      "simuDesc2": "Ajusta tus estrategias de inventario y mide el impacto directamente en tus KPI",
      "simuDesc3": "Elige el escenario que maximice tus posibilidades de alcanzar tus objetivos",
      "simuDesc4": "Simulaciones de uno a varios años para equilibrar el valor del stock, la cobertura y el rendimiento",
      "buttonText": "Activar modo simulación",
      "topupSubject": "{companyName}: activar modo simulación para Tactical {siteName}",
      "topupBody": "Por favor, habilitar acceso al modo simulación para Tactical en {siteName} / {companyName} (ID del sitio: {siteId}); email: {email}"
    },
    "upsellFromLiteToFullModal": {
      "buttonTrigger": "Obtén todo el poder de la simulación",
      "current": "Actual",
      "dontShowAgain": "No mostrar este mensaje nuevamente",
      "fullButtonText": "Obtener simulación completa",
      "fullDesc1": "La mejor precisión en simulaciones: 50 escenarios adaptados a tus tiempos de entrega",
      "fullDesc2": "Todas las restricciones disponibles",
      "fullDesc3": "Todas las estrategias de inventario disponibles",
      "fullDesc4": "Simulación adaptada a tus necesidades",
      "fullVersion": "Simulación completa",
      "liteButtonText": "Continuar con la versión lite",
      "liteDesc1": "Precisión limitada en simulaciones mensuales",
      "liteDesc2": "Restricciones básicas",
      "liteDesc3": "Solo estrategia de IA de Flowlity",
      "liteDesc4": "Simulación actualizada trimestralmente",
      "liteVersion": "Simulación Lite",
      "title": "¡Obtén todo el poder de la simulación!",
      "topupBody": "Por favor, habilitar acceso a la simulación completa para Tactical en {siteName} / {companyName} (ID del sitio: {siteId}); email: {email}",
      "topupSubject": "{companyName}: activar simulación completa para Tactical {siteName}",
      "ZDFBannerText": "Ten en cuenta que en el modo de simulación Lite, la métrica de “Días sin Stock” es estimada y puede carecer de precisión. Para cálculos detallados y precisos, considera actualizar a nuestro",
      "ZDFBannerLink": "Modo de simulación completa"
    },
    "chart": {
      "demand": "Plan de demanda",
      "stockCoverage": "Cobertura de stock",
      "stockLevel": "Nivel de inventario",
      "supply": "Plan de suministro",
      "zeroStockDay": "Días sin stock",
      "noTags": "No se encontraron etiquetas",
      "actualSimulation": "Simulación actual",
      "objective": "Objetivo",
      "newSimulation": "Nueva simulación",
      "value": "Valor, Euro",
      "defaultUnit": "Cantidad, Unidad predeterminada",
      "secondaryUnit": "Cantidad, Unidad secundaria",
      "days": "Días",
      "dayUnit": "d",
      "percentage": "%, Porcentaje",
      "default": "Por defecto",
      "fullSiteScope": "Alcance completo del sitio",
      "chartOnly": "Gráfico",
      "chartAndTable": "Gráfico y Tabla",
      "baseline": "Proyección perfecta",
      "perfectProjectionTooltipText": "La proyección perfecta es la proyección de tu KPI con la estrategia de inventario actual como si todo ocurriera según el plan (Previsión 100% precisa, Sin retrasos de proveedores, etc.)",
      "actualSimulationTooltipText": "La simulación actual significa que su proyección es una simulación con los parámetros actuales de la estrategia de inventario",
      "newSimulationTooltipText": "La nueva simulación significa que su proyección es una simulación con los nuevos parámetros de la estrategia de inventario"
    },
    "columnsSettings": {
      "all": "Todos",
      "avgStockMinQtyNext6M": "Stock mínimo (cantidad)",
      "avgStockMinCoverageNext6M": "Stock mínimo (días)",
      "customers": "Clientes",
      "leadTime": "Plazo de entrega",
      "defaultUnitName": "Unidad predeterminada",
      "endOfLife": "Fin de vida",
      "events": "Eventos",
      "promotions": "Promociones",
      "similarProducts": "Productos similares",
      "finalForecast": "Previsión final",
      "firmDemand": "Demanda firme",
      "firmOrders": "Pedidos firmes",
      "general": "General",
      "hidden": "Oculto",
      "demand": "Demanda",
      "inventory": "Inventario",
      "pastDemand": "Demanda pasada",
      "productName": "Nombre del producto",
      "reference": "Referencia",
      "resetModalCancelButton": "Cancelar",
      "resetModalConfirmButton": "Restablecer por defecto",
      "resetModalText": "Perderás tus columnas activas actuales y el orden",
      "resetModalTitle": "¿Restablecer el orden predeterminado de columnas?",
      "bufferLevel": "Nivel de buffer %",
      "shelfLife": "Vida útil",
      "shown": "Mostrado",
      "supplier": "Proveedores",
      "supplyBufferPolicy": "Política de buffer de suministro",
      "title": "Columnas",
      "stockReduction": "Cambio de stock %",
      "zeroStockDays": "Días sin stock",
      "stockCoverage": "Cobertura de stock",
      "simulated": "Simulado",
      "nextOneYearAvgSimulated": "Promedio del próximo año simulado",
      "tabSimulated": "Simulado",
      "tags": "Etiquetas",
      "stockChangeTooltip": "Cambio en % del nivel de stock en comparación con la línea base",
      "resetOrder": "Restablecer orden",
      "forecastModelDemandType": "Tipo de demanda"
    },
    "objectives": {
      "addScope": "Agregar alcance",
      "demandPlan": "Plan de demanda",
      "discardConfirmButton": "Descartar cambios",
      "discardDescription": "Perderás todos los cambios realizados en la pestaña actual",
      "discardTitle": "¿Descartar cambios?",
      "discardCancelButton": "Volver a editar",
      "editTitle": "Editar objetivos",
      "fullSite": "Sitio completo",
      "headerTitle": "Objetivos",
      "inventoryLevel": "Nivel de inventario",
      "stockCoverage": "Cobertura de stock",
      "supplyPlan": "Plan de suministro",
      "tag": "Etiqueta",
      "zeroStockDays": "Días sin stock"
    },
    "list": {
      "headers": {
        "bufferLevel": "Nivel de buffer %",
        "stockReduction": "Cambio de stock %",
        "zeroStockDays": "Días sin stock",
        "stockCoverage": "Cobertura de stock",
        "simulated": "Simulado",
        "nextOneYearAvgSimulated": "Promedio del próximo año simulado",
        "tags": "Etiquetas"
      },
      "filters": {
        "clear": "Limpiar",
        "NoTags": "No se encontraron etiquetas",
        "Tags": "Etiquetas | {name} | {count} etiquetas",
        "NoSuppliers": "Sin proveedores",
        "Suppliers": "Proveedores | {name} | {count} proveedores",
        "suppliersExcluded": "Proveedores | Todos excepto {name} | Todos excepto {count} proveedores",
        "bufferLevel": "Nivel de buffer %",
        "ZeroStockDays": "Días sin stock",
        "ZeroStockDaysShort": "DSF",
        "lastMonth": "Último mes",
        "last6M": "Promedio últimos 6 meses",
        "StockCoverage": "Cobertura de stock",
        "StockCoverageShort": "Cobertura de stock",
        "current": "Mes actual",
        "next6Mavg": "Promedio próximos 6 meses",
        "next1Yavg": "Promedio del próximo año",
        "days": "días",
        "upTo": "hasta",
        "from": "desde",
        "EditedOnly": "Solo editados",
        "deselectAll": "Deseleccionar todo",
        "minDays": "mín. 0 días | mín. 1 día | mín. {count} días",
        "maxDays": "máx. 0 días | máx. 1 día | máx. {count} días",
        "withoutSuppliersMode": "Sin proveedor"
      },
      "NTagsSelected": "{count} etiqueta seleccionada | {count} etiquetas seleccionadas",
      "editBufferLevel": "Editar nivel de buffer",
      "editInventoryStrategy": "Editar estrategia de inventario",
      "Mixed": "Mixto",
      "DeselectAllPage": "Deseleccionar todo en esta página",
      "ClearSelection": "Limpiar selección",
      "tags": "Etiquetas | Etiqueta | Etiquetas",
      "title": "Productos",
      "initialServiceLevel": "Valor inicial - {value}",
      "onGoingChangesMsg": "Ya hay un cambio en curso en este parámetro: {value}",
      "rowIsDisabledTooltip": "Este producto no utiliza la IA de Flowlity para su estrategia de inventario, por lo tanto, el nivel de buffer no está disponible para editar"
    },
    "editing": {
      "discardChanges": "Descartar cambios",
      "applyToAll": "Aplicar a todos los niveles de buffer:",
      "exactValue": "Valor exacto",
      "adjustBy": "Ajustar por",
      "simulate": "Simular",
      "save": "Guardar",
      "mixed": "Mixto",
      "discardText": "Perderás todos los cambios realizados durante la edición",
      "discardCancelButton": "Volver a editar",
      "discardConfirmButton": "Descartar cambios",
      "discardTitle": "¿Descartar cambios?"
    },
    "validation": {
      "validate": "Validar",
      "validated": "Validado",
      "discardValidation": "Descartar validación",
      "discardSimulation": "Eliminar datos simulados",
      "confirmationTitleValidation": "¿Validar escenario?",
      "confirmationDescriptionValidation": "Después de la validación, la modificación del nivel de buffer será bloqueada.",
      "discardConfirmationValidation": "Descartar validación",
      "discardConfirmationTitleValidation": "¿Descartar validación?",
      "discardConfirmationDescriptionValidation": "Perderás el estado de validación y la posibilidad de aplicar los cambios a Flowlity",
      "discardConfirmationSimulation": "Eliminar datos simulados",
      "discardConfirmationTitleSimulation": "¿Descartar simulación?",
      "discardConfirmationDescriptionSimulation": "Perderás todos los datos simulados en los productos"
    },
    "cascade": {
      "confirmationTitle": "¿Aplicar el escenario a Flowlity?",
      "confirmationDescription": "Las modificaciones se tendrán en cuenta en el próximo cálculo del plan de suministro.",
      "confirm": "Confirmar",
      "apply": "Aplicar",
      "applyExplanation": "Aplicar nueva configuración a Flowlity",
      "successNotifTitle": "Todos los cambios simulados aplicados a Flowlity",
      "successNotifDescription": "Todos los valores simulados se convertirán en valores activos de los productos"
    },
    "inventoryStrategy": {
      "strategyType": "Tipo de estrategia",
      "bufferPolicy": "Política de buffer",
      "bufferLevel": "Nivel de buffer",
      "rop": "Valor del punto de reorden",
      "myStockMin": "Mi stock mínimo",
      "myStockMax": "Mi stock máximo",
      "changedMessage": "Cambios en la estrategia de inventario aplicados",
      "title": {
        "strategyType": "Editar tipo de estrategia",
        "bufferPolicy": "Editar política de buffer",
        "bufferLevel": "Editar nivel de buffer",
        "rop": "Editar valor del punto de reorden",
        "stockMin": "Editar mi stock mínimo",
        "stockMax": "Editar mi stock máximo"
      }
    },
    "waitingNextCalculation": "Esperando el próximo cálculo"
  }
}


