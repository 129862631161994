{
  "requiredError": "This field is required",
  "requiredNumberError": "Please provide a valid number",
  "requiredStringError": "Please provide a valid string",
  "main": {
    "exportTitle": "Download data",
    "settings": "Settings",
    "company": "Company",
    "sites": "Sites",
    "users": "Users",
    "suppliers": "Suppliers",
    "customers": "Customers",
    "products": "Products",
    "promotions": "Promotions",
    "bufferPolicy": "Buffer Policy",
    "closures": "Site closures",
    "tags": "Tags",
    "general": "General",
    "profile": "Profile",
    "parameters": "Parameters"
  },
  "company": {
    "title": "Company",
    "name": "Name",
    "nameError": "Please add a company name",
    "cancel": "Cancel"
  },
  "bufferPolicy": {
    "orderPointValue": "Order point value",
    "constantMinimumTip": " We will use it as fixed value for the whole horizon",
    "useLowerLimitMin": "Add lower limit",
    "lowerLimitMin": "Limit value",
    "title": "Buffer Policy",
    "MTSFlowlityAITitle": "Default Supply Buffer Policy for MTS Flowlity AI strategy",
    "MTSMyMinTitle": "Default Supply Buffer Policy for MTS My min strategy",
    "MTSROPTitle": "Default Supply Buffer Policy for MTS Reorder point strategy",
    "MTSROPAITitle": "Default Supply Buffer Policy for MTS Reorder point + AI strategy",
    "MTOtitle": "Default Supply Buffer Policy for MTO strategy",
    "subtitle": "It will be applied to all products with these strategies where custom policy is not configured.",
    "none": "None",
    "time": "Time",
    "stock": "Stock",
    "supplierDelay": "Supplier Delay",
    "inputDisabledTooltip": "No supplier delay because there is no policy applied",
    "errorEmpty": "Supplier delay can't be empty, it should be 1 day or more",
    "errorZero": "Supplier delay can't be 0, it should be 1 day or more",
    "errorNegative": "Supplier delay can't be negative, it should be 1 day or more",
    "save": "Save",
    "discard": "Discard changes",
    "updateSuccess": "Buffer Policy updated",
    "updateError": "An error occurred while updating the Buffer Policy",
    "saveTitle": "Save Changes?",
    "saveDescription": "Changes will apply to products using default Buffer Policy",
    "saveConfirmButton": "Save Changes",
    "cancel": "Cancel",
    "days": "days",
    "minFixedValue": "My Stock Min level"
  },
  "export": {
    "title": "Downloads",
    "desc": "Please select the file and format you want to download",
    "salesOrders": "Sales Orders",
    "purchaseOrders": "Purchase Orders",
    "planning": "Planning",
    "demands": "Demand Forecasts",
    "file": "File(s)",
    "localDownload": "Local Download",
    "sftpSuccess": "Data successfully exported",
    "exportError": "Export has failed with the following error: ",
    "period": "Period",
    "site": "Site(s)",
    "timebucket": "Period type",
    "units": "Units",
    "week": "Week",
    "month": "Month",
    "primaryUnit": "Primary",
    "secondaryUnit": "Secondary"
  },
  "closures": {
    "add": "Add closure",
    "back": "back",
    "cancel": "Cancel",
    "createSuccess": "Closure created successfully",
    "createTitle": "Create site closure",
    "delete": "Delete",
    "deleteDescription": "This action is permanent",
    "deleteQuestion": "This will permanently delete this closure. Continue?",
    "deleteSuccess": "Closure deleted successfully",
    "editTitle": "Edit closure",
    "endDate": "End date",
    "closureDetails": "Closure details",
    "name": "Name",
    "noData": "No closure found",
    "notFound": "Closure not found",
    "percentageOff": "Percentage off",
    "requiredError": "This field is required",
    "requiredNumberError": "Please provide a valid number",
    "save": "Save",
    "search": "Search by name",
    "startDate": "Start date",
    "title": "All site closures",
    "updateSuccess": "Closure updated successfully"
  },
  "products": {
    "add": "Add Product",
    "addPromotion": "Add Promotion",
    "addSecondaryUnit": "Add Secondary Unit",
    "addSimilarProducts": "Add Similar Product",
    "addSuppliers": "Add suppliers",
    "addTag": "Add Tag",
    "ajustedBy": "Ajusted by",
    "averageDelays": " delay coverage",
    "source": "Source",
    "bufferLeadTimeTip": "Orders placed in advance of the requirements",
    "bufferLevel": "Buffer Level",
    "bufferLevelTip": "The Flowlity Buffer Level will impact your buffer stock level. The lower is the buffer level, the lower will be your minimum level. The higher is the buffer level, the higher will be your minimum level.",
    "bufferPolicy": "Buffer Policy",
    "bufferStockTip": "Stock min takes into account suppliers delays",
    "cancel": "Cancel",
    "cases": "cases",
    "coeff": "Coeff",
    "coverPercentageOfRisk": "to cover {count}% of risk",
    "coverage": "Coverage",
    "computeNewMin": "Compute new min",
    "computeNewMinTooltip": "Available only in planning view",
    "days": "day | day | days",
    "defaultSimilarSelect": "None",
    "defaultUnit": "Default unit",
    "delayForecastingDescription": "Recommended by Flowlity values",
    "delete": "Delete",
    "deleteDescription": "You will be automatically redirect to list page after deletion.",
    "deleteProduct": "Delete Product",
    "deleteTitle": "Do you really want to delete this product ?",
    "demandBasedBuffer": "Demand-based - Flowlity AI",
    "demandBasedBufferDescription": "Supply recommendations will be placed based on flowlity Forecast and stock level. The buffer stock level is generated based on flowlity demand and supply uncertainties",
    "description": "Description",
    "discard": "Discard",
    "noPermissionToEdit": "You don't have permission to edit this inventory strategy",
    "discardCancelButton": "Back to Editing",
    "discardConfirmButton": "Discard changes",
    "discardDescription": "Your will loose all changes that was made on current tab",
    "discardTitle": "Discard changes ?",
    "displayUnit": "Display Unit",
    "editTitle": "Edit Product Details",
    "emptyDelayForecasting": "No delay recommendations, we need more data to generate",
    "emptyError": "Can't be empty",
    "enter": "Enter",
    "events": "Events",
    "externalIntegration": "This data is integrated from external system",
    "selectedProductsUseDifferentUnits": "Selected products use different units",
    "inventoryStrategies": "Inventory Strategy",
    "myStockMin": "Flowlity AI + My min",
    "myStockMinDescription": "Supply recommendations are based on Flowlity AI forecast and stock level. The buffer stock level is user-defined",
    "fullHorizon": "Full horizon",
    "manualPeriod": "Manual period",
    "manualHorizon": "Manual horizon",
    "mts": "Make to stock",
    "mtsDescription": "Supply recommendations are based on buffer stock level",
    "mto": "Make to order",
    "mtoDescription": "Supply recommendations are based on firm demand. There is no buffer stock",
    "flowlityAi": "Flowlity AI",
    "internalCustomer": "Internal Customer",
    "internalCustomerTooltip": "Forecast data of internal customers is excluded from product forecast calculation. Only firm demand of internal customers is used for calculation.",
    "fixedLotSize": "Fixed lot size",
    "info": "Info",
    "infoRatio": "Ratio",
    "initialValue": "Initial Value - {initialBufferLevel}",
    "exactValue": "Exact value",
    "inventoryStrategy": "Inventory Strategy",
    "lockedByTacticalChanges": "Locked by tactical changes",
    "leadTime": "Lead Time",
    "manualStockCoverage": "Manual Stock Coverage",
    "moq": "Minimum Order Quantity",
    "myMaxValue": "My max value",
    "myStockMax": "My Stock Max",
    "myStockMaxTooltip": "An alert is triggered when the stock level is above My stock max value. My stock max does not act as a constraint in the supply plan calculation",
    "name": "Name",
    "next6mAvgDays": "Next 6m avg.",
    "next6mAvgDaysSimulated": "Simulated next 6m avg.",
    "next6mAvgQtySimulated": "Simulated next 6m avg.",
    "noData": "No product found",
    "none": "None",
    "noPolicy": "No Policy",
    "price": "Price",
    "productionEnd": "Production End",
    "productionStart": "Production Start",
    "productLifeCycle": "Product life cycle",
    "promotions": "Promotions",
    "quantity": "Quantity",
    "ratio": "Ratio (0-100%)",
    "remove": "Remove",
    "reorderPoint": "Reorder point",
    "reorderPointDescription": "Supply recommendations are triggered when stock reaches or falls below the Reorder Point level. Only firm demand is considered within the ROP horizon (no forecast)",
    "ropAI": "Reorder point + AI",
    "ropAIDescription": "AI generates the ROP level dynamically based on lead times and past consumptions",
    "salesEnd": "End of Life",
    "salesEndTooltip": "This data is integrated from an external system",
    "salesStart": "Sales Start",
    "save": "Save",
    "apply": "Apply",
    "changesApplied": "Inventory strategy changes applied",
    "incomingChanges": "Incoming changes:",
    "inventoryStrategyWasChanged": "Inventory strategy was changed. ",
    "saveBufferPolicyDescription": "You will see changes after the next computation.",
    "saveBufferPolicyDescriptionWithOptimizeBtn": "You will see changes after the next computation. You can also use \"Optimize\" button in Planning to recompute minimum and supply recommendations immediately.",
    "saveBufferPolicyTitle": "Save changes of the Inventory Strategy configuration?",
    "saveChanges": "Save Changes",
    "search": "Search by name",
    "secondaryUnit": "Secondary unit",
    "selectADate": "Select a date",
    "shelfLife": "Shelf Life",
    "similarProducts": "Similar Products",
    "similarProductsSubTitle": "Add former similar products",
    "showNewMin": "Show new min",
    "sourceCustomer": "Source Customer",
    "sourceProduct": "Source Product",
    "stock": "Stock",
    "stockCoverageTitle": "Stock coverage, days",
    "stockMinCoverage": "Stock Min Coverage",
    "stockPolicyHelpContent": "Stock min is adjusted to take an account those delays",
    "atLeastOneProductCannotUseThisPolicy": "At least 1 selected product cannot use this policy",
    "storageSites": "Storage sites",
    "strategy": "Strategy",
    "suppliers": "Suppliers",
    "supplyBufferPolicy": "Supply buffer policy",
    "supplyBufferPolicyNoDefaultStockTip": "Default Supply Buffer Policy is “Stock” but it can't be used with current configuration",
    "supplyStrategy": "Supply Strategy",
    "tagGrouper": "Tags for order grouping",
    "tags": "Tags",
    "targetCustomer": "Target Customer",
    "time": "Time",
    "timePolicyHelpContent": "Delivery dates in orders placed in advance of the planning requirements.",
    "title": "All products",
    "units": "Units",
    "useDefaultBufferPolicy": "Use default policy",
    "value": "Value",
    "withCustomer": "x Customer",
    "mixed": "Mixed",
    "values": "Values",
    "allSuppliers": "All suppliers",
    "perSupplier": "Per supplier",
    "delayCoverage": "Delay coverage",
    "productUseDefaultPolicy": "Product use default policy",
    "bom": "Bom",
    "bomParents": "Parent products",
    "bomChildren": "Child products",
    "bomSubTitle": "In parent products we display values of current reference as a component in their bill of material.",
    "openPlanning": "Open planning",
    "bomParentsQuantity": "Bom quantity (current Ref.)",
    "bomParentsOffset": "BOM offset (current Ref.)",
    "bomChildrenQuantity": "Bom quantity",
    "bomChildrenOffset": "BOM offset"
  },
  "promotions": {
    "add": "Add Promotion",
    "addButton": "Create",
    "addTitle": "Create a new promotion",
    "back": "back",
    "cancel": "Cancel",
    "coefficient": "Sales impact in (%)",
    "create": "Create",
    "createSuccess": "Promotion created successfully",
    "delete": "Delete",
    "deleteDescription": "This action is permanent",
    "deleteQuestion": "This will permanently delete this promotion. Continue?",
    "deleteSuccess": "Promotion deleted successfully",
    "description": "Description",
    "editTitle": "Edit promotion",
    "editButton": "Save",
    "endDate": "End date",
    "name": "Name",
    "noData": "No promotion found",
    "products": "Products",
    "promotionDetails": "Promotion details",
    "requiredError": "This field is required",
    "requiredNumberError": "Please provide a valid number",
    "search": "Search by name",
    "startDate": "Start date",
    "title": "All promotions",
    "updateSuccess": "Promotion updated successfully"
  },
  "sites": {
    "title": "All sites",
    "noSite": "No site found",
    "back": "all sites",
    "editTitle": "Edit the site",
    "name": "Name",
    "address": "Address",
    "currency": "Currency (EUR, USD, CAD, ...)",
    "save": "Save",
    "cancel": "Cancel",
    "errorName": "Please add a site name",
    "errorAddress": "Please add a site address",
    "demandDistribution": "Demand distribution throughout the week",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "addClosure": "Add a new closure time",
    "showFutureDashboard": "Show future values in inventory dashboard",
    "inventoryDashboardTitle": "Inventory Dashboard properties",
    "stockCompTitle": "Stock computation properties",
    "shelfLife": "Products' shelf life",
    "mustBeEq100": "Must be = 100%"
  },
  "suppliers": {
    "search": "Search by name",
    "add": "Add Supplier",
    "addButton": "Create",
    "address": "address (optional)",
    "addTitle": "Create a new supplier",
    "back": "all suppliers",
    "cancel": "Cancel",
    "constraints": "Constraints (optional)",
    "days": "days",
    "editButton": "Save",
    "editTitle": "Edit the supplier",
    "email": "Contact email (optional)",
    "errorExtId": "Please add a supplier reference",
    "errorName": "Please add a supplier name",
    "externalId": "Ref",
    "fullTruckQte": "Full truck qte",
    "maxSuppliesByOrder": "Max rows by order (export only)",
    "minOrderQte": "Min order qte",
    "name": "Name",
    "noSupplier": "No supplier found",
    "orderFrequency": "Order frequency",
    "searchSupplier": "Search by name",
    "title": "All suppliers",
    "unit": "unit",
    "unitPlaceholder": "Select unit",
    "noAvailableUnitsMsg": "There are no unit available, due to no product assigned to supplier or no unit assigned to the products.",
    "updateSuccess": "Supplier has been updated successfully",
    "createSuccess": "Supplier has been created successfully"
  },
  "tags": {
    "add": "Create tag",
    "addTag": "To tag",
    "all": "Products list",
    "back": "back",
    "cancel": "Cancel",
    "create": "Create",
    "createError": "An error occurred during the tag creation",
    "createSuccess": "Tag Created",
    "delete": "Delete",
    "deleteDescription": "This tag used in {count} products",
    "deleteQuestion": "Delete tag?",
    "deleteAction": "Delete tag",
    "deleteSuccess": "Tag \"{name}\" deleted",
    "editTitle": "Edit tag",
    "isTagGrouper": "Used to group orders",
    "isSimulated": "Used for simulation",
    "name": "Tag name",
    "noData": "No tag found",
    "nonAdminWarningText": "Please contact your admin for any modification",
    "notTaggedProducts": "available products | available product | available products",
    "products": "Products",
    "remove": "Untag",
    "requiredError": "This field is required",
    "save": "Save",
    "search": "Search",
    "searchByProduct": "Search by product name",
    "selectAll": "Select all",
    "deselectAll": "Deselect all",
    "tagDetails": "Tag details",
    "productsSelected": "1 product selected | {count} products selected",
    "title": "All Tags",
    "updateError": "An error occurred while updating the tag",
    "updateSuccess": "Tag Updated",
    "tagType": "Type",
    "integrated": "Integrated",
    "createdInFlowlity": "Created in Flowlity",
    "integratedDescription": "Integrated from external system",
    "discardCancelButton": "Back to Editing",
    "discardConfirmButton": "Discard changes",
    "discardText": "You will loose all changes that was made during editing",
    "discardTitle": "Discard changes?"
  },
  "users": {
    "active": "Active User",
    "back": "all users",
    "cancel": "Cancel",
    "editTitle": "Edit the user",
    "profile": "Profile",
    "email": "Email",
    "errorEmail": "Please add a user email",
    "errorEmailPattern": "Please enter a proper email",
    "errorName": "Please add a user name",
    "job": "Job Title (optional)",
    "name": "Name",
    "notFound": "User not found",
    "noUser": "No user found",
    "phone": "Office Phone (optional)",
    "sameSiteError": "You cannot remove yourself from the Site currently used in the application",
    "title": "All users",
    "userInfo": "Info",
    "scope": "Scope",
    "suppliersScope": "Suppliers Scope",
    "updateSuccess": "Changes saved",
    "updateError": "An error occurred while updating the profile",
    "save": "Save",
    "discard": "Discard changes"
  }
}
