{
  "main": {
    "exportTitle": "Télécharger les données",
    "settings": "Configurations",
    "company": "Entreprise",
    "sites": "Sites",
    "users": "Utilisateurs",
    "suppliers": "Fournisseurs",
    "customers": "Clients",
    "products": "Produits",
    "promotions": "Promotions",
    "bufferPolicy": "Politique de buffer",
    "closures": "Fermetures de site",
    "tags": "Tags",
    "general": "Général",
    "profile": "Profil",
    "parameters": "Paramètres"
  },
  "company": {
    "title": "Entreprise",
    "name": "Nom",
    "nameError": "Ajoutez un nom d'entreprise",
    "cancel": "Annuler"
  },
  "bufferPolicy": {
    "orderPointValue": "Niveau de Point de Commande",
    "constantMinimumTip": "Cette valeur sera fixe pour tout l'horizon",
    "useLowerLimitMin": "Ajouter une limite inférieure",
    "lowerLimitMin": "Valeur limite",
    "title": "Politique de buffer",
    "MTSFlowlityAITitle": "Politique de buffer par défaut pour la stratégie MTS Flowlity IA",
    "MTSMyMinTitle": "Politique de buffer par défaut pour la stratégie MTS Mon stock min",
    "MTSROPTitle": "Politique de buffer par défaut pour la stratégie MTS Point de commande",
    "MTSROPAITitle": "Politique de buffer par défaut pour la stratégie MTS Point de commande + IA",
    "MTOtitle": "Politique de buffer par défaut pour la stratégie MTO",
    "subtitle": "Cette politique sera appliquée à tous les produits de cette stratégie n'ayant pas de politique spécifique.",
    "none": "Aucune",
    "time": "Temps",
    "stock": "Stock",
    "supplierDelay": "Délais fournisseur",
    "inputDisabledTooltip": "Pas de délais fournisseur car aucune politique de buffer n'est appliquée",
    "errorEmpty": "Le délais fournisseur ne peut être vide, il doit être de 1 jour ou plus",
    "errorZero": "Le délais fournisseur ne peut être 0, il doit être de 1 jour ou plus",
    "errorNegative": "Le délais fournisseur ne peut être négatif, il doit être de 1 jour ou plus",
    "save": "Valider",
    "discard": "Annuler les changements",
    "updateSuccess": "Politique de buffer mise à jour",
    "updateError": "Une erreur est survenue durant la mise à jour de la Politique de Buffer",
    "saveTitle": "Sauvegarder les changements?",
    "saveDescription": "Les changements s'appliqueront sur les produits utilisant la Politique de Buffer par défaut",
    "saveConfirmButton": "Sauvegarder",
    "cancel": "Annuler",
    "days": "jours",
    "minFixedValue": "Niveau de Mon Stock Min"
  },
  "export": {
    "title": "Téléchargements",
    "desc": "Sélectionner le type et le format du fichier à télécharger",
    "salesOrders": "Commandes de ventes",
    "purchaseOrders": "Commandes d'achats",
    "planning": "Plan",
    "demands": "Prévisions de Demandes",
    "file": "Fichier(s)",
    "localDownload": "Téléchargement local",
    "sftpSuccess": "Données exportées avec succès",
    "exportError": "L'export a échoué avec l'erreur suivante: ",
    "period": "Période",
    "site": "Site(s)",
    "timebucket": "Type de période",
    "units": "Unités",
    "week": "Semaine",
    "month": "Mois",
    "primaryUnit": "Primaire",
    "secondaryUnit": "Secondaire"
  },
  "closures": {
    "add": "Créer fermeture",
    "back": "retour",
    "cancel": "Annuler",
    "createSuccess": "Fermeture créée avec succès",
    "createTitle": "Créer fermeture de site",
    "delete": "Supprimer",
    "deleteDescription": "Cette action est définitive",
    "deleteQuestion": "Ceci va définitivement supprimer la fermeture. Continuer ?",
    "deleteSuccess": "Fermeture supprimée avec succès",
    "editTitle": "Modifier fermeture",
    "endDate": "Date de fin",
    "closureDetails": "Details de la fermeture",
    "name": "Nom",
    "noData": "Pas de fermeture trouvée",
    "notFound": "Fermeture introuvable",
    "percentageOff": "Pourcentage de réduction",
    "requiredError": "Ce champ est requis",
    "requiredNumberError": "Merci de renseigner un nombre valide",
    "save": "Valider",
    "search": "Chercher par nom",
    "startDate": "Date de début",
    "title": "Toutes les fermetures",
    "updateSuccess": "Fermeture mise à jour avec succès"
  },
  "products": {
    "add": "Créer Produit",
    "addPromotion": "Créer Promotion",
    "addSecondaryUnit": "Ajouter une unité secondaire",
    "addSimilarProducts": "Ajouter un produit similaire",
    "addSuppliers": "Ajoutez des fournisseurs",
    "addTag": "Créer Tag",
    "averageDelays": " retard couvert",
    "source": "Source",
    "bufferLeadTimeTip": "Commandes passées avant les besoins",
    "bufferLevel": "Niv. de stock sécurité",
    "bufferLevelTip": "Le niveau de stock sécurité Flowlity aura un impact sur votre niveau de stock sécurité. Plus le niveau de stock sécurité est faible, plus votre niveau minimum sera bas. Plus le niveau de stock sécurité est élevé, plus votre niveau minimum sera élevé.",
    "bufferPolicy": "Politique de buffer",
    "bufferStockTip": "Stock min tient compte des délais fournisseurs",
    "cancel": "Annuler",
    "cases": "des cas",
    "coeff": "Coeff",
    "coverPercentageOfRisk": "pour couvrir {count}% des risques",
    "coverage": "Couverture",
    "computeNewMin": "Calculer le nouveau min",
    "computeNewMinTooltip": "Disponible uniquement dans l'onglet Planning",
    "days": "jour | jour | jours",
    "defaultSimilarSelect": "Pas de valeur",
    "defaultUnit": "Unité par défaut",
    "delayForecastingDescription": "Retard basé sur les valeurs de Flowlity",
    "delete": "Supprimer",
    "deleteDescription": "Vous allez être redirigé vers la liste des produits après suppression.",
    "deleteProduct": "Supprimer ce produit",
    "deleteTitle": "Êtes-vous sûr de vouloir supprimer ce produit ?",
    "demandBasedBuffer": "Flux tirés - Flowlity IA",
    "demandBasedBufferDescription": "Les recommandations en matière d'approvisionnement seront formulées en fonction des prévisions de flux et du niveau de stock. Le niveau de stock tampon est généré sur la base de la demande de fluidité et des incertitudes de l'offre",
    "description": "Description",
    "discard": "Annuler",
    "noPermissionToEdit": "Vous n'avez pas les permissions pour modifier cette stratégie de stock",
    "discardCancelButton": "Continuer d'éditer",
    "discardConfirmButton": "Annuler les modifications",
    "discardDescription": "Vous ne pourrez plus récupérer les modifications effectuées.",
    "discardTitle": "Annuler les modifications ?",
    "displayUnit": "Unité d'affichage",
    "editTitle": "Editer un produit",
    "emptyDelayForecasting": "Pas assez de données pour générer des recommandations de retard",
    "emptyError": "Doit être remplie",
    "events": "Evénements",
    "externalIntegration": "Données integrées via système externe",
    "selectedProductsUseDifferentUnits": "Les produits sélectionnés utilisent des unités différentes",
    "inventoryStrategies": "Stratégie de stock",
    "myStockMin": "Flowlity IA + Mon min",
    "myStockMinDescription": "Les recommandations d'approvisionnement sont basées sur les prévisions de Flowlity AI et le niveau de stock. Le niveau de stock tampon est défini par l'utilisateur",
    "fullHorizon": "Horizon complet",
    "manualPeriod": "Période manuelle",
    "manualHorizon": "Horizon manuel",
    "mts": "Fabrication pour stock",
    "mtsDescription": "Les recommandations d'approvisionnement sont basées sur le niveau de stock buffer",
    "mto": "Fabrication à la commande",
    "mtoDescription": "Les recommandations d'approvisionnement sont basées sur une demande ferme. Il n'y a pas de stock buffer",
    "flowlityAi": "Flowlity IA",
    "internalCustomer": "Client interne",
    "internalCustomerTooltip": "Les données de prévision des clients internes sont exclues du calcul de la prévision du produit. Seule la demande ferme des clients internes est utilisée pour le calcul.",
    "fixedLotSize": "Taille de lot fixe",
    "info": "Info",
    "infoRatio": "Ratio",
    "initialValue": "Valeur initiale - {initialBufferLevel}",
    "inventoryStrategy": "Stratégie de stock",
    "lockedByTacticalChanges": "Bloqué par Tactical",
    "leadTime": "Délai de livraison",
    "low": "minimum",
    "manualStockCoverage": "Couverture de stock manuel",
    "moq": "Qte min de commande",
    "myMaxValue": "Ma valeur de max",
    "myStockMax": "Mon Stock Max",
    "myStockMaxTooltip": "Une alerte est déclenchée lorsque le niveau de stock dépasse la valeur de Mon stock max. Mon stock max n'agit pas comme une contrainte dans le calcul du plan d'approvisionnement.",
    "name": "Nom",
    "next6mAvgDays": "Moy. 6 prochains mois",
    "next6mAvgDaysSimulated": "Moy. 6 prochains mois simulée",
    "next6mAvgQtySimulated": "Moy. 6 prochains mois simulée",
    "noData": "Pas de produit trouvé",
    "none": "Aucune",
    "noPolicy": "Pas de politique",
    "price": "Prix",
    "productionEnd": "Fin de production",
    "productionStart": "Début de production",
    "productLifeCycle": "Cycle de vie",
    "promotions": "Promotions",
    "quantity": "Quantité",
    "ratio": "Ratio (0-100%)",
    "remove": "Supprimer",
    "reorderPoint": "Point de commande",
    "reorderPointDescription": "Les recommandations d'approvisionnement sont déclenchées lorsque le stock atteint ou tombe en dessous du point de commande. Seule la demande ferme est prise en compte dans l'horizon du point de commande (pas de prévision)",
    "ropAI": "Point de commande + IA",
    "ropAIDescription": "L'IA génère le niveau du ROP de manière dynamique sur la base des délais et des consommations passées",
    "salesEnd": "Fin de vie",
    "salesEndTooltip": "Cette donnée est intégrée d'un système externe",
    "salesStart": "Début de vente",
    "save": "Enregistrer",
    "apply": "Appliquer",
    "changesApplied": "Modifications de stratégie de stock appliquées",
    "incomingChanges": "Modifications à venir :",
    "inventoryStrategyWasChanged": "La stratégie d'inventaire a été modifiée. ",
    "saveBufferPolicyDescription": "Vous ne verrez les modifications qu’à partir du prochain calcul.",
    "saveBufferPolicyDescriptionWithOptimizeBtn": "Vous ne verrez les modifications qu’à partir du prochain calcul. Pour recalculer immédiatement le minimum et les recommandations d'approvisionnement vous pouvez utiliser le bouton \"Optimiser\".",
    "saveBufferPolicyTitle": "Sauvegarder les configurations de stratégie de stock?",
    "saveChanges": "Confirmer",
    "search": "Chercher par nom",
    "secondaryUnit": "Unité secondaire",
    "selectADate": "Choisir une date",
    "shelfLife": "Durée de vie",
    "similarProducts": "Produits similaires",
    "similarProductsSubTitle": "Ajoutez des anciens produits similaires",
    "showNewMin": "Afficher le nouveau min",
    "sourceCustomer": "Client Source",
    "sourceProduct": "Produit Source",
    "stock": "Stock",
    "stockCoverageTitle": "Couverture de stock, jours",
    "stockMinCoverage": "Min. Couverture de Stock",
    "stockPolicyHelpContent": "Le stock minimum est ajusté pour prendre en compte ces retards.",
    "atLeastOneProductCannotUseThisPolicy": "Au moins 1 produit sélectionné ne peut pas utiliser cette politique",
    "storageSites": "Points de stockage",
    "strategy": "Stratégie",
    "suppliers": "Fournisseurs",
    "supplyBufferPolicy": "Politique de buffer (Appros)",
    "supplyBufferPolicyNoDefaultStockTip": "La politique de buffer par défaut est “Stock”, mais “Stock” ne peut être utilisée avec la configuration actuelle",
    "supplyStrategy": "Stratégie Fournisseur",
    "tagGrouper": "Tags pour regrouper les commandes",
    "tags": "Tags",
    "targetCustomer": "Client Ciblé",
    "timePolicyHelpContent": "Les dates de livraison sont positionnées en avance des besoins planning.",
    "time": "Temps",
    "title": "Tous les produits",
    "units": "Unités",
    "useDefaultBufferPolicy": "Utiliser la politique de buffer par défaut",
    "value": "Valeur",
    "withCustomer": "x Client",
    "mixed": "Mixte",
    "values": "Valeurs",
    "allSuppliers": "Tous les fournisseurs",
    "perSupplier": "Par fournisseur",
    "delayCoverage": "Retard couvert",
    "productUseDefaultPolicy": "Le produit utilise la politique par défaut",
    "bom": "Bom",
    "bomParents": "Produits parents",
    "bomChildren": "Produits enfants",
    "bomSubTitle": "Dans les produits parents, nous affichons les valeurs de la référence actuelle en tant que composant dans leur fiche technique.",
    "openPlanning": "Ouvrir dans le planning",
    "bomParentsQuantity": "Quantité du bom (référence actuelle)",
    "bomParentsOffset": "Offset du bom (référence actuelle)",
    "bomChildrenQuantity": "Quantité du bom",
    "bomChildrenOffset": "Offset du bom"
  },
  "promotions": {
    "add": "Créer Promotion",
    "addButton": "Créer",
    "addTitle": "Créer une nouvelle promotion",
    "back": "retour",
    "cancel": "Annuler",
    "coefficient": "Impact sur les ventes en (%)",
    "createSuccess": "Promotion créée avec succès",
    "description": "Description",
    "delete": "Supprimer",
    "deleteDescription": "Cette action est définitive",
    "deleteQuestion": "Ceci va définitivement supprimer la promotion. Continuer ?",
    "deleteSuccess": "Promotion supprimée avec succès",
    "editButton": "Valider",
    "editTitle": "Modifier promotion",
    "endDate": "Date de fin",
    "name": "Nom",
    "noData": "Pas de promotion trouvée",
    "products": "Produits",
    "promotionDetails": "Details de la promotion",
    "requiredError": "Ce champ est requis",
    "requiredNumberError": "Merci de renseigner un nombre valide",
    "search": "Chercher par nom",
    "startDate": "Date de début",
    "title": "Toutes les promotions",
    "updateSuccess": "Promotion mise à jour avec succès"
  },
  "sites": {
    "title": "Tous les sites",
    "noData": "Pas de site trouvé",
    "back": "retour",
    "editTitle": "Editer le site",
    "name": "Nom",
    "address": "Adresse",
    "currency": "Devise (EUR, USD, CAD, ...)",
    "save": "Valider",
    "cancel": "Annuler",
    "errorName": "Ajoutez un nom",
    "errorAddress": "Ajoutez une adresse",
    "demandDistribution": "Distribution de la demande à la semaine",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "addClosure": "Créer une nouvelle période de fermeture",
    "showFutureDashboard": "Afficher les valeurs futures dans le dashboard d'inventaire",
    "inventoryDashboardTitle": "Propriétés du dashboard d'inventaire",
    "stockCompTitle": "Propriétés de la computation de stock",
    "shelfLife": "Durée de vie des produits",
    "mustBeEq100": "Doit être = 100%"
  },
  "suppliers": {
    "add": "Créer Fournisseur",
    "addButton": "Créer",
    "address": "Adresse postale (optionnel)",
    "addTitle": "Créer un nouveau fournisseur",
    "back": "retour",
    "cancel": "Annuler",
    "constraints": "Contraintes (optionnel)",
    "days": "jours",
    "editButton": "Valider",
    "editTitle": "Editer un fournisseur",
    "email": "Email du contact (optionnel)",
    "errorExtId": "Veuillez ajouter la référence du fournisseur",
    "errorName": "Veuillez ajouter le nom du fournisseur",
    "externalId": "Ref",
    "fullTruckQte": "Quantité camion plein",
    "maxSuppliesByOrder": "Nombre maximum de lignes par commande (export seulement)",
    "minOrderQte": "Quantité commande min",
    "name": "Nom",
    "noData": "Pas de fournisseur trouvé",
    "orderFrequency": "Fréquence de commande",
    "search": "Chercher par nom",
    "title": "Tous les fournisseurs",
    "unit": "Unité",
    "unitPlaceholder": "Sélectionnez l'unité",
    "noAvailableUnitsMsg": "Il n'y a aucune unité disponible, car aucun produit n'est attribué au fournisseur ou aucune unité n'est attribuée aux produits.",
    "updateSuccess": "Fournisseur mis à jour avec succès",
    "createSuccess": "Fournisseur créé avec succès"
  },
  "tags": {
    "add": "Créer un tag",
    "addTag": "Ajouter",
    "all": "Liste de produits",
    "back": "retour",
    "cancel": "Annuler",
    "create": "Créer",
    "createError": "Une erreur est survenue durant la création du Tag",
    "createSuccess": "Tag créé avec succès",
    "delete": "Supprimer",
    "deleteDescription": "Cet tag est utilisée dans {count} produits",
    "deleteQuestion": "Supprimer le tag ?",
    "deleteAction": "Supprimer le tag",
    "deleteSuccess": "Le tag \"{name}\" a été supprimé",
    "editTitle": "Modifier Tag",
    "isTagGrouper": "Utilisé pour regrouper les commandes",
    "isSimulated": "Utilisé pour la simulation",
    "name": "Nom du tag",
    "noData": "Pas de tag trouvé",
    "nonAdminWarningText": "Veuillez contacter votre admin pour tout besoin de modification",
    "notTaggedProducts": "produit disponible | produit disponible | produits disponibles",
    "products": "Produits",
    "remove": "Enlever",
    "requiredError": "Ce champ est requis",
    "save": "Valider",
    "search": "Chercher",
    "searchByProduct": "Chercher par nom de produit",
    "selectAll": "Tout sélectionner",
    "deselectAll": "Tout désélectionner",
    "tagDetails": "Details du Tag",
    "productsSelected": "0 produit sélectionné | 1 produit sélectionné | {count} produits sélectionnés",
    "discardCancelButton": "Continuer d'éditer",
    "discardConfirmButton": "Annuler les modifications",
    "discardText": "Vous perdrez tous les modifications qui ont été faits pendant l'édition",
    "discardTitle": "Annuler les modifications ?",
    "taggedProducts": "produit tagué | produit tagué | produits tagués",
    "title": "Tous les Tags",
    "updateError": "Une erreur est survenue durant la mise à jour du Tag",
    "updateSuccess": "Tag mis à jour avec succès",
    "tagType": "Type",
    "integrated": "Intégré",
    "createdInFlowlity": "Créé dans Flowlity",
    "integratedDescription": "Intégré à partir d'un système externe"
  },
  "users": {
    "active": "Utilisateur actif",
    "back": "retour",
    "cancel": "Annuler",
    "editTitle": "Editer un utilisateur",
    "profile": "Profil",
    "email": "Email",
    "errorEmail": "Ajoutez un email",
    "errorEmailPattern": "Entrez une adresse mail correcte",
    "errorName": "Ajoutez un nom",
    "job": "Poste occupé (optionnel)",
    "name": "Nom",
    "noData": "Aucun utilisateur trouvé",
    "notFound": "Utilisateur introuvable",
    "phone": "Téléphone (optionnel)",
    "sameSiteError": "Vous ne pouvez vous dissocier du Site actuellement utilisé dans l'application",
    "save": "Valider",
    "title": "Tous les utilisateurs",
    "updatePassword": "Modifier",
    "scope": "Périmètre",
    "suppliersScope": "Périmètre fournisseur",
    "userInfo": "Info utilisateur",
    "updateSuccess": "Profil mis à jour",
    "updateError": "Une erreur est survenue durant la mise à jour du profil",
    "discard": "Annuler les changements"
  }
}
